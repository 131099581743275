import { ThemeContext } from "styled-components";
import { KPI } from "../../../components/composed/kpi/KPI";
import { Box } from "../../../components/elements/box/Box";
import {
  HeaderSecondary,
  HeaderSub,
  HeaderSubHover,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import {
  SCREEN_BIG_DESKTOP_WIDTH,
  SCREEN_MEDIUM_LAPTOP_WIDTH,
} from "../../../shared/consts";
import { useContext } from "react";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { SecurityScorePieChart } from "./SecurityScorePieChart";
import { useApiSecurityScoreExtended } from "../../insights/findingsTrendsGraph/findingsTrendsContext";
import { useNavigate } from "react-router";
import { Mixpanel } from "../../../shared/mixpanel";
import { Icon } from "../../../components/elements/icon/Icon";
import { UnprotectedTooltipContent } from "../../../shared/tooltipTexts";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { Filters } from "../../findings/Findings";
import { OPEN_STATUSES } from "../../../types/Finding";
import { objectToBase64, toBase64AssetsView } from "../../../shared/helper";

type Props = {
  selectedProduct: number | "all";
};

export const SecurityScore = (props: Props) => {
  const { selectedProduct } = props;
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isSmallScreen = screenWidth < SCREEN_BIG_DESKTOP_WIDTH - 50;
  const isLaptop = screenWidth < SCREEN_MEDIUM_LAPTOP_WIDTH;
  const navigate = useNavigate();

  let findingsBaseFilter: Filters = selectedProduct
    ? {}
    : { products: selectedProduct };
  findingsBaseFilter.status = OPEN_STATUSES;

  let assetsBaseFilter = [
    {
      column: "status",
      value: "live",
      condition: "is",
      order: 0,
      next_condition: "and",
    },
  ];

  const filters = {
    highly_vulnerable_assets: {
      name: "",
      filters: [
        ...assetsBaseFilter,
        {
          column: "risk_score",
          value: "40",
          condition: "gt",
          order: 0,
          next_condition: "and",
        },
      ],
    },
    unprotected_assets: {
      name: "",
      filters: [
        ...assetsBaseFilter,
        {
          column: "is_asm_enabled",
          value: "Disabled",
          condition: "is",
          order: 1,
          next_condition: "and",
        },
      ],
    },
    unassigned_findings: { ...findingsBaseFilter, assignee: "unassigned" },
    urgent_findings: { ...findingsBaseFilter, overall_risk: [3, 4] },
    widespread_findings: { ...findingsBaseFilter, widespread: true },
  };

  const params =
    selectedProduct && selectedProduct !== "all"
      ? { product_id: selectedProduct }
      : {};
  const { data: securityScoreExtended } = useApiSecurityScoreExtended(params);

  return (
    <Box
      style={{
        height: isSmallScreen ? "100%" : "470px",
        width: isSmallScreen ? "100%" : "590px",
      }}
    >
      <Flex column={!isSmallScreen || isLaptop} justify="between" gap="32px">
        <Flex column justify="between" gap="16px" w100>
          <Flex align="center" justify="between">
            <HeaderSecondary>Security Score</HeaderSecondary>
            <Flex>
              <TextButton
                label="Go to Insights Page"
                onClick={() => {
                  Mixpanel.track(
                    "Go to Insights Page from Dashboard Security Score"
                  );
                  navigate("/insights#benchmark");
                }}
                style={{ textAlign: "end", paddingRight: "0px" }}
              />
            </Flex>
          </Flex>
          <SecurityScorePieChart
            score={securityScoreExtended?.security_score || 0}
          />
        </Flex>

        <Flex column style={{ minWidth: "300px" }} gap="">
          {/* <HeaderSub color={theme.textSub}>Required Attention </HeaderSub> */}
          <Flex
            align="center"
            justify="between"
            w100
            style={{ height: "35px" }}
          >
            <HeaderSubHover
              onClick={() =>
                navigate(
                  `/assets?view=${toBase64AssetsView(filters.highly_vulnerable_assets)}`
                )
              }
            >
              Highly Vulnerable Assets
            </HeaderSubHover>
            {!!securityScoreExtended?.highly_vulnerable_assets ? (
              <KPI
                color={
                  !!securityScoreExtended?.highly_vulnerable_assets
                    ? theme.redPrimary
                    : theme.textBody
                }
                count={securityScoreExtended?.highly_vulnerable_assets}
                style={{ fontSize: "20px" }}
              />
            ) : (
              <HeaderSub style={{ fontSize: "20px" }}>-</HeaderSub>
            )}
          </Flex>
          <Flex
            align="center"
            justify="between"
            w100
            style={{ height: "35px" }}
          >
            <Flex align="center" gap="8px">
              <HeaderSubHover
                onClick={() =>
                  navigate(
                    `/assets?view=${toBase64AssetsView(filters.unprotected_assets)}`
                  )
                }
              >
                Unprotected Assets
              </HeaderSubHover>

              <Tooltip content={UnprotectedTooltipContent}>
                <Icon name="info" color={theme.black600} size={16} />
              </Tooltip>
            </Flex>

            {!!securityScoreExtended?.asm_disabled_assets ? (
              <KPI
                color={
                  !!securityScoreExtended?.asm_disabled_assets
                    ? theme.redPrimary
                    : theme.textBody
                }
                count={securityScoreExtended?.asm_disabled_assets}
                style={{ fontSize: "20px" }}
              />
            ) : (
              <HeaderSub style={{ fontSize: "20px" }}>-</HeaderSub>
            )}
          </Flex>
          <Flex
            align="center"
            justify="between"
            w100
            style={{ height: "35px" }}
          >
            <HeaderSubHover
              onClick={() =>
                navigate(
                  `/findings?filters=${objectToBase64(filters.unassigned_findings)}`
                )
              }
            >
              Unassigned Findings
            </HeaderSubHover>
            {!!securityScoreExtended?.unassigned_findings ? (
              <KPI
                color={
                  !!securityScoreExtended?.unassigned_findings
                    ? theme.redPrimary
                    : theme.textBody
                }
                count={securityScoreExtended?.unassigned_findings}
                style={{ fontSize: "20px" }}
              />
            ) : (
              <HeaderSub style={{ fontSize: "20px" }}>-</HeaderSub>
            )}
          </Flex>
          <Flex
            align="center"
            justify="between"
            w100
            style={{ height: "35px" }}
          >
            <HeaderSubHover
              onClick={() =>
                navigate(
                  `/findings?filters=${objectToBase64(filters.urgent_findings)}`
                )
              }
            >
              Urgent Findings
            </HeaderSubHover>
            {!!securityScoreExtended?.urgent_findings ? (
              <KPI
                color={
                  !!securityScoreExtended?.urgent_findings
                    ? theme.redPrimary
                    : theme.textBody
                }
                count={securityScoreExtended?.urgent_findings}
                style={{ fontSize: "20px" }}
              />
            ) : (
              <HeaderSub style={{ fontSize: "20px" }}>-</HeaderSub>
            )}
          </Flex>
          <Flex
            align="center"
            justify="between"
            w100
            style={{ height: "35px" }}
          >
            <HeaderSubHover
              onClick={() =>
                navigate(
                  `/findings?filters=${objectToBase64(filters.widespread_findings)}`
                )
              }
            >
              Widespread Findings
            </HeaderSubHover>
            {!!securityScoreExtended?.widespread_findings ? (
              <KPI
                color={
                  !!securityScoreExtended?.widespread_findings
                    ? theme.redPrimary
                    : theme.textBody
                }
                count={securityScoreExtended?.widespread_findings}
                style={{ fontSize: "20px" }}
              />
            ) : (
              <HeaderSub style={{ fontSize: "20px" }}>-</HeaderSub>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
