import React, { ChangeEvent, useContext, useRef, useState } from "react";
import { LinkButton } from "../link/LinkButton";
import { Loading } from "../../loading/Loading";
import { Flex } from "../../../layouts/flex/Flex";
import { IconButton } from "../icon/IconButton";
import { ThemeContext } from "styled-components";
import useToastContext from "../../../../hooks/toastHook";
import { fileTypeFromBlob } from "file-type";

type Props = {
  label: string;
  validFileTypes?: string[];
  onDelete: () => void;
  onClick?: (event: ChangeEvent<HTMLInputElement>) => void;
  isExist?: boolean;
  fileName?: string;
  width?: number;
  isLoading?: boolean;
  isSuccessful?: boolean;
};

export const UploadButton = (props: Props) => {
  const addToast = useToastContext();
  const theme = useContext(ThemeContext);
  const {
    label,
    validFileTypes,
    onClick,
    onDelete,
    isExist = false,
    fileName = "",
    width = 100,
    isLoading,
    isSuccessful,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadedFileName, setFileName] = useState<string>(fileName);
  const handleButtonClick = () => {
    // Reset the file input value to ensure onChange will trigger even if selecting the same file
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.click();
    }
  };

  const validateFileTypes = async (file: File): Promise<boolean> => {
    /**
     * Validates the file type of a given file using both browser-detected MIME type
     * and the MIME type determined from the file content via `fileTypeFromBlob`.
     *
     * Note: Since IPA file type aren't easily detected we use both methods of
     * detection.
     **/
    let fileType = await fileTypeFromBlob(file);
    return validFileTypes
      ? validFileTypes.includes(file.type) ||
          validFileTypes.includes(fileType?.mime || "")
      : true;
  };

  const handleOnClick = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!onClick) return;

    if (event.target.files && event.target.files.length > 0) {
      // validate file
      if (!(await validateFileTypes(event.target.files[0]))) {
        addToast({
          type: "error",
          message: `Incorrect type, did you upload a valid file?`,
        });
        // Reset the file input after validation failure
        if (inputRef.current) inputRef.current.value = "";
        return;
      }
      // if valid, show file name
      setFileName(event.target.files[0].name);

      try {
        onClick(event);
      } catch (error) {
        addToast({ type: "error", message: `An error occurred: ${error}` });
        // Reset the file input after error
        if (inputRef.current) inputRef.current.value = "";
      }
    }
  };

  const handleOnDelete = async () => {
    onDelete();
    setFileName("");
    // Reset the file input on delete
    if (inputRef.current) inputRef.current.value = "";
  };

  return (
    <Flex justify="between" style={{ width: `${width}%` }}>
      <Flex>
        {isLoading ? (
          <Loading />
        ) : (
          <LinkButton
            iconName={isExist ? "checkGreen" : "add"}
            label={isSuccessful ? uploadedFileName : label}
            onClick={handleButtonClick}
          />
        )}
        <input
          type="file"
          ref={inputRef}
          onChange={handleOnClick}
          style={{ display: "none" }} // Hide the file input
        />
      </Flex>
      {isExist && (
        <IconButton
          iconName="remove"
          color={theme.redPrimary}
          size="small"
          label="Delete file"
          onClick={handleOnDelete}
        />
      )}
    </Flex>
  );
};
