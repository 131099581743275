import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../../../components/elements/icon/Icon";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import {
  HeaderSecondary,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { toBase64AssetsView } from "../../../shared/helper";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import {
  SCREEN_BIG_DESKTOP_WIDTH,
  SCREEN_MEDIUM_LAPTOP_WIDTH,
} from "../../../shared/consts";
import { AssetOverviewCounter } from "../topBlocks/dashboardOverview/AssetOverviewCounter";
import { useApiTrendsOverview } from "../../insights/findingsTrendsGraph/findingsTrendsContext";
import { TrendBadge } from "./TrendBadge";

type Props = {
  selectedProduct: number | "all";
};

export const AssetsOverviewV2 = (props: Props) => {
  const { selectedProduct } = props;
  const theme = useContext(ThemeContext);

  const screenWidth = useScreenWidth();
  const isLargeScreen = screenWidth >= SCREEN_BIG_DESKTOP_WIDTH - 50;
  const isLaptop = screenWidth < SCREEN_MEDIUM_LAPTOP_WIDTH;

  const getTrendsParams = () => {
    var params: any = {};
    if (selectedProduct !== "all") params.product = selectedProduct;
    return params;
  };

  const { data: overview, isFetching } =
    useApiTrendsOverview(getTrendsParams());

  const [counters, setCounters] = useState({
    currentTotalAssets: 0,
    oldTotalAssets: 0,
    currentTotalVulnerableAssets: 0,
    oldTotalVulnerableAssets: 0,
    currentCoverage: 0,
    oldCoverage: 0,
  });

  useEffect(() => {
    if (!overview?.assets.current_risk) return;
    const oldTotalAssets: number = !!overview?.assets.timeframe_risk
      ? Object.values(overview?.assets.timeframe_risk).reduce(
          (a, b) => a + b,
          0
        )
      : 0;

    const currentTotalAssets: number = Object.values(
      overview?.assets.current_risk
    ).reduce((a, b) => a + b, 0);

    const currentTotalVulnerableAssets: number =
      overview?.assets.current_risk.critical +
      overview?.assets.current_risk.high +
      overview?.assets.current_risk.medium +
      overview?.assets.current_risk.low;

    const oldTotalVulnerableAssets: number = !!overview?.assets.timeframe_risk
      ? overview?.assets.timeframe_risk.critical +
        overview?.assets.timeframe_risk.high +
        overview?.assets.timeframe_risk.medium +
        overview?.assets.timeframe_risk.low
      : 0;

    setCounters({
      currentTotalAssets,
      oldTotalAssets,
      currentTotalVulnerableAssets,
      oldTotalVulnerableAssets,
      currentCoverage: overview.assets.current_coverage,
      oldCoverage: overview.assets.timeframe_coverage
        ? overview.assets.timeframe_coverage
        : 0,
    });
  }, [overview]);

  return (
    <>
      <Flex
        column
        justify="between"
        gap="24px"
        h100
        w100
        style={{
          width: isLaptop || isLargeScreen ? "100%" : "470px",
        }}
      >
        <Flex justify="between" align="center" w100>
          <Flex className="text-truncate" align="center" gap="8px">
            <HeaderSecondary className="text-truncate" title="Assets Overview">
              Assets Overview
            </HeaderSecondary>
          </Flex>
        </Flex>
        <Flex gap="16px" justify="between" align="end" h100 w100>
          <Flex column w100 gap="4px">
            <AssetOverviewCounter
              title="Live Assets"
              count={counters.currentTotalAssets}
              isLoading={!!isFetching && !overview?.assets}
              linkTo={`/assets?view=${toBase64AssetsView({
                name: "",
                filters: [
                  {
                    column: "status",
                    value: "live",
                    condition: "is",
                    order: 0,
                    next_condition: "and",
                  },
                ],
              })}`}
            />
            <TrendBadge
              oldValue={counters.oldTotalAssets}
              newValue={counters.currentTotalAssets}
              timeframeLabel={"Last 30 Days"}
            />
          </Flex>
          <SeparatorVertical style={{ height: "100px" }} />
          <Flex column w100 gap="4px">
            <AssetOverviewCounter
              title="Vulnerable Assets"
              count={counters.currentTotalVulnerableAssets}
              isLoading={!!isFetching && !overview?.assets}
              linkTo={`/assets?view=${toBase64AssetsView({
                name: "",
                filters: [
                  {
                    column: "risk_score",
                    value: "0",
                    condition: "gt",
                    order: 0,
                    next_condition: "and",
                  },
                ],
              })}`}
            />
            <TrendBadge
              showTrendIcon
              oldValue={counters.oldTotalVulnerableAssets}
              newValue={counters.currentTotalVulnerableAssets}
              timeframeLabel={"Last 30 Days"}
            />
          </Flex>
          <SeparatorVertical style={{ height: "100px" }} />
          <Flex column w100 gap="4px">
            <AssetOverviewCounter
              title="Protection Coverage"
              titleElement={
                <Flex align="center" gap="4px">
                  <LabelRegular
                    className={
                      screenWidth < SCREEN_BIG_DESKTOP_WIDTH
                        ? "text-truncate"
                        : ""
                    }
                    title="Protection Coverage"
                    color={theme.textSecondary}
                  >
                    Protection Coverage
                  </LabelRegular>
                  <Tooltip
                    placement="bottom"
                    content={
                      <LabelRegular
                        style={{
                          maxWidth: "200px",
                          textTransform: "none",
                        }}
                      >
                        <strong>Protection Coverage</strong> measures security
                        measures for an organization's assets. <br />
                        It considers three factors - Web Application Firewall
                        (WAF), <br />
                        Penetration Testing, and Active Security Monitoring
                        (ASM). <br />
                        The score is based on whether these factors are present
                        or not, with equal weight for each.
                      </LabelRegular>
                    }
                  >
                    <Icon name="info" size={16} color={theme.black600} />
                  </Tooltip>
                </Flex>
              }
              count={overview?.assets?.current_coverage || 0}
              suffix="%"
              isLoading={!!isFetching && !overview?.assets}
            />
            <TrendBadge
              showTrendIcon
              newValue={overview?.assets?.current_coverage || 0}
              oldValue={overview?.assets?.timeframe_coverage || 0}
              timeframeLabel={"Last 30 Days"}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
