import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { Avatar } from "../../elements/avatar/Avatar";
import { Loading } from "../../elements/loading/Loading";
import { AssigneeMenu } from "./AssigneeMenu";
import { AssigneeLabelStyle } from "./AssigneeStyles";
import GrayAvatar from "../../../assets/images/avatars/GrayAvatar.svg";
import { Flex } from "../../layouts/flex/Flex";
import { useApiMe } from "../../../hooks/queries/meContext";

type Props = {
  name?: string | undefined | null;
  imageSrc?: string | undefined | null;
  isEditable?: boolean;
  isDisabled?: boolean;
  hideIcon?: boolean;
  hideName?: boolean;
  findingId?: number;
  fontSize?: string;
};

export const Assignee = (props: Props) => {
  const {
    name,
    imageSrc,
    findingId,
    fontSize,
    isEditable = false,
    isDisabled = false,
    hideIcon = false,
    hideName = false,
  } = props;
  const theme = useContext(ThemeContext);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const isUnassigned = !name || name.toLowerCase() === "unassigned";
  const { data: me } = useApiMe();

  return (
    <Flex
      align="center"
      gap="8px"
      style={{ textOverflow: "ellipsis", overflow: "inherit" }}
      title={name || "Unassigned"}
    >
      {!hideIcon && (
        <Avatar size={24} imageSrc={isUnassigned ? GrayAvatar : imageSrc} />
      )}
      {isUpdating ? (
        <Loading />
      ) : (
        <AssigneeLabelStyle
          className="text-truncate"
          title={hideName ? "" : name || "Unassigned"}
          isEditable={isEditable && !isDisabled}
          style={{
            color: isDisabled
              ? theme.textSub
              : showMenu
                ? theme.primary
                : theme.textBody,
            fontWeight: showMenu ? 500 : "",
            cursor: isEditable && !isDisabled ? "pointer" : "default",
            fontSize: fontSize || "14px",
          }}
          onClick={() => (isEditable ? setShowMenu((state) => !state) : {})}
        >
          {hideName ? "" : name || "Unassigned"}
        </AssigneeLabelStyle>
      )}

      {isEditable && showMenu && !isDisabled && (
        <AssigneeMenu
          close={() => setShowMenu(false)}
          findingId={findingId}
          setIsUpdating={setIsUpdating}
          isJiraConnected={!!me?.customer.jira_details?.token}
        />
      )}
    </Flex>
  );
};
