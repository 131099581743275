import { useContext } from "react";
import { DoughnutChart } from "../../../components/composed/charts/DoughnutChart";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  useApiAssetsCount,
  useApiAssetsRiskDistribution,
} from "../../../hooks/queries/assetsContext";
import { Filter } from "../../../types/AssetsView";
import { ThemeContext } from "styled-components";
import { LegendItem } from "../../../components/elements/legend/LegendItem";
import { Mixpanel } from "../../../shared/mixpanel";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toBase64AssetsView } from "../../../shared/helper";
import { getGradeFilters } from "../AssetUtils";
import { AssetGrade } from "../../../types/Asset";
import { useRiskColors } from "../../../shared/findingsHelper";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  BodyBold,
  BodyRegular,
} from "../../../components/elements/typography/Typography";

const TooltipContent = ({ severity }: { severity: string }) => (
  <Flex column w100>
    <Flex>
      <BodyRegular>{severity}</BodyRegular>
    </Flex>
    <Flex>
      <BodyBold>Click to view on assets table</BodyBold>
    </Flex>
  </Flex>
);

type Props = {
  filters: Filter[];
  isRiskColors?: boolean;
  size?: number;
  labelNumberSize?: number;
  labelTextSize?: number;
  top?: string;
  labelNumberLineHeight?: string;
  legendWidth?: string;
};

export const AssetsRiskPieChart = (props: Props) => {
  const {
    filters,
    isRiskColors = true,
    size,
    labelNumberSize,
    labelTextSize,
    top,
    labelNumberLineHeight,
    legendWidth = "120px",
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const { riskColors } = useRiskColors({ withInfo: true });
  const colors = isRiskColors
    ? [
        riskColors.critical,
        riskColors.high,
        riskColors.medium,
        riskColors.low,
        riskColors.info,
      ]
    : [
        theme.blue700,
        theme.blue600,
        theme.blue500,
        theme.blue300,
        theme.blue100,
      ];

  const [, setSearchParams] = useSearchParams();

  const { data: riskDistribution, isFetching: isFetchingDist } =
    useApiAssetsRiskDistribution(
      filters.filter((f) => f.column !== "risk_score")
    );

  const { data: assetsCounts, isFetching: isFetchingCounts } =
    useApiAssetsCount({}, filters);

  const labels = [
    "Critical Risk",
    "High Risk",
    "Medium Risk",
    "Low Risk",
    "No Risk",
  ];

  const lowRiskCount = riskDistribution
    ? riskDistribution.c + riskDistribution.b
    : 0;

  const datasets = [
    {
      data: riskDistribution
        ? [
            riskDistribution.f,
            riskDistribution.e,
            riskDistribution.d,
            lowRiskCount, //c + b
            riskDistribution.a,
          ]
        : [],
      backgroundColor: colors,
    },
  ];

  const onLegendClick = (grade: AssetGrade, maxGrade?: AssetGrade) => {
    Mixpanel.track("Asset Pie-chart click", { Selected: grade });
    const view = toBase64AssetsView({
      name: "",
      filters: [
        ...filters.filter((f) => f.column !== "risk_score"),
        ...getGradeFilters(grade, maxGrade),
      ],
    });

    if (location.pathname.includes("assets"))
      setSearchParams((prev) => ({ ...prev, view }));
    else navigate(`/assets?view=${view}`);
  };

  return (
    <Flex justify="center" align="center" w100 h100 gap="24px">
      <Flex>
        <DoughnutChart
          datasets={datasets}
          labelNumber={assetsCounts?.total || 0}
          labelText="Total Assets"
          labels={labels}
          labelNumberSize={
            labelNumberSize
              ? labelNumberSize
              : assetsCounts?.total && assetsCounts.total > 1000
                ? 18
                : 24
          }
          labelNumberLineHeight={labelNumberLineHeight || "42px"}
          isLoadingData={isFetchingCounts || isFetchingDist}
          size={size || 112}
          labelTextSize={labelTextSize}
          top={top}
        />
      </Flex>
      <Flex column w100 style={{ width: legendWidth }}>
        <Tooltip
          content={<TooltipContent severity="Critical Risk" />}
          isTextTruncate
        >
          <LegendItem
            color={colors[0]}
            label="Critical Risk"
            labelSize={12}
            labelColor={theme.textSub}
            value={riskDistribution?.f}
            onClick={() => onLegendClick("F")}
          />
        </Tooltip>
        <Tooltip
          content={<TooltipContent severity="High Risk" />}
          isTextTruncate
        >
          <LegendItem
            color={colors[1]}
            label="High Risk"
            labelSize={12}
            labelColor={theme.textSub}
            value={riskDistribution?.e}
            onClick={() => onLegendClick("E")}
          />
        </Tooltip>
        <Tooltip
          content={<TooltipContent severity="Medium Risk" />}
          isTextTruncate
        >
          <LegendItem
            color={colors[2]}
            label="Medium Risk"
            labelSize={12}
            labelColor={theme.textSub}
            value={riskDistribution?.d}
            onClick={() => onLegendClick("D")}
          />
        </Tooltip>
        <Tooltip
          content={<TooltipContent severity="Low Risk" />}
          isTextTruncate
        >
          <LegendItem
            color={colors[3]}
            label="Low Risk"
            labelSize={12}
            labelColor={theme.textSub}
            value={lowRiskCount}
            onClick={() => onLegendClick("B", "C")}
          />
        </Tooltip>
        <Tooltip content={<TooltipContent severity="No Risk" />} isTextTruncate>
          <LegendItem
            color={colors[4]}
            label="No Risk"
            labelSize={12}
            labelColor={theme.textSub}
            value={riskDistribution?.a}
            onClick={() => onLegendClick("A")}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};
