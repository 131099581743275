import { Dispatch, SetStateAction } from "react";
import { ProjectFormProps } from "./ProjectScheduler";
import { Flex } from "../../../components/layouts/flex/Flex";
import { BodyMedium } from "../../../components/elements/typography/Typography";
import { Switch } from "../../../components/elements/switch/Switch";
import { MultiSelect } from "../../../components/elements/dropdowns/MultiSelect";
import { stringsListToSelectOptions } from "../../../shared/helper";
import { InputText } from "../../../components/elements/input/textInput/InputText";

export const complianceOptions = stringsListToSelectOptions([
  "PCI_DSS",
  "HIPAA",
  "ISO 27001",
  "Other",
]);

interface Props extends ProjectFormProps {
  closeMenus: boolean;
  setCloseMenus: Dispatch<SetStateAction<boolean>>;
}

export const ComplianceSupport = (props: Props) => {
  const { mainFormValues, setMainFormValues, closeMenus, setCloseMenus } =
    props;

  return (
    <>
      <Flex column style={{ padding: "32px" }}>
        <Flex w100 align="center" justify="between">
          <BodyMedium>Compliance Support</BodyMedium>
          <Switch
            checked={mainFormValues.test_settings.compliance_support}
            onChange={() =>
              setMainFormValues((prev) => ({
                ...prev,
                test_settings: {
                  ...prev.test_settings,
                  compliance_support: !prev.test_settings.compliance_support,
                },
              }))
            }
          />
        </Flex>
      </Flex>
      {mainFormValues.test_settings.compliance_support && (
        <Flex column style={{ padding: "32px" }}>
          <Flex gap="32px">
            <MultiSelect
              placeholder="Select Compliance Types"
              width="100%"
              isBadged
              selected={stringsListToSelectOptions(
                mainFormValues.test_settings.compliance_types
              )}
              onSelect={(opts) =>
                setMainFormValues((prev) => ({
                  ...prev,
                  test_settings: {
                    ...prev.test_settings,
                    compliance_types: opts.map((opt) => `${opt.value}`),
                  },
                }))
              }
              options={complianceOptions}
              disabled={!mainFormValues.test_settings.compliance_support}
              onOpenMenu={() => setCloseMenus(false)}
              closeMenu={closeMenus}
            />
            <InputText
              placeholder="Add a Note"
              value={mainFormValues.test_settings.compliance_note}
              width="600px"
              onChange={(e) =>
                setMainFormValues((prev) => ({
                  ...prev,
                  test_settings: {
                    ...prev.test_settings,
                    compliance_note: e.target.value,
                  },
                }))
              }
              disabled={!mainFormValues.test_settings.compliance_support}
            />
          </Flex>
        </Flex>
      )}
    </>
  );
};
