import React from "react";
import { IconProps } from "./WaspIcon";

export const MarketplaceMenuIcon = (props: IconProps) => {
  const size = props.size || 10;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13561_36284)">
        <mask
          id="mask0_13561_36284"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="-1"
          width="24"
          height="25"
        >
          <path d="M0 -0.00329399H24V23.9967H0V-0.00329399Z" fill="white" />
        </mask>
        <g mask="url(#mask0_13561_36284)">
          <path
            d="M20.2845 5.97167C20.2845 6.80309 19.6097 7.47791 18.7782 7.47791C17.9468 7.47791 17.272 6.80309 17.272 5.97167C17.272 5.1402 17.9468 4.46544 18.7782 4.46544C19.6097 4.46544 20.2845 5.1402 20.2845 5.97167Z"
            stroke={props.color || "currentColor"}
            strokeWidth="1.40625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.2845 18.0209C20.2845 17.1895 19.6097 16.5147 18.7782 16.5147C17.9468 16.5147 17.272 17.1895 17.272 18.0209C17.272 18.8524 17.9468 19.5272 18.7782 19.5272C19.6097 19.5272 20.2845 18.8524 20.2845 18.0209Z"
            stroke={props.color || "currentColor"}
            strokeWidth="1.40625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.2967 11.9963C23.2967 11.1649 22.6219 10.4901 21.7904 10.4901C20.959 10.4901 20.2842 11.1649 20.2842 11.9963C20.2842 12.8278 20.959 13.5026 21.7904 13.5026C22.6219 13.5026 23.2967 12.8278 23.2967 11.9963Z"
            stroke={props.color || "currentColor"}
            strokeWidth="1.40625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.7132 7.03706L15.7656 8.98462H12"
            stroke={props.color || "currentColor"}
            strokeWidth="1.40625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.7132 16.9571L15.7656 15.0095H12"
            stroke={props.color || "currentColor"}
            strokeWidth="1.40625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.2844 11.996H12"
            stroke={props.color || "currentColor"}
            strokeWidth="1.40625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.46873 4.46477C4.46873 2.38652 6.15614 0.69916 8.23434 0.69916C10.3126 0.69916 12 2.38652 12 4.46477V19.5273C12 21.6055 10.3126 23.2929 8.23434 23.2929C6.15614 23.2929 4.46873 21.6055 4.46873 19.5273C2.39048 19.5273 0.703125 17.8399 0.703125 15.7616C0.703125 13.6834 2.39048 11.996 4.46873 11.996C2.39048 11.996 0.703125 10.3086 0.703125 8.23038C0.703125 6.15213 2.39048 4.46477 4.46873 4.46477Z"
            stroke={props.color || "currentColor"}
            strokeWidth="1.40625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.72812 14.257C6.72812 13.0102 5.71553 11.9976 4.46875 11.9976C5.71553 11.9976 6.72812 10.9851 6.72812 9.73827"
            stroke={props.color || "currentColor"}
            strokeWidth="1.40625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.72812 6.72571C5.48134 6.72571 4.46875 5.71311 4.46875 4.46633"
            stroke={props.color || "currentColor"}
            strokeWidth="1.40625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.72812 17.2679C5.48134 17.2679 4.46875 18.2805 4.46875 19.5273"
            stroke={props.color || "currentColor"}
            strokeWidth="1.40625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.0001 5.97097C12.0001 7.21775 10.9875 8.23035 9.74072 8.23035"
            stroke={props.color || "currentColor"}
            strokeWidth="1.40625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.0001 18.0225C12.0001 16.7757 10.9875 15.7631 9.74072 15.7631"
            stroke={props.color || "currentColor"}
            strokeWidth="1.40625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_13561_36284">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
