import { SingleValue } from "react-select";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import {
  BodyBold,
  BodyRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  useApiFindingsBreakdown,
  useApiFindingsCounts,
} from "../../../hooks/queries/findingContext";
import { FindingStatus, OPEN_STATUSES } from "../../../types/Finding";
import { FindingDoughnutChart } from "../../findings/findingsCharts/FindingDoughnutChart";
import { useContext, useState } from "react";
import { DoughnutChart } from "../../../components/composed/charts/DoughnutChart";
import { LegendItem } from "../../../components/elements/legend/LegendItem";

import { ThemeContext } from "styled-components";
import { cweMap } from "../../insights/cwePieChart/cweMap";
import { StatusMapping } from "../../../shared/consts";
import { useNavigate } from "react-router";
import { objectToBase64 } from "../../../shared/helper";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { Mixpanel } from "../../../shared/mixpanel";

const distributionOptions: Option[] = [
  { value: "overall_risk", label: "Severity" },
  { value: "finding_type", label: "Type" },
  { value: "cwe_id", label: "CWE" },
  { value: "status", label: "Status" },
];

export const DistributionOfFindings = ({
  selectedProduct,
}: {
  selectedProduct?: number | "all";
}) => {
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const colors = [
    theme.blue800,
    theme.blue600,
    theme.blue500,
    theme.blue300,
    theme.blue100,
  ];

  const [distribution, setDistribution] = useState<SingleValue<Option>>(
    distributionOptions[0]
  );

  const countFilters =
    distribution?.value !== "overall_risk"
      ? {}
      : !!selectedProduct && selectedProduct !== "all"
        ? { status: OPEN_STATUSES, products: selectedProduct }
        : { status: OPEN_STATUSES };

  const { data: findingsCounts, isFetching } =
    useApiFindingsCounts(countFilters);
  const breakdownFilters =
    !!selectedProduct && selectedProduct !== "all"
      ? { products: selectedProduct }
      : {};

  const { data: breakdown } = useApiFindingsBreakdown(
    { ...breakdownFilters, distribution: distribution?.value },
    distribution?.value !== "overall_risk"
  );

  const isBreakdownDataLongerThanDisplayed =
    !!breakdown && Object.keys(breakdown).length > 0;

  const breakdownList = breakdown
    ? Object.keys(breakdown)
        .map((breakdownKey) => ({
          key: breakdownKey,
          value: breakdown[breakdownKey],
          label:
            distribution?.value === "cwe_id"
              ? `CWE #${breakdownKey} - ${cweMap[parseInt(breakdownKey)]}`
              : distribution?.value === "status"
                ? StatusMapping[breakdownKey as FindingStatus]
                : distribution?.value === "finding_type"
                  ? breakdownKey.toUpperCase()
                  : breakdownKey,
        }))
        .filter((cwe) =>
          distribution?.value === "cwe_id" ? cwe.key in cweMap : cwe
        )
        .sort((a, b) => b.value - a.value)
        .slice(0, 5)
    : [];

  const labels = breakdownList ? breakdownList.map((item) => item.label) : [];
  const dataset = [
    {
      data: breakdownList ? breakdownList.map((item) => item.value) : [],
      backgroundColor: colors,
    },
  ];

  const getUrlFilter = (breakdownKey: string | number) =>
    objectToBase64({ [`${distribution?.value}`]: breakdownKey });

  return (
    <Flex column h100 gap="12px">
      <Flex align="center">
        <Dropdown
          valuePrefix="Breakdown By"
          value={distribution}
          options={distributionOptions}
          onChange={(opt) => {
            setDistribution(opt);
            Mixpanel.track("Dashboard - distribution of findings", {
              distribution: distribution?.label,
            });
          }}
        />
      </Flex>
      <Flex column h100 justify="center">
        {distribution?.value === "overall_risk" ? (
          <FindingDoughnutChart
            includeInfo
            showOpenStatus
            size={120}
            top={"40px"}
            findingsCounts={findingsCounts}
            isLoading={isFetching && !findingsCounts?.open}
            selectedProduct={selectedProduct}
            filters={countFilters}
            labelTextSize={12}
            legendWidth="160px"
            labelNumberLineHeight="24px"
          />
        ) : (
          <Flex w100 h100 justify="center" align="center" gap="24px">
            <Flex>
              <DoughnutChart
                labels={labels}
                datasets={dataset}
                size={120}
                labelNumber={findingsCounts?.total}
                labelText="Total Issues"
                top={"40px"}
                labelTextSize={12}
                labelNumberSize={24}
                labelNumberLineHeight="24px"
              />
            </Flex>
            <Flex column style={{ width: "160px" }}>
              {breakdownList.map((item, idx) => (
                <Tooltip
                  key={item.key}
                  content={
                    <Flex column w100>
                      <Flex>
                        <BodyRegular>{item.label}</BodyRegular>
                      </Flex>
                      <Flex>
                        <BodyBold>Click to view on findings table</BodyBold>
                      </Flex>
                    </Flex>
                  }
                  isTextTruncate
                >
                  <LegendItem
                    color={colors[idx]}
                    labelColor={theme.textSub}
                    label={
                      distribution?.value === "cwe_id"
                        ? `cwe #${item.key}`
                        : item.label
                    }
                    value={item.value}
                    onClick={() => {
                      navigate(`/findings?filters=${getUrlFilter(item.key)}}`);
                      Mixpanel.track(
                        "Dashboard - distribution of Findings - redirect",
                        { to: item.label }
                      );
                    }}
                    maxLabelLength="130px"
                  />
                </Tooltip>
              ))}
              {isBreakdownDataLongerThanDisplayed &&
                distribution?.value === "cwe_id" && (
                  <TextButton
                    label={"View more"}
                    onClick={() => {
                      Mixpanel.track(
                        "Dashboard - distribution of Findings - show more"
                      );
                      navigate(`/insights`);
                    }}
                  />
                )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
