import { KPI } from "../../composed/kpi/KPI";
import { Flex } from "../../layouts/flex/Flex";
import { BodyRegular, BodyRegularHover } from "../typography/Typography";

type Props = {
  label: string;
  value?: number;
  color: string;
  onClick?: (e: any, label: string) => void;
  isOn?: boolean;
  ellipseSize?: number;
  labelSize?: number;
  labelColor?: string;
  hideLegendText?: boolean;
  maxLabelLength?: string;
};

export const LegendItem = (props: Props) => {
  const {
    label,
    value,
    onClick,
    color,
    ellipseSize,
    labelSize,
    labelColor,
    hideLegendText,
    isOn = true,
    maxLabelLength,
  } = props;

  return (
    <Flex
      w100
      align="center"
      justify="between"
      gap="8px"
      className={!!onClick ? "pointer" : ""}
      onClick={(e) => (!!onClick ? onClick(e, label) : {})}
    >
      <Flex align="center" gap="8px">
        <span
          style={{
            width: ellipseSize || "8px",
            height: ellipseSize || "8px",
            background: color,
            borderRadius: "50%",
          }}
        />

        {!!onClick && !hideLegendText ? (
          <BodyRegularHover
            color={labelColor}
            className="text-truncate"
            style={{
              maxWidth: maxLabelLength,
              textDecoration: isOn ? "" : "line-through",
              fontSize: `${labelSize || 14}px`,
            }}
          >
            {label}
          </BodyRegularHover>
        ) : !hideLegendText ? (
          <BodyRegular
            color={labelColor}
            className="text-truncate"
            style={{
              maxWidth: maxLabelLength,
              fontSize: `${labelSize || 14}px`,
            }}
          >
            {label}
          </BodyRegular>
        ) : null}
      </Flex>

      <Flex align="center" justify="end">
        {value !== undefined && (
          <KPI
            count={value}
            style={{ fontSize: "12px", lineHeight: "14px", fontWeight: "400" }}
          />
        )}
      </Flex>
    </Flex>
  );
};
