import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { MenuButton } from "../../elements/button/menu/MenuButton";
import { useLocation } from "react-router-dom";
import { SettingsNav } from "./SettingsNav";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";

export const LeftNavBar = () => {
  const theme = useContext(ThemeContext);
  const location = useLocation();
  const isSuperuser = useIsSuperuser();
  let currentPage = location.pathname.replace("/", "");
  if (!currentPage) currentPage = "dashboard";

  return (
    <>
      <div
        id="left-navbar"
        style={{
          width: "97px",
          position: "fixed",
          zIndex: 50,
          top: 0,
          left: 0,
          bottom: 0,
          height: "100%",
          paddingTop: "72px",
          overflowX: "hidden",
          backgroundColor: theme.bg2,
          borderRight: `1px solid ${theme.black500}`,
        }}
      >
        <div
          style={{
            height: "calc(100% - 130px)",
          }}
        >
          <MenuButton
            label="Dashboard"
            linkTo="/dashboard"
            selected={currentPage.includes("dashboard")}
            iconName="dashboardMenu"
          />
          <MenuButton
            label="Findings"
            linkTo="/findings"
            selected={currentPage.includes("findings")}
            iconName="findingsMenu"
          />
          <MenuButton
            label="Assets"
            linkTo="/assets"
            selected={currentPage.includes("assets")}
            iconName="assetsMenu"
          />
          <MenuButton
            label="Projects"
            linkTo="/projects"
            selected={currentPage.includes("projects")}
            iconName="projectsMenu"
          />
          <MenuButton
            label="Insights"
            linkTo="/insights"
            selected={currentPage.includes("insights")}
            iconName="insightsMenu"
          />
          <MenuButton
            label="WASP AI"
            linkTo="/wasp-ai"
            selected={currentPage.includes("wasp-ai")}
            iconName="waspAiMenu"
          />
          {isSuperuser && (
            <MenuButton
              label="Services"
              linkTo="/marketplace"
              selected={currentPage.includes("marketplace")}
              iconName="marketplaceMenu"
            />
          )}
          <MenuButton
            label="Settings"
            linkTo="/settings"
            selected={currentPage.includes("settings")}
            iconName="settingsMenu"
          />
        </div>
      </div>
      <SettingsNav isOpen={currentPage.includes("settings")} />
    </>
  );
};
