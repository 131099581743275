import { useContext, useEffect, useState } from "react";
import { Flex } from "../../components/layouts/flex/Flex";
import { Icon } from "../../components/elements/icon/Icon";
import { HeaderSecondary } from "../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";

import { ASMUpdatesCurrentStatus } from "./ASMUpdatesCurrentStatus";
import { ASMUpdatesTimeline } from "./ASMUpdatesTimeline";
import { useApiScanEventsPaging } from "../../hooks/queries/scansContext";
import { useApiMe } from "../../hooks/queries/meContext";
import { SkeletonLoading } from "../../components/elements/loading/SkeletonLoading";
import { RunningBadge } from "../../components/composed/badegs/RunningBadge";
import { PausedBadge } from "../../components/composed/badegs/PausedBadge";
import { TurnOnASM } from "./TurnOnASM";
import { useScreenWidth } from "../../hooks/utilsHooks";
import { SCREEN_MEDIUM_LAPTOP_WIDTH } from "../../shared/consts";
import { useApiScanners } from "../../hooks/queries/scannersContext";

export const ASMUpdates = () => {
  const theme = useContext(ThemeContext);

  const screenWidth = useScreenWidth();
  const isLaptop = screenWidth < SCREEN_MEDIUM_LAPTOP_WIDTH;

  const element = document.querySelector("#top-right-box");
  const containerHeight = element?.clientHeight || 0;

  const [runningScanners, setRunningScanEvents] = useState<number>(0);
  const { data: me } = useApiMe();
  const { data: scanners, isFetching: isFetchingScanners } = useApiScanners(
    me?.customer?.id
  );
  const { data: scanEventsPages, isFetching: isFetchingScanEvents } =
    useApiScanEventsPaging({ customer_id: me?.customer?.id, page_size: 50 });

  const allEnabledScannersNames = scanners
    ?.filter(
      (scanner) => !me?.customer?.disabled_scanners?.includes(scanner.name)
    )
    .map((scanner) => scanner.name);

  useEffect(() => {
    if (!scanEventsPages) return;
    const scanEvents = scanEventsPages?.pages
      ?.map((p) => p.results || [])
      ?.flat();
    const runningScanEvents = scanEvents.filter(
      (scanEvents) =>
        scanEvents.status === "running" &&
        allEnabledScannersNames?.includes(scanEvents.scanner)
    );
    setRunningScanEvents(runningScanEvents.length);
  }, [scanEventsPages, allEnabledScannersNames]);

  return (
    <Flex column gap="16px">
      <Flex column gap="8px">
        <Flex align="center" justify="between" gap="8px" flexWrap>
          <Flex align="center" gap="4px">
            <Icon name="asm" size={32} color={theme.primary} />
            <HeaderSecondary>ASM Updates</HeaderSecondary>
          </Flex>
          {me && !me.customer.is_asm_enabled ? (
            <PausedBadge />
          ) : (
            runningScanners > 0 && <RunningBadge />
          )}
        </Flex>
      </Flex>

      <Flex
        column
        gap="8px"
        style={{
          height: isLaptop ? "1200px" : `${containerHeight - 120}px`,
          overflowY: "scroll",
        }}
      >
        {me && !me.customer.is_asm_enabled && (
          <Flex>
            <TurnOnASM />
          </Flex>
        )}

        {isFetchingScanEvents || isFetchingScanners ? (
          <SkeletonLoading width="100%" height="100px" />
        ) : (
          <ASMUpdatesCurrentStatus
            runningVulnerabilitiesScanners={runningScanners}
          />
        )}
        <Flex column>
          <ASMUpdatesTimeline />
        </Flex>
      </Flex>
    </Flex>
  );
};
