import { BASE_API_URL, getBaseConfig, getCookie } from "./utils";
import { isEmptyObject, removeNullKeys } from "../../shared/helper";
import { Filter } from "../../types/AssetsView";

const errorHandler = async (response: Response, key: string) => {
  if (response.ok) return;
  if (response.status === 401) window.location.replace("/login");

  let errmsg;
  try {
    const data = await response.json();
    errmsg = data?.errmsg;
  } catch (error) {
    console.error("Failed to parse error response as JSON:", error);
  }

  if (errmsg) throw new Error(`${errmsg} | status: ${response.status}`);
  console.error(
    `Network response is not ok, failed to fetch ${key} | status: ${response.status}`
  );
  throw new Error(`Unexpected error occurred`);
};

export const get = async (url: string, params?: string) => {
  return await fetch(`${BASE_API_URL}/${url}${params || ""}`);
};

export const getResource = async (key: string, params?: string) => {
  const res: Response = await fetch(`${BASE_API_URL}/${key}${params || ""}`);
  if (res.ok) {
    try {
      return res.json().then((data) => data);
    } catch {
      console.error(
        `Failed to parse ${key} server response to JSON, status: ${res.status}`
      );
    }
  }
  await errorHandler(res, key);
};

export const getSingleItem = async (key: string, id: string) => {
  const res: Response = await fetch(`${BASE_API_URL}/${key}/${id}`);
  if (res.ok) {
    try {
      return res.json();
    } catch {
      console.error(
        `Failed to parse ${key} server response to JSON, status: ${res.status}`
      );
    }
  }
  await errorHandler(res, key);
};

export const getItems = async (
  key: string,
  params?: { [key: string]: any },
  customAction?: string
) => {
  const res: Response = await fetch(
    `${BASE_API_URL}/${key}${customAction ? "/" + customAction : ""}${
      isEmptyObject(params) ? "" : "?" + new URLSearchParams(params)
    }`
  );
  if (res.ok) {
    try {
      return res
        .json()
        .then((data) => ("results" in data ? data?.results : data));
    } catch {
      console.error(
        `Failed to parse ${key} server response to JSON, status: ${res.status}`
      );
    }
    await errorHandler(res, key);
  }
};

export const getPage = async (key: string, params?: { [key: string]: any }) => {
  const res: Response = await fetch(
    `${BASE_API_URL}/${key}${
      isEmptyObject(params) ? "" : "?" + new URLSearchParams(params)
    }`
  );
  if (res.ok) {
    try {
      return res.json();
    } catch {
      console.error(
        `Failed to parse ${key} server response to JSON, status: ${res.status}`
      );
    }
  }
  await errorHandler(res, key);
};

export async function getViewPage(
  key: string,
  filters: Filter[],
  page: number,
  ordering?: string,
  adminMode?: boolean
) {
  const params = {
    page: page.toString(),
    ordering: ordering || "",
    "admin-mode": adminMode ? "true" : "false",
  };
  const url = `${BASE_API_URL}/${key}?${new URLSearchParams(params)}`;
  const res: Response = await fetch(url, {
    ...getBaseConfig("put"),
    body: JSON.stringify(filters),
  });
  if (res.ok) {
    try {
      return res.json();
    } catch {
      console.error(
        `Failed to parse ${key} server response to JSON, status: ${res.status}`
      );
    }
  }
  await errorHandler(res, url);
}

export const createItem = async (
  key: string,
  data: Object,
  custom_action?: string,
  isBlob: boolean = false
) => {
  const res: Response = await fetch(
    `${BASE_API_URL}/${key}${custom_action ? "/" + custom_action : ""}`,
    {
      ...getBaseConfig("post"),
      body: JSON.stringify(removeNullKeys(data)),
    }
  );
  if (res.ok) {
    try {
      if (isBlob) {
        return res.blob();
      }
      return res.json();
    } catch {
      console.error(
        `Failed to parse ${key} server response to JSON, status: ${res.status}`
      );
    }
  }
  await errorHandler(res, key[0]);
};

export const updateItem = async (
  key: string,
  id: number,
  data: Object,
  params?: { [key: string]: any }
) => {
  console.log(data);
  return await updateUrl(
    `${key}${!!id ? "/" + id : ""}${
      isEmptyObject(params) ? "" : "?" + new URLSearchParams(params)
    }`,
    data
  );
};

export const patchItem = async (
  key: string,
  id: number,
  data: Object,
  params?: { [key: string]: any }
) => {
  return await updateUrlPatch(
    `${key}${!!id ? "/" + id : ""}${
      isEmptyObject(params) ? "" : "?" + new URLSearchParams(params)
    }`,
    data
  );
};

export const updateUrl = async (
  url: string,
  data: Object,
  responseJson: boolean = true
) => {
  const res: Response = await fetch(`${BASE_API_URL}/${url}`, {
    ...getBaseConfig("put"),
    body: JSON.stringify(removeNullKeys(data)),
  });
  if (res.ok) {
    try {
      return responseJson ? res.json() : res;
    } catch {
      console.error(
        `Failed to parse server response to JSON, status: ${res.status}`
      );
    }
  }
  await errorHandler(res, url);
};

export const updateUrlPatch = async (
  url: string,
  data: Object,
  responseJson: boolean = true
) => {
  const res: Response = await fetch(`${BASE_API_URL}/${url}`, {
    ...getBaseConfig("PATCH"),
    body: JSON.stringify(removeNullKeys(data)),
  });
  if (res.ok) {
    try {
      return responseJson ? res.json() : res;
    } catch {
      console.error(
        `Failed to parse server response to JSON, status: ${res.status}`
      );
    }
  }
  await errorHandler(res, url);
};

export const updateBulk = async (
  key: string,
  data: Object,
  filters?: { [key: string]: any }
) => {
  const res: Response = await fetch(
    `${BASE_API_URL}/${key}${
      isEmptyObject(filters) ? "" : "?" + new URLSearchParams(filters)
    }`,
    {
      ...getBaseConfig("put"),
      body: JSON.stringify(removeNullKeys(data)),
    }
  );
  if (res.ok) {
    try {
      return res.json();
    } catch {
      console.error(
        `Failed to parse ${key} server response to JSON, status: ${res.status}`
      );
    }
  }
  await errorHandler(res, key[0]);
};

export const deleteBulk = async (
  key: string,
  data: Object,
  filters?: { [key: string]: any }
) => {
  const res: Response = await fetch(
    `${BASE_API_URL}/${key}${
      isEmptyObject(filters) ? "" : "?" + new URLSearchParams(filters)
    }`,
    {
      ...getBaseConfig("delete"),
      body: JSON.stringify(removeNullKeys(data)),
    }
  );
  if (res.ok) {
    try {
      return res.status;
    } catch {
      console.error(
        `Failed to parse ${key} server response to JSON, status: ${res.status}`
      );
    }
  }
  await errorHandler(res, key[0]);
};

export const deleteItem = async (key: string, data: Object, id: number) => {
  const res: Response = await fetch(`${BASE_API_URL}/${key}/${id}`, {
    ...getBaseConfig("delete"),
  });
  // return the deleted item data object for displaying its name and data
  if (res.ok) return data;
  await errorHandler(res, key);
};

export const deleteUrl = async (
  url: string,
  params: { [key: string]: string }
) => {
  const res: Response = await fetch(
    `${BASE_API_URL}/${url}?${new URLSearchParams(params)}`,
    {
      ...getBaseConfig("delete"),
    }
  );
  if (res.ok) {
    try {
      return res.json();
    } catch {
      console.error(
        `Failed to parse server response to JSON, status: ${res.status}`
      );
    }
  }
  await errorHandler(res, url);
};

export const sendFile = async (url: string, file: File): Promise<any> => {
  // Append the single file to the FormData
  const formData = new FormData();
  formData.append("file", file);
  const csrf = getCookie("csrftoken");
  // Send the request
  const res: Response = await fetch(`${BASE_API_URL}/${url}`, {
    method: "put",
    credentials: "include", // send cookie with JWT
    headers: {
      "X-CSRFToken": csrf,
    },
    body: formData,
  });

  if (res.ok) {
    try {
      return res.json();
    } catch {
      console.error(
        `Failed to parse server response to JSON, status: ${res.status}`
      );
    }
  }
  await errorHandler(res, url);
};

/**
 * Send a file chunk to the specified endpoint
 *
 * @param endpoint - The API endpoint without leading slash (e.g., "assets/mobile/123")
 * @param chunk - The file chunk to upload
 * @param chunkIndex - Index of the current chunk (0-based)
 * @param totalChunks - Total number of chunks
 * @param fileName - Original file name
 * @param fileSize - Original file size in bytes
 * @returns The response from the server
 */
export async function sendChunk(
  endpoint: string,
  chunk: Blob,
  chunkIndex: number,
  totalChunks: number,
  fileName: string,
  fileSize: number
): Promise<any> {
  const formData = new FormData();
  formData.append("file", chunk, "chunk");
  formData.append("chunk_index", chunkIndex.toString());
  formData.append("total_chunks", totalChunks.toString());
  formData.append("file_name", fileName);
  formData.append("file_size", fileSize.toString());
  const csrf = getCookie("csrftoken");

  const response = await fetch(`/api/v1/${endpoint}?chunked=true`, {
    method: "PUT",
    body: formData,
    credentials: "include", // send cookie with JWT
    headers: {
      "X-CSRFToken": csrf,
    },
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to upload chunk ${chunkIndex}: ${errorText}`);
  }

  // For non-last chunks, just return the status
  if (chunkIndex < totalChunks - 1) {
    return await response.json();
  }

  // For the final chunk, return the complete response
  return await response.json();
}
