import { ThemeContext } from "styled-components";
import { Icon } from "../../icon/Icon";
import { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { Flex } from "../../../layouts/flex/Flex";
import { IconButton } from "../../button/icon/IconButton";
import useToastContext from "../../../../hooks/toastHook";
import { InputStyle } from "../InputStyle";

export type TextInputProps = {
  disabled?: boolean;
  isError?: boolean;
  copyToClipboard?: boolean;

  iconName?: string;
  inverted?: boolean;
  width?: string | number;
  border?: string;
  color?: string;
  autoFocus?: boolean;
  onChange?: (e: any) => any;
  onSave?: () => void;
  onCancel?: () => void;
  onBlur?: () => void;
  onClear?: () => void | undefined;
  onKeyDown?: () => void | undefined;
  type?: string;
  placeholder?: string;
  value?: string;
  name?: string; // Required for form submission
  dataTestId?: string;
  className?: string;
  style?: any;
  isPassword?: boolean;
  isClearable?: boolean;

  initValue?: string;
  isRequired?: boolean;
  saveChangesMode?: boolean;
  queryStatus?: "error" | "idle" | "loading" | "success";
  variant?: "body" | "header";
};

const positionRight: CSSProperties = {
  position: "absolute",
  top: "5px",
  right: "12px",
};

export const InputText = (props: TextInputProps) => {
  const {
    onChange,
    onCancel,
    onSave,
    onBlur,
    onClear,
    onKeyDown,
    value,
    name,
    initValue,
    saveChangesMode,
    iconName,
    inverted,
    dataTestId,
    queryStatus,
    copyToClipboard,
    isRequired,
    isClearable,
    width,
    type = "",
    isPassword = false,
    variant = "body",
  } = props;

  const theme = useContext(ThemeContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const addToast = useToastContext();

  const [hidePassword, setHidePassword] = useState(!!isPassword);
  const [text, setText] = useState<string>(value || "");
  const [isFocused, setFocused] = useState<boolean>(false);

  const handleBlur = () => {
    // Delay the blur handling to check the newly focused element
    setTimeout(() => {
      // Check if the new active element is outside the component
      if (
        containerRef.current &&
        !containerRef.current.contains(document.activeElement)
      ) {
        setFocused(false);
        if (onBlur) onBlur();
      }
    }, 0);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && onKeyDown) onKeyDown();
  };

  const handleSave = () => {
    if (onSave) onSave();
    setFocused(false);
  };

  useEffect(() => {
    setText(value || "");
  }, [value]);

  const copyValueToClipboard = () =>
    value &&
    navigator.clipboard
      .writeText(value)
      .then(() =>
        addToast({
          message: "Token copied to clipboard",
          type: "success",
        })
      )
      .catch((err) =>
        addToast({
          message: `Failed to copy token: ${err}`,
          type: "error",
        })
      );

  return (
    <Flex w100>
      <Flex
        align="center"
        gap="8px"
        style={{ position: "relative", width: width || "" }}
        ref={containerRef}
      >
        {iconName && (
          <span style={{ position: "absolute", left: "12px" }}>
            <Icon
              name={iconName}
              color={inverted ? theme.textWhite : theme.textSecondary}
              size={variant === "header" ? 42 : undefined}
            />
          </span>
        )}
        <InputStyle
          {...props}
          data-testid={dataTestId}
          type={hidePassword ? "password" : type}
          value={text}
          name={name}
          onChange={(e) => {
            onChange && onChange(e);
            setText(e.target.value);
          }}
          style={{ width: width, ...props.style }}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
        {isPassword && (
          <IconButton
            size={variant === "header" ? "large" : "small"}
            onClick={() => setHidePassword((prev) => !prev)}
            iconName={hidePassword ? "eye" : "eyeSlash"}
          />
        )}
        {copyToClipboard && (
          <IconButton
            style={positionRight}
            iconName="copy"
            color={theme.black800}
            size={variant === "header" ? "large" : "small"}
            onClick={copyValueToClipboard}
          />
        )}
        {isClearable && (
          <IconButton
            disabled={!text}
            dataTestId={`${dataTestId}-clear`}
            style={positionRight}
            iconName="cancel"
            size={variant === "header" ? "large" : "small"}
            onClick={() => {
              onClear && onClear();
              setText("");
            }}
          />
        )}

        {saveChangesMode &&
          isFocused &&
          (!queryStatus || queryStatus === "idle") && (
            <Flex
              align="center"
              style={{
                ...positionRight,
                top: variant === "header" ? "0" : "5px",
              }}
            >
              <IconButton
                iconName="cancel"
                label="Cancel"
                dataTestId={`${dataTestId}-cancel`}
                color={theme.primary}
                size={variant === "header" ? "large" : "small"}
                onClick={() => {
                  if (onCancel) onCancel();
                  setFocused(false);
                }}
              />
              <IconButton
                label="Save"
                iconName={"send"}
                dataTestId={`${dataTestId}-send`}
                color={theme.primary}
                size={variant === "header" ? "large" : "small"}
                onClick={() => handleSave()}
                disabled={(isRequired && !value) || initValue === value}
              />
            </Flex>
          )}

        {saveChangesMode && queryStatus !== "idle" && (
          <Flex
            align="center"
            style={{
              ...positionRight,
              top: variant === "header" ? "3px" : "7px",
            }}
          >
            {queryStatus === "loading" && (
              <Icon name="spinner" size={variant === "header" ? 42 : 24} />
            )}
            {queryStatus === "success" && (
              <Icon
                name="check"
                size={variant === "header" ? 42 : 24}
                color={theme.greenPrimary}
              />
            )}
            {queryStatus === "error" && (
              <Icon
                name="activeIssues"
                size={variant === "header" ? 42 : 24}
                color={theme.redPrimary}
              />
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
