import React, { useContext } from "react";
import { Flex } from "../../components/layouts/flex/Flex";
import { Service } from "./types";
import { HeaderSub } from "../../components/elements/typography/Typography";
import { ServiceCard } from "./ServiceCard";
import { ThemeContext } from "styled-components";

type Props = {
  sectionTitle: string;
  services: Service[];
  onClickService: (service: Service) => void;
};
export const ServiceSection = (props: Props) => {
  const theme = useContext(ThemeContext);
  const { sectionTitle, services, onClickService } = props;
  return (
    <Flex column gap="18px" style={{ paddingLeft: "20px" }}>
      <HeaderSub color={theme.textSub}>{sectionTitle}</HeaderSub>
      <Flex gap="12px" flexWrap>
        {services.map((s) => (
          <ServiceCard service={s} onClickService={onClickService} />
        ))}
      </Flex>
    </Flex>
  );
};
