import { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import {
  Finding,
  FindingStatus as FindingStatusType,
} from "../../../types/Finding";
import { Icon } from "../../../components/elements/icon/Icon";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { FindingStatusMenu } from "./FindingStatusMenu";
import { getStatusName } from "../../../shared/findingsHelper";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  finding: Finding;
};

type LabelStyleProps = {
  showMenu: boolean;
};

const LabelStyle = styled(BodyRegular)<LabelStyleProps>`
  color: ${(props) => (props.showMenu ? props.theme.blue800 : "inherit")};
  &:hover {
    color: ${(props) => props.theme.primary};
  }
`;

export const FindingStatus = (props: Props) => {
  const { finding } = props;

  const theme = useContext(ThemeContext);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const iconsColors: Record<FindingStatusType, string> = {
    dismissed: theme.blue300,
    fixed: theme.greenPrimary,
    in_progress: theme.primary,
    presented: theme.primary,
    acceptable_risk: theme.primary,
    re_test: theme.primary,
  };

  return (
    <Flex
      align="center"
      gap="8px"
      style={{
        textOverflow: "ellipsis",
        overflow: "inherit",
      }}
    >
      <Icon
        name={finding.status}
        size={16}
        color={iconsColors[finding.status]}
      />
      <LabelStyle
        data-testid={`${finding.title}-status`}
        className="text-truncate"
        style={{
          fontWeight: showMenu ? 500 : "",
          cursor: "pointer",
          width: "calc(100% - 24px)",
        }}
        onClick={() => setShowMenu((state) => !state)}
        showMenu={showMenu}
        title={getStatusName(finding.status)}
      >
        {getStatusName(finding.status)}
      </LabelStyle>

      {showMenu && (
        <FindingStatusMenu
          close={() => setShowMenu(false)}
          finding={finding}
          customStyle={{ left: "-50px" }}
        />
      )}
    </Flex>
  );
};
