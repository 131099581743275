import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  DEFAULT_STALE_TIME,
  invalidateApiQueries,
  updateContext,
} from "./utils";
import { getItems, getSingleItem, updateItem } from "./sdk";
import {
  AsmConfig,
  AwsDetails,
  CloudFlareDetails,
  Customer,
  EffortEstimationSettings,
  OktaDetails,
  SlaConfig,
  SlackDetails,
} from "../../types/Customer";
import {
  CustomerJiraDetails,
  JiraProjectMapping,
} from "../../types/JiraIntegration";
import { NotificationSettings } from "../../types/Notifications";
import { bitAfterNow } from "../../shared/helper";

const key = "customers";
const logKey = "customers-logs";

// All possible attributes that can pass to API call
interface CustomerParams {
  is_slack_installed?: boolean;
  is_asm_enabled?: boolean;
  sla_config?: SlaConfig;
  slack_settings?: NotificationSettings;
  jira_projects_mapping?: JiraProjectMapping[];
  jira_details?: CustomerJiraDetails;
  slack_details?: SlackDetails;
  cloudflare_details?: CloudFlareDetails | null;
  aws_details?: AwsDetails | null;
  okta_info?: OktaDetails | null;
  auto_scan_new_assets?: boolean;
  effort_estimation_settings?: EffortEstimationSettings;
  onboarding_stage?: number;
  asm_config?: AsmConfig;
}

export interface CustomerContext {
  customerId: number;
  customerData: CustomerParams;
  onSuccessCallback?: () => void;
  onErrorCallback?: (error: Error) => void;
}

export const useApiCustomers = (
  params?: { [key: string]: any },
  enabled: boolean = true
) =>
  useQuery<Customer[], Error>({
    queryKey: [key],
    keepPreviousData: true,
    placeholderData: [],
    enabled: enabled,
    queryFn: async (): Promise<Customer[]> => getItems(key, params),
  });

export const useApiSingleCustomer = (id: number | undefined) =>
  useQuery<Customer | undefined, Error>({
    queryKey: [key, id],
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: !!id,
    queryFn: async (): Promise<Customer | undefined> =>
      getSingleItem(key, id?.toString() || "").then((data) =>
        data ? (data as Customer) : undefined
      ),
  });

export const useApiUpdateCustomer = () => {
  const queryClient = useQueryClient();
  return useMutation<Customer, Error, CustomerContext>({
    mutationKey: [key],
    mutationFn: async ({
      customerId,
      customerData,
    }: CustomerContext): Promise<Customer> =>
      await updateItem(key, customerId, customerData),
    onSuccess: (data: Customer, { onSuccessCallback }) => {
      updateContext({ data, queryKey: [key, { id: data?.id }], queryClient });
      onSuccessCallback && onSuccessCallback();
    },
    onError: (error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
      invalidateApiQueries([logKey], queryClient);
      invalidateApiQueries(["me"], queryClient);
    },
  });
};
