import React, { Dispatch, SetStateAction, useState } from "react";
import { Modal } from "../../../components/elements/modal/Modal";
import {
  BodyBold,
  HeaderSubHover,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { RequiredField } from "../../../components/elements/requiredField/RequiredField";
import { Switch } from "../../../components/elements/switch/Switch";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { CodeBox } from "../../../components/elements/box/Box";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { useApiCustomers } from "../../../hooks/queries/customersContext";
import {
  customersPriorityTierOptions,
  objectsToOptions,
} from "../../../shared/formUtils";
import { TagBadge } from "../../../components/composed/tagsLine/TagBadge";
import useToastContext from "../../../hooks/toastHook";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { useApiCreateCtiItem } from "../../../hooks/queries/ctiItemsContext";
import { CtiItem, emptyCtiItem } from "../../../types/CtiItem";
import { BadgesLine } from "../../../components/elements/badge/BadgesLine";
import { PriorityTier } from "../../../types/Customer";
import { PrimaryBadge } from "../../../components/elements/badge/PrimaryBadge";
import { Checkbox } from "../../../components/elements/checkbox/Checkbox";

type SendChoices = "all" | "tiers" | "specific";

type Props = {
  showModal: Dispatch<SetStateAction<boolean>>;
};

export const SendCtiItemModal = (props: Props) => {
  const { showModal } = props;
  const addToast = useToastContext();
  const { data: customers } = useApiCustomers();
  const { data: products } = useApiProducts({ "admin-mode": true });
  const { mutate: createCtiItem } = useApiCreateCtiItem();
  const [ctiItem, setCtiItem] = useState<CtiItem>(emptyCtiItem);
  const [sendChoice, setSendChoice] = useState<SendChoices>("specific");
  const [selectedTier, setTier] = useState<PriorityTier>("no tier");

  const filteredCustomers = customers?.filter((c) =>
    ctiItem.customers.every((customer) => customer !== c.id)
  );

  const filteredProducts = products?.filter(
    (p) =>
      ctiItem.customers.some((customer) => customer === p.customer) &&
      ctiItem.affected_products.every((product) => product !== p.id)
  );

  const validateForm = (): boolean => {
    if (ctiItem.customers.length === 0) {
      return false;
    }
    if (!ctiItem.image || !ctiItem.link || !ctiItem.title) {
      return false;
    }
    return true;
  };

  const resetForm = () => {
    setCtiItem(emptyCtiItem);
  };

  const submitForm = () => {
    if (validateForm()) {
      createCtiItem({
        ...ctiItem,
        onSuccessCallback() {
          resetForm();
          addToast({
            message: `CTI post sent to ${ctiItem.customers.length} customers!`,
            type: "success",
          });
        },
        onErrorCallback(error) {
          addToast({
            message: `Post creation failed. Error: ${error}`,
            type: "error",
          });
        },
      });
    } else {
      addToast({
        message: "Please complete the form before creating the alert.",
        type: "warning",
      });
    }
  };
  return (
    <Modal
      onClose={() => showModal(false)}
      width="40%"
      height="75%"
      header={"Create CTI Post"}
      style={{ overflowY: "scroll" }}
    >
      <Flex
        column
        gap="16px"
        style={{
          overflowY: "scroll",
          paddingRight: "24px",
          paddingBottom: "24px",
        }}
      >
        <Flex>
          <BodyBold>Post Properties</BodyBold>
        </Flex>
        <Flex column gap="16px">
          <Flex gap="8px" justify="between">
            <Flex>
              <LabelRegular>Show to all customers</LabelRegular>
            </Flex>
            <Switch
              disabled={sendChoice === "all"}
              checked={sendChoice === "all"}
              onChange={() => {
                resetForm();
                setSendChoice("all");
                setCtiItem({
                  ...ctiItem,
                  customers: customers?.map((c) => c.id) || [],
                });
              }}
            />
          </Flex>

          <Flex gap="8px" justify="between">
            <Flex>
              <LabelRegular>Show to a tier of customers</LabelRegular>
            </Flex>
            <Switch
              disabled={sendChoice === "tiers"}
              checked={sendChoice === "tiers"}
              onChange={() => {
                resetForm();
                setSendChoice("tiers");
              }}
            />
          </Flex>
          <Flex gap="8px" justify="between">
            <Flex>
              <LabelRegular>Show to a specific list of customers</LabelRegular>
            </Flex>
            <Switch
              disabled={sendChoice === "specific"}
              checked={sendChoice === "specific"}
              onChange={() => {
                resetForm();
                setSendChoice("specific");
              }}
            />
          </Flex>
        </Flex>
        <Checkbox
          size={"small"}
          label={"Notify the customer/s"}
          state={ctiItem.is_alert_required ? "checked" : "unchecked"}
          onChange={(state) =>
            setCtiItem({
              ...ctiItem,
              is_alert_required: state === "checked" ? true : false,
            })
          }
        />
        <Flex gap="16px" column>
          {sendChoice === "tiers" && (
            <Flex column gap="8px">
              <Flex>
                <LabelRegular>Customer Tier</LabelRegular>
                <RequiredField />
              </Flex>
              <Dropdown
                placeholder="Select tier"
                onChange={(op) => {
                  if (op) {
                    setTier(op.value as PriorityTier);
                    setCtiItem({
                      ...ctiItem,
                      customers:
                        customers
                          ?.filter((c) => c.priority_tier === op.value)
                          .map((c) => c.id) || [],
                    });
                  }
                }}
                value={
                  customersPriorityTierOptions.filter(
                    (t) => t.value === selectedTier
                  )[0]
                }
                options={customersPriorityTierOptions.filter(
                  (op) => op.value !== "all"
                )}
                variant="border"
                size="medium"
              />
              {ctiItem.customers.length > 0 && (
                <PrimaryBadge
                  content={
                    ctiItem.customers.length === 1
                      ? "1 customer selected"
                      : `${ctiItem.customers.length} customers selected`
                  }
                />
              )}
            </Flex>
          )}
          {sendChoice === "specific" && (
            <>
              <Flex column gap="8px">
                <Flex>
                  <LabelRegular>Customers</LabelRegular>
                  <RequiredField />
                </Flex>
                <Dropdown
                  searchable
                  placeholder="Select customers"
                  options={
                    filteredCustomers ? objectsToOptions(filteredCustomers) : []
                  }
                  onChange={(op) => {
                    if (op)
                      setCtiItem({
                        ...ctiItem,
                        customers: [...ctiItem.customers, op.value as number],
                      });
                  }}
                  variant="border"
                  size="medium"
                />
                {ctiItem.customers &&
                  ctiItem.customers.map?.((customer) => (
                    <TagBadge
                      key={customer}
                      option={{
                        label:
                          customers?.find((f) => f.id === customer)?.name || "",
                        value: customer,
                      }}
                      onDeleteOption={(op) => {
                        if (op)
                          setCtiItem({
                            ...ctiItem,
                            customers: ctiItem.customers.filter(
                              (customer) => op.value !== customer
                            ),
                          });
                      }}
                      isEditable={true}
                    />
                  ))}
              </Flex>
              <Flex column gap="8px">
                <Flex>
                  <LabelRegular>Products</LabelRegular>
                  <RequiredField />
                </Flex>
                <Dropdown
                  searchable
                  placeholder="Select products"
                  disabled={ctiItem.customers.length === 0}
                  options={
                    filteredProducts ? objectsToOptions(filteredProducts) : []
                  }
                  onChange={(op) => {
                    if (op)
                      setCtiItem({
                        ...ctiItem,
                        affected_products: [
                          ...ctiItem.affected_products,
                          op.value as number,
                        ],
                      });
                  }}
                  variant="border"
                  size="medium"
                />
                {ctiItem.affected_products &&
                  ctiItem.affected_products.map?.((product) => (
                    <TagBadge
                      key={product}
                      option={{
                        label:
                          products?.find((p) => p.id === product)?.name || "",
                        value: product,
                      }}
                      onDeleteOption={(op) => {
                        if (op)
                          setCtiItem({
                            ...ctiItem,
                            affected_products: ctiItem.affected_products.filter(
                              (product) => op.value !== product
                            ),
                          });
                      }}
                      isEditable={true}
                    />
                  ))}
              </Flex>
            </>
          )}
        </Flex>
        <Flex column gap="8px">
          <Flex>
            <LabelRegular>Title</LabelRegular>
            <RequiredField />
          </Flex>
          <InputText
            width={"100%"}
            onChange={(e) => setCtiItem({ ...ctiItem, title: e.target.value })}
            value={ctiItem.title}
          />
        </Flex>
        <Flex column gap="8px">
          <Flex>
            <LabelRegular>Link to Article</LabelRegular>
            <RequiredField />
          </Flex>
          <InputText
            width={"100%"}
            onChange={(e) => setCtiItem({ ...ctiItem, link: e.target.value })}
            value={ctiItem.link}
          />
        </Flex>
        <Flex column gap="8px">
          <Flex>
            <LabelRegular>Link to Image</LabelRegular>
            <RequiredField />
          </Flex>
          <InputText
            width={"100%"}
            onChange={(e) => setCtiItem({ ...ctiItem, image: e.target.value })}
            value={ctiItem.image}
          />
        </Flex>
        <Flex column gap="8px">
          <BodyBold>Post Preview</BodyBold>
          <CodeBox style={{ width: "100%", minHeight: "200px" }}>
            <Flex column h100 justify="between" gap="16px" w100>
              <img
                src={ctiItem.image}
                alt="placeholder"
                height={"100px"}
                style={{
                  borderRadius: "8px 0 8px 8px",
                  width: "inherit",
                }}
              />

              <HeaderSubHover
                className="d-flex flex-wrap "
                style={{ width: "inherit" }}
              >
                <a
                  href={ctiItem.link}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    width: "inherit",
                  }}
                  data-testid="cti-item-title"
                  rel="noreferrer"
                  target="_blank"
                >
                  {ctiItem.title || "Placeholder title"}
                </a>
              </HeaderSubHover>

              <Flex align="center" gap="8px">
                <LabelRegular>{"Today"}</LabelRegular>
                <BadgesLine
                  badges={
                    products
                      ?.filter((p) => ctiItem.affected_products.includes(p.id))
                      .map((p) => p.name) || []
                  }
                  noWrap={true}
                />
              </Flex>
            </Flex>
          </CodeBox>
        </Flex>
        <Flex justify="end">
          <MainButton
            label={"Send Post"}
            onClick={submitForm}
            disabled={false}
            dataTestId="create-alert-btn"
            size="medium"
          />
        </Flex>
      </Flex>
    </Modal>
  );
};
