import React, { useState } from "react";
import { Flex } from "../../components/layouts/flex/Flex";
import { HeaderMain } from "../../components/elements/typography/Typography";
import { ServicePane } from "./ServicePane";
import { services } from "./servicesConfig";
import { Service } from "./types";
import { ServiceSection } from "./ServiceSection";
import { useIsSuperuser } from "../../hooks/useIsSuperuser";

export const Marketplace = () => {
  const [serviceToShow, setServiceToShow] = useState<Service | undefined>(
    undefined
  );

  const isSuperuser = useIsSuperuser();

  const onClickService = (service: Service) => {
    setServiceToShow(service);
  };

  const onCloseServicePane = () => {
    setServiceToShow(undefined);
  };

  if (!isSuperuser) {
    return (
      <Flex column w100 h100>
        <HeaderMain>404 NOT FOUND</HeaderMain>
      </Flex>
    );
  }

  return (
    <Flex column gap="16px" style={{ paddingBottom: "24px" }}>
      <HeaderMain>Services</HeaderMain>
      <Flex w100 h100 gap="44px" column>
        <ServiceSection
          sectionTitle={"Scanning"}
          services={services}
          onClickService={onClickService}
        />
        <ServiceSection
          sectionTitle={"WASP Platform"}
          services={services}
          onClickService={onClickService}
        />
        <ServiceSection
          sectionTitle={"Solutions"}
          services={services}
          onClickService={onClickService}
        />
        <ServiceSection
          sectionTitle={"Corporate Security"}
          services={services}
          onClickService={onClickService}
        />
      </Flex>
      {serviceToShow && (
        <ServicePane service={serviceToShow} onClose={onCloseServicePane} />
      )}
    </Flex>
  );
};
