import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import {
  HeaderSecondary,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Toggle } from "../../../components/elements/toggle/Toggle";
import FindingsLegend, { IFindingsLegend } from "./FindingsLegend";
import FindingsOverTime from "./findingsOverTime/FindingsOverTime";
import FindingsFunnel from "./findingsFunnel/FindingsFunnel";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { findingsTimeOptions } from "../filters/Timeframe";
import { SingleValue } from "react-select";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { Mixpanel } from "../../../shared/mixpanel";
import { Flex } from "../../../components/layouts/flex/Flex";
import { DatePicker } from "../../../components/elements/datetimePicker/DatePicker";
import { dateObjToDateString } from "../../../shared/helper";
import { Filters } from "../Findings";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_MEDIUM_LAPTOP_WIDTH } from "../../../shared/consts";

const defaultLegend = {
  info: true,
  low: true,
  medium: true,
  high: true,
  critical: true,
};

export type CustomTimeframe = {
  after?: string;
  before: string;
};

export const customTimeOption = { label: "Custom Dates", value: "-1" };
export const funnelTimeOptions = [...findingsTimeOptions, customTimeOption];
const overTimeOptions = [
  { label: "Last 30 days", value: 30 },
  { label: "Last 90 days", value: 90 },
  { label: "Last Year", value: 365 },
  customTimeOption,
];

const today = new Date();

export const FindingsChartsContainer = ({
  selectedProduct,
}: {
  selectedProduct: number | "all";
}) => {
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isSmallScreen = screenWidth < SCREEN_MEDIUM_LAPTOP_WIDTH;

  const [legend, setLegend] = useState<IFindingsLegend>(defaultLegend);
  const [isFunnel, setIsFunnel] = useState<boolean>(true);

  const [funnelTimeframe, setFunnelTimeframe] = useState<SingleValue<Option>>(
    funnelTimeOptions[5]
  );
  const [overTimeTimeframe, setOverTimeframe] = useState<SingleValue<Option>>(
    overTimeOptions[2]
  );

  const [isCustomTimeframe, setIsCustomTimeframe] = useState(false);
  const [customTimeframe, setCustomTimeframe] = useState<CustomTimeframe>({
    before: dateObjToDateString(today),
  });

  const [filters, setFilters] = useState<Filters>({
    timeframe: isFunnel ? funnelTimeframe?.value : overTimeTimeframe?.value,
    stats_type: "funnel",
  });

  // Sync to the product filters from page top
  useEffect(() => {
    if (
      (!Object.keys(filters).includes("products") &&
        selectedProduct === "all") ||
      selectedProduct === filters.products
    )
      return;
    if (selectedProduct === "all") {
      let newFilters = { ...filters };
      delete newFilters.products;
      setFilters(newFilters);
    } else {
      setFilters((prev) => ({ ...prev, products: selectedProduct }));
    }
  }, [selectedProduct, filters]);

  // Apply theme change on funnel graph
  const [themeName, setThemeName] = useState(theme.name);
  useEffect(() => {
    if (theme.name === themeName) return;
    setThemeName("");
    setTimeout(() => setThemeName(theme.name), 100);
  }, [theme.name, themeName]);

  // On charts switching - change legend and timeframe options
  const handleToggle = (funnelState: boolean) => {
    setIsFunnel(!funnelState);
    const overTimeLegend = { total: true, ...defaultLegend };
    const timeframe = funnelState
      ? overTimeTimeframe?.value
      : funnelTimeframe?.value;
    setLegend(!funnelState ? defaultLegend : overTimeLegend);
    setFilters((prev) => ({
      ...prev,
      timeframe,
      stats_type: funnelState ? "over_time" : "funnel",
    }));
    setIsCustomTimeframe(timeframe === "-1");
  };

  const handleTimeframeSelect = (option: SingleValue<Option>) => {
    Mixpanel.track("Dashboard - Findings Chart - Timeframe selection", {
      timeSelected: option?.label || "",
    });
    if (isFunnel) setFunnelTimeframe(option);
    else setOverTimeframe(option);
    let newFilters: Filters = { ...filters, timeframe: option?.value };
    // change filters only if custom date
    setIsCustomTimeframe(option?.value === "-1");
    if (option?.value === "-1") {
      newFilters.before = customTimeframe.before;
      if (customTimeframe.after) newFilters.after = customTimeframe.after;
    }
    setFilters(newFilters);
  };

  return (
    <Box
      data-tut="dashboard-funnel"
      className="w-100 d-flex flex-column gap-16"
      style={{ overflowX: "clip" }}
    >
      <Flex
        flexWrap={isSmallScreen}
        align="center"
        justify="between"
        gap="16px"
        w100
      >
        <Flex align="center" gap="16px" flexWrap w100>
          <Flex align="center" gap="8px">
            <HeaderSecondary>Findings</HeaderSecondary>
            <Toggle
              checked={isFunnel}
              onChange={() => {
                Mixpanel.track("Toggle press", {
                  isFunnel: isFunnel,
                  isOverTime: !isFunnel,
                });
                handleToggle(isFunnel);
              }}
              offText="Over Time"
              onText="Funnel"
              width="168px"
            />
          </Flex>

          <SeparatorVertical style={{ height: "34px" }} />

          <Dropdown
            onChange={handleTimeframeSelect}
            options={isFunnel ? funnelTimeOptions : overTimeOptions}
            value={isFunnel ? funnelTimeframe : overTimeTimeframe}
            dataTestId="timeframe-dropdown"
            width="130px"
          />

          {isCustomTimeframe && (
            <Flex align="center" gap="24px">
              <SeparatorVertical style={{ height: "34px" }} />

              <Flex align="center" gap="8px" style={{ width: "200px" }}>
                <LabelRegular>After</LabelRegular>
                <DatePicker
                  value={customTimeframe.after}
                  max={customTimeframe.before}
                  onChange={(e) => {
                    setCustomTimeframe((prev) => ({
                      ...prev,
                      after: e.target.value,
                    }));

                    let newFilters: Filters = {
                      ...filters,
                      after: e.target.value,
                      timeframe: customTimeOption.value,
                    };
                    setFilters(newFilters);
                    // if (customTimeframe.before)
                    //   newFilters.before = e.target.value;
                    // setTimeframe(customTimeOption);
                  }}
                />
              </Flex>
              <Flex align="center" gap="8px" style={{ width: "270px" }}>
                <LabelRegular>Before</LabelRegular>
                <DatePicker
                  value={customTimeframe.before}
                  min={customTimeframe.after}
                  max={dateObjToDateString(today)}
                  onChange={(e) => {
                    setCustomTimeframe((prev) => ({
                      ...prev,
                      before: e.target.value,
                    }));
                    let newFilters: Filters = {
                      ...filters,
                      before: e.target.value,
                      timeframe: customTimeOption.value,
                    };
                    // if (customTimeframe.before)
                    //   newFilters.before = customTimeframe.after;
                    setFilters(newFilters);
                    // setTimeframe(customTimeOption);
                  }}
                />
              </Flex>
            </Flex>
          )}
        </Flex>
        <FindingsLegend legend={legend} setLegend={setLegend} />
      </Flex>

      {isFunnel ? (
        <div data-testid="funnal-container">
          {themeName && (
            <FindingsFunnel
              selectedProduct={selectedProduct}
              legend={legend}
              timeframe={funnelTimeframe}
              filters={filters}
              customTimeframe={customTimeframe}
              isCustomTimeframe={isCustomTimeframe}
            />
          )}
        </div>
      ) : (
        <FindingsOverTime
          legend={legend}
          timeframe={overTimeTimeframe}
          filters={filters}
        />
      )}
    </Box>
  );
};
