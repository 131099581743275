import { IconProps } from "./WaspIcon";

export function ProjectsMenuIcon(props: IconProps) {
  const size = props.size || 16;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_12853_87532"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="-1"
        width="24"
        height="25"
      >
        <path d="M24 -0.00195312H0V23.998H24V-0.00195312Z" fill="white" />
      </mask>
      <g mask="url(#mask0_12853_87532)">
        <mask
          id="mask1_12853_87532"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="-1"
          width="24"
          height="25"
        >
          <path d="M0 -0.00463676H24V23.9954H0V-0.00463676Z" fill="white" />
        </mask>
        <g mask="url(#mask1_12853_87532)">
          <path
            d="M13.7614 1.06156C12.7928 0.577158 11.2077 0.577158 10.239 1.06156L1.94717 5.20738C0.978547 5.69183 0.978547 6.48425 1.94717 6.96856L10.239 11.1146C11.2077 11.5989 12.7928 11.5989 13.7614 11.1146L22.0531 6.96856C23.0219 6.48425 23.0219 5.69183 22.0531 5.20738L13.7614 1.06156Z"
            stroke={props.color || "currentColor"}
            strokeWidth="1.40625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.09303 14.9483L1.94717 17.0212C0.978547 17.5056 0.978547 18.2981 1.94717 18.7824L10.239 22.9284C11.2076 23.4126 12.7928 23.4126 13.7614 22.9284L22.053 18.7824C23.0218 18.2981 23.0218 17.5056 22.053 17.0212L17.9073 14.9483"
            stroke={props.color || "currentColor"}
            strokeWidth="1.40625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.09303 9.04232L1.94717 11.1154C0.978547 11.5997 0.978547 12.3921 1.94717 12.8764L10.239 17.0223C11.2076 17.5067 12.7928 17.5067 13.7614 17.0223L22.053 12.8764C23.0218 12.3921 23.0218 11.5997 22.053 11.1154L17.9073 9.04232"
            stroke={props.color || "currentColor"}
            strokeWidth="1.40625"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}
