import { useContext, useState } from "react";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { Flex } from "../../../components/layouts/flex/Flex";
import { FindingStatusMenu } from "../findingStatus/FindingStatusMenu";
import { ConfirmModal } from "../../../components/composed/confirmModal/ConfirmModal";
import {
  useApiDeleteFindingsBulk,
  useApiUpdateFindingsBulk,
} from "../../../hooks/queries/findingContext";
import useToastContext from "../../../hooks/toastHook";
import { Finding, FindingEdit } from "../../../types/Finding";
import { Filters } from "../Findings";
import { AssigneeMenu } from "../../../components/composed/assignee/AssigneeMenu";
import { PushFindingJiraMenu } from "../findingsSync/pushFindingJira/PushFindingJiraMenu";
import { MergeFindingsMenu } from "./MergeFindingsMenu";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { DeleteModal } from "../../../components/composed/deleteModal/DeleteModal";
import { ThemeContext } from "styled-components";
import { useApiMe } from "../../../hooks/queries/meContext";
import { PublishBulkFindingsMenu } from "./PublishBulkFindingsMenu";

type Props = {
  findings: Finding[];
  filters?: Filters;
  isSelectAll?: boolean;
  resetSelected?: () => void;
};

export const FindingsBulkActions = (props: Props) => {
  const { findings, filters, isSelectAll = false, resetSelected } = props;
  const addToast = useToastContext();
  const theme = useContext(ThemeContext);
  const isSuperuser = useIsSuperuser();
  const { data: me } = useApiMe();

  const {
    mutate: deleteFindings,
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
    reset: resetDelete,
  } = useApiDeleteFindingsBulk(filters);

  const { mutate, status, reset } = useApiUpdateFindingsBulk(filters);

  const bulkUpdate = () => {
    if (!findings || !updates || !filters) return;
    mutate({
      findingsData: {
        findings: findings.map((f) => f.id),
        all: isSelectAll,
        updates,
      },
      onSuccessCallback: () => {
        setUpdates(null);
        addToast({ message: `Findings successfully updated`, type: "success" });
        setShowStatusMenu(false);
        setShowAssignMenu(false);
        reset();
      },
      onErrorCallback: (error: Error) =>
        addToast({ message: error.message, type: "error" }),
    });
  };

  const bulkDelete = () => {
    deleteFindings({
      findingsData: {
        all: isSelectAll,
        findings: findings.map((f) => f.id),
        updates: {},
      },
      onErrorCallback: (error: Error) =>
        addToast({ message: error.message, type: "error" }),
    });
  };

  const [showStatusMenu, setShowStatusMenu] = useState<boolean>(false);
  const [showAssignMenu, setShowAssignMenu] = useState<boolean>(false);
  const [showSendToJiraMenu, setShowSendToJiraMenu] = useState<boolean>(false);
  const [showMergeMenu, setShowMergeMenu] = useState<boolean>(false);
  const [showPublishMenu, setShowPublishMenu] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [updates, setUpdates] = useState<FindingEdit | null>(null);

  return (
    <>
      {!!findings.length && (
        <>
          <Flex w100 align="center" gap="8px" style={{ position: "relative" }}>
            <Flex column>
              <LinkButton
                iconName="send"
                label="Send to Jira"
                onClick={() => setShowSendToJiraMenu((state) => !state)}
                disabled={findings.every(
                  (finding) => finding.customer_jira_issue_key
                )}
              />
              {showSendToJiraMenu && (
                <div>
                  <PushFindingJiraMenu
                    onClose={() => setShowSendToJiraMenu(false)}
                    findings={findings}
                  />
                </div>
              )}
            </Flex>
            <SeparatorVertical height="24px" />
            <Flex column>
              <LinkButton
                iconName="insightsOutline"
                label="Change Status"
                onClick={() => setShowStatusMenu((state) => !state)}
              />
              {showStatusMenu && (
                <div>
                  <FindingStatusMenu
                    close={() => !showConfirmModal && setShowStatusMenu(false)}
                    setShowConfirmModal={setShowConfirmModal}
                    setUpdates={setUpdates}
                  />
                </div>
              )}
            </Flex>
            <SeparatorVertical height="24px" />
            <Flex column>
              <LinkButton
                iconName="user"
                label="Assign"
                onClick={() => setShowAssignMenu((state) => !state)}
              />
              {showAssignMenu && (
                <div>
                  <AssigneeMenu
                    close={() => !showConfirmModal && setShowAssignMenu(false)}
                    setShowConfirmModal={setShowConfirmModal}
                    setUpdates={setUpdates}
                  />
                </div>
              )}
            </Flex>
            {isSuperuser && (
              <>
                <SeparatorVertical height="24px" />
                <Flex column>
                  <LinkButton
                    iconName="merge"
                    label="Merge"
                    onClick={() => setShowMergeMenu((state) => !state)}
                  />
                  {showMergeMenu && (
                    <div>
                      <MergeFindingsMenu
                        close={() =>
                          !showConfirmModal && setShowMergeMenu(false)
                        }
                        findings={findings}
                      />
                    </div>
                  )}
                </Flex>
              </>
            )}
            {isSuperuser && (
              <>
                <SeparatorVertical height="24px" />
                <Flex column>
                  <LinkButton
                    iconName="wasp"
                    label="Publish to WASP"
                    onClick={() => setShowPublishMenu((state) => !state)}
                  />
                  {showPublishMenu && (
                    <div>
                      <PublishBulkFindingsMenu
                        close={() =>
                          !showConfirmModal && setShowPublishMenu(false)
                        }
                        findings={findings}
                      />
                    </div>
                  )}
                </Flex>
              </>
            )}
            {isSuperuser ||
            (me?.customer.is_multi_tenant && me?.can_manage_customer) ? (
              <>
                <SeparatorVertical height="24px" />
                <LinkButton
                  iconColor={theme.redPrimary}
                  iconName="remove"
                  label="Delete"
                  onClick={() => setShowDeleteModal(true)}
                />
              </>
            ) : null}
          </Flex>
          {showConfirmModal && !!updates && (
            <ConfirmModal
              isLoading={status === `loading`}
              isSuccess={status === `success`}
              confirmButtonText="Apply"
              successMessage="Changes applied successfully"
              title={`The changes will be applied to ${isSelectAll ? "all" : findings?.length} findings`}
              onClose={() => setShowConfirmModal(false)}
              onConfirm={bulkUpdate}
            />
          )}
          {showDeleteModal && (
            <DeleteModal
              onClose={() => {
                setShowDeleteModal(false);
                if (isSuccessDelete) {
                  resetDelete();
                  resetSelected && resetSelected();
                  setShowDeleteModal(false);
                }
              }}
              onConfirm={bulkDelete}
              body={`This action cannot be undone!`}
              itemName={`selected findings`}
              itemType="findings"
              isSuccess={isSuccessDelete}
              isLoading={isLoadingDelete}
            />
          )}
        </>
      )}
    </>
  );
};
