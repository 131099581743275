import React, { useContext, useEffect } from "react";
import { AssetsView, AssetsViewProps } from "../../../../types/AssetsView";
import { useApiAssetsViews } from "../../../../hooks/queries/assetsViewsContext";
import { Flex } from "../../../../components/layouts/flex/Flex";
import {
  fromBase64AssetsView,
  toBase64AssetsView,
} from "../../../../shared/helper";
import { useSearchParams } from "react-router-dom";
import { SkeletonLoading } from "../../../../components/elements/loading/SkeletonLoading";
import { TextButton } from "../../../../components/elements/button/text/TextButton";
import { ThemeContext } from "styled-components";

export const QuickFilters = () => {
  const theme = useContext(ThemeContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentView, setCurrentView] = React.useState<AssetsViewProps | null>(
    null
  );

  const { data: views, isFetching } = useApiAssetsViews();

  useEffect(() => {
    // Sets the assets view state from URL params,
    // If no view attribute on URL params, sets the URL to default view
    let encodedView = searchParams.get("view");
    if (encodedView) {
      let decodedView = fromBase64AssetsView(encodedView);
      if (decodedView) setCurrentView(decodedView);
    }
  }, [searchParams]);

  const handleFiltersViewChange = (view: AssetsView) => {
    setCurrentView(view);
    const viewBase64 = toBase64AssetsView(view);
    // Create a new URLSearchParams object based on the current one
    const newParams = new URLSearchParams(searchParams);
    // Set the view parameter
    newParams.set("view", viewBase64);
    // Update the search params with the new object
    setSearchParams(newParams);
  };

  return (
    <Flex
      column
      w100
      gap="8px"
      key={searchParams.toString()}
      style={{
        maxWidth: "240px",
        maxHeight: "calc(100% - 325px)",
        overflowY: "auto",
      }}
    >
      {isFetching && (
        <>
          <SkeletonLoading width="100%" height="54px" />
          <SkeletonLoading width="100%" height="54px" />
          <SkeletonLoading width="100%" height="54px" />
          <SkeletonLoading width="100%" height="54px" />
          <SkeletonLoading width="100%" height="54px" />
          <SkeletonLoading width="100%" height="54px" />
          <SkeletonLoading width="100%" height="54px" />
          <SkeletonLoading width="100%" height="54px" />
        </>
      )}
      {views?.map((view) => (
        <TextButton
          key={view.id}
          onClick={() => handleFiltersViewChange(view)}
          label={view.name}
          color={currentView?.name === view.name ? undefined : theme.black650}
          style={{
            textAlign: "start",
            backgroundColor:
              currentView?.name === view.name ? theme.black400 : "",
          }}
        />
      ))}
    </Flex>
  );
};
