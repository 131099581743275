import { IconProps } from "./WaspIcon";

export const WaspAiMenuIcon = (props: IconProps) => {
  const size = props.size || 32;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12931_18028)">
        <path
          d="M13.9751 6.40617C11.0658 5.79628 8.79188 3.52233 8.18208 0.612984C8.10718 0.255844 7.79218 0 7.42716 0C7.06214 0 6.74714 0.255844 6.67229 0.613031C6.06244 3.52233 3.78849 5.79624 0.879191 6.40603C0.522004 6.48089 0.266113 6.79594 0.266113 7.16091C0.266113 7.52588 0.521957 7.84092 0.879191 7.91578C3.78844 8.52567 6.0623 10.7996 6.67214 13.7089C6.747 14.0661 7.062 14.322 7.42702 14.322C7.79199 14.322 8.10704 14.0662 8.18189 13.7089C8.79179 10.7996 11.0658 8.52572 13.9751 7.91597C14.3323 7.84111 14.5882 7.52611 14.5882 7.16109C14.5881 6.79608 14.3323 6.48103 13.9751 6.40617ZM7.42707 11.2187C6.55219 9.46261 5.12546 8.03583 3.36938 7.16095C5.1255 6.28608 6.55229 4.85934 7.42716 3.10322C8.30204 4.85939 9.72877 6.28613 11.4848 7.16105C9.72872 8.03592 8.30194 9.46261 7.42707 11.2187Z"
          fill={props.color || "currentColor"}
        />
        <path
          d="M12.7005 19.1477C11.3298 18.8604 10.2584 17.789 9.97101 16.4182C9.89615 16.061 9.58115 15.8051 9.21614 15.8051C8.85117 15.8051 8.53612 16.0609 8.46126 16.4181C8.17387 17.7889 7.10245 18.8603 5.73173 19.1476C5.37454 19.2225 5.11865 19.5375 5.11865 19.9025C5.11865 20.2675 5.3745 20.5825 5.73173 20.6574C7.1024 20.9447 8.17382 22.0161 8.46117 23.3869C8.53603 23.7441 8.85103 24 9.21604 24C9.58101 24 9.89606 23.7442 9.97092 23.3869C10.2583 22.0161 11.3297 20.9448 12.7005 20.6574C13.0577 20.5826 13.3136 20.2675 13.3136 19.9026C13.3136 19.5376 13.0577 19.2226 12.7005 19.1477ZM9.21609 21.2814C8.85009 20.7373 8.38134 20.2686 7.83736 19.9026C8.38143 19.5366 8.85009 19.0678 9.21609 18.5239C9.58214 19.0679 10.0508 19.5366 10.5948 19.9026C10.0508 20.2686 9.58209 20.7373 9.21609 21.2814Z"
          fill={props.color || "currentColor"}
        />
        <path
          d="M23.1205 12.0063C21.2677 11.6179 19.8196 10.1697 19.4313 8.317C19.3564 7.95981 19.0414 7.70392 18.6764 7.70392C18.3114 7.70392 17.9964 7.95972 17.9215 8.31695C17.5331 10.1697 16.085 11.6178 14.2322 12.0062C13.875 12.0811 13.6191 12.3961 13.6191 12.7611C13.6191 13.126 13.875 13.441 14.2322 13.516C16.085 13.9044 17.5331 15.3525 17.9214 17.2052C17.9963 17.5624 18.3113 17.8183 18.6763 17.8183C19.0413 17.8183 19.3563 17.5625 19.4312 17.2052C19.8196 15.3525 21.2677 13.9044 23.1205 13.516C23.4777 13.4411 23.7335 13.1261 23.7335 12.7612C23.7335 12.3962 23.4777 12.0812 23.1205 12.0063ZM18.6764 14.9534C18.1394 14.0525 17.385 13.2981 16.4841 12.7611C17.385 12.2242 18.1394 11.4699 18.6764 10.5689C19.2134 11.4699 19.9677 12.2242 20.8686 12.7612C19.9678 13.2981 19.2133 14.0525 18.6764 14.9534Z"
          fill={props.color || "currentColor"}
        />
      </g>
      <defs>
        <clipPath id="clip0_12931_18028">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
