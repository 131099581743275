import { useEffect, useMemo, useState } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  LabelMini,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { Switch } from "../../../components/elements/switch/Switch";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import {
  useApiAssetsPaging,
  useApiCreateAsset,
  useApiUpdateAsset,
  useApiUploadMobileBundle,
} from "../../../hooks/queries/assetsContext";
import { stringsListToSelectOptions } from "../../../shared/helper";
import useToastContext from "../../../hooks/toastHook";
import {
  Asset,
  ASSET_TYPES_NAMES,
  AssetEdit,
  AssetType,
} from "../../../types/Asset";
import {
  OptionalField,
  RequiredField,
} from "../../../components/elements/requiredField/RequiredField";
import { FormError } from "../../../components/elements/FormsElements/FormError";
import { Customer } from "../../../types/Customer";
import { UploadButton } from "../../../components/elements/button/upload/UploadButton";
import { AssetCloudForm, isValidCloudIntegration } from "./AssetCloudForm";
import { useApiRunScan } from "../../../hooks/queries/scansContext";
import { useApiMe } from "../../../hooks/queries/meContext";
import { isIpAddress } from "../AssetUtils";

type Props = {
  isOnboarding?: boolean;
  newAssetName?: string;
  customer?: Customer;
  onApply?: (asset: Asset) => void;
  hideAsmButton?: boolean;
  hideParentAsset?: boolean;
  defaultProdEnvironment?: boolean;
  editedAsset?: Asset | null;
  isFormDisabled?: boolean;
};

export const AddAssetForm = (props: Props) => {
  const {
    isOnboarding = false,
    newAssetName = "",
    customer,
    onApply,
    hideAsmButton,
    hideParentAsset,
    defaultProdEnvironment,
    editedAsset,
    isFormDisabled,
  } = props;

  const addToast = useToastContext();
  const isSuperuser = useIsSuperuser();
  const { mutate: startScan } = useApiRunScan();
  const { data: me } = useApiMe();

  const { data: products } = useApiProducts(
    customer ? { "admin-mode": true, customer: customer.id } : undefined
  );
  const {
    data: assets,
    hasNextPage,
    fetchNextPage,
  } = useApiAssetsPaging(
    customer ? { "admin-mode": true, customer: customer.id } : undefined
  );
  const { mutate: createAsset, isLoading: isCreating } = useApiCreateAsset(
    customer ? { "admin-mode": true, customer: customer.id } : undefined
  );
  const { mutate: updateAsset, isLoading: isUpdating } = useApiUpdateAsset();

  const { mutate: uploadMobileBundle } = useApiUploadMobileBundle();

  const getAssets = () => {
    return assets?.pages.map((page) => page.results || []).flat() || [];
  };

  const initAsset: AssetEdit = useMemo(
    () => ({
      name: newAssetName,
      parent_asset: null,
      product: products?.length === 1 ? products[0].id : null,
      environment: defaultProdEnvironment ? "Production" : "",
      is_asm_enabled: false,
      properties: {},
      source: "manual",
      type: "domain",
    }),
    [products, defaultProdEnvironment] // eslint-disable-line
  );
  const excludeTypes = ["s3_bucket"];
  const assetTypesOptions: Option[] = Object.entries(ASSET_TYPES_NAMES)
    .filter(([k, v]) => !excludeTypes.includes(k))
    .map(([k, v]) => ({
      label: v,
      value: k,
    }));

  const assetsOptions = getAssets().map((asset) => ({
    label: asset.name,
    value: asset.id,
  }));

  const productsOptions = products?.map((p) => ({
    value: p.id,
    label: p.name,
  }));

  const envOptions = stringsListToSelectOptions([
    "Testing",
    "Development",
    "Production",
    "Staging",
  ]);

  const [formValues, setFormValues] = useState<AssetEdit>(initAsset);
  const [formErrors, setFormErrors] = useState<string[]>([]);

  useEffect(() => {
    setFormValues(editedAsset || initAsset);
  }, [editedAsset, initAsset]);

  const validateDomain = (values: AssetEdit): string[] => {
    const errors: string[] = [];
    if (values?.name?.includes("www.") || values?.name?.includes("https"))
      errors?.push("Domain name should not contain 'www.' or https://");
    const domainRegex =
      /^((?!-)(?!.*--)(?!.*\.\.)[A-Za-z0-9-]{1,63}\.)+[A-Za-z]{2,}$/;
    if (!domainRegex.test(values?.name || "")) {
      errors?.push("Invalid domain name");
    }
    return errors;
  };

  const validateIPAddress = (values: AssetEdit): string[] => {
    const errors: string[] = [];
    // Example valid inputs: "127.0.0.1", "192.168.1.10"
    // Example invalid inputs: "999.0.0.1", "1.1.1"
    const ipRegex =
      /^((25[0-5]|(2[0-4]\d)|([01]?\d?\d))\.){3}(25[0-5]|(2[0-4]\d)|([01]?\d?\d))$/;
    if (!ipRegex.test(values?.name || "")) {
      errors?.push("Invalid IP address");
    }
    return errors;
  };

  const validateMobile = (values: AssetEdit): string[] => {
    const errors: string[] = [];
    if (!values.name || values.name === "")
      errors?.push("Mobile app name is empty");
    return errors;
  };

  const validate = (values: AssetEdit): string[] => {
    if (values.type === "domain") return validateDomain(values);
    else if (values.type === "ip") return validateIPAddress(values);
    else if (values.type === "mobile") return validateMobile(values);
    else return [];
  };

  const handleCloudAssetAdded = () => {
    startScan({
      customerId: Number(me?.customer?.id || "-1"),
      customerName: me?.customer?.name || "",
      scannerName: "cloud_assets_mapper",
      username: me?.email || "",

      onSuccessCallback() {
        addToast({
          type: "success",
          message: "Start mapping cloud assets",
        });
      },
      onErrorCallback(error) {
        addToast({
          type: "error",
          message: `Failed to start mapping cloud assets - Error: ${error.message}`,
        });
      },
    });
  };

  const applyForm = () => {
    if (isFormDisabled) return;

    const errors = validate(formValues);
    if (!!errors.length) {
      setFormErrors(errors);
      return;
    }

    const mobileBundleUpload = (assetId: number) => {
      if (formValues.mobile_app_bundle)
        uploadMobileBundle({
          mobile_app_bundle: formValues.mobile_app_bundle,
          assetId: assetId,
          onSuccessCallback(data) {
            addToast({
              type: "success",
              message: `Uploaded ${data.mobile_app_bundle_name}.`,
            });
          },
          onErrorCallback(error) {
            addToast({
              type: "error",
              message: `Failed to upload app bundle - Error: ${error.message}`,
            });
          },
        });
    };

    const callbacks = {
      onSuccessCallback: (asset: Asset) => {
        if (asset.type === "mobile" && !editedAsset) {
          mobileBundleUpload(asset.id);
        }
        addToast({
          message: `Asset ${asset.name} ${
            editedAsset ? "updated" : "created"
          } successfully`,
          type: "success",
        });
        if (asset.type === "cloud") {
          handleCloudAssetAdded();
        }
        onApply && onApply(asset);
      },
      onErrorCallback(error: Error) {
        addToast({
          message: `Failed to ${
            editedAsset ? "update" : "create"
          } asset - ${error}`,
          type: "error",
        });
      },
    };

    if (editedAsset)
      updateAsset({
        assetId: editedAsset.id,
        assetData: formValues,
        ...callbacks,
      });
    else
      createAsset(
        customer
          ? {
              customer_id: customer.id,
              ...formValues,
              ...callbacks,
            }
          : {
              ...formValues,
              ...callbacks,
            }
      );
  };

  function handleTypeChanges(opt: Option) {
    if (opt)
      setFormValues((prev) => ({
        ...prev,
        type: opt.value as AssetType,
      }));
    if (opt?.value === "cloud")
      setFormValues((prev) => ({
        ...prev,
        is_asm_enabled: true,
      }));
  }

  const onChangeName = (value: string) => {
    // When name changes, detect if it's an IP address and update type accordingly
    if (value) {
      const assetType = isIpAddress(value) ? "ip" : "domain";
      setFormValues({
        ...formValues,
        name: value,
        type: assetType,
      });
    } else {
      setFormValues({
        ...formValues,
        name: value,
      });
    }
  };

  return (
    <Flex w100 column gap="24px">
      <Flex w100 column gap="8px">
        {customer && (
          <>
            <Flex gap="8px" align="center">
              <LabelRegular>Customer</LabelRegular>
              <RequiredField />
            </Flex>
            <InputText
              width="100%"
              placeholder={customer?.name}
              disabled={true}
            />
          </>
        )}
        <Flex w100 column gap="8px">
          <Flex gap="8px" align="center">
            <LabelRegular>Asset Type</LabelRegular>
            <RequiredField />
          </Flex>
          <Dropdown
            dataTestId="asset-type"
            placeholder="Select asset type"
            options={assetTypesOptions}
            onChange={(o) => handleTypeChanges(o as Option)}
            value={assetTypesOptions?.find(
              (opt) => formValues.type && opt.value === formValues.type
            )}
            variant="border"
            disabled={isOnboarding || isFormDisabled}
          />
        </Flex>
        {formValues.type && ["domain", "ip"].includes(formValues.type) && (
          <>
            <Flex gap="8px" align="center">
              <LabelRegular>
                {formValues.type === "domain" ? "Domain Name" : "IP Address"}
              </LabelRegular>
              <RequiredField />
            </Flex>
            <InputText
              width="100%"
              value={formValues.name ? formValues.name : newAssetName}
              onChange={(e) => onChangeName(e.target.value)}
              isError={!!formErrors.length}
              dataTestId="asset-name"
              placeholder={
                formValues.type === "domain"
                  ? "your-domain-name.com"
                  : "1.1.1.1"
              }
              disabled={isFormDisabled}
            />
            {formErrors.map((e) => (
              <FormError key={e} errorMessage={e} />
            ))}

            {!hideParentAsset && (
              <Flex w100 column gap="8px">
                <Flex justify="between">
                  <LabelRegular>Parent Asset</LabelRegular>
                  <OptionalField />
                </Flex>
                <Dropdown
                  options={assetsOptions}
                  onChange={(opt) =>
                    setFormValues((prev) => ({
                      ...prev,
                      parent_asset: parseInt(`${opt?.value}`),
                    }))
                  }
                  value={assetsOptions?.find(
                    (opt) => opt.value === formValues.parent_asset
                  )}
                  variant="border"
                  onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
                  dataTestId="asset-parent"
                  searchable
                  disabled={isFormDisabled}
                />
              </Flex>
            )}
          </>
        )}
        {formValues.type === "mobile" && (
          <Flex column gap="8px">
            <Flex gap="8px" align="center">
              <LabelRegular>Mobile App Name</LabelRegular>
              <RequiredField />
            </Flex>
            <InputText
              width="100%"
              value={formValues.name ? formValues.name : newAssetName}
              onChange={(e) =>
                setFormValues((prev) => ({ ...prev, name: e.target.value }))
              }
              dataTestId="asset-name"
              isError={!!formErrors.length}
              placeholder="your-secure-mobile-app"
              disabled={isFormDisabled}
            />
            {formErrors.map((e) => (
              <FormError errorMessage={e} />
            ))}
            <UploadButton
              label={"Upload Mobile App Bundle (APK/IPA)"}
              onClick={(event) => {
                setFormValues((prev) => ({
                  ...prev,
                  mobile_app_bundle: event.target.files![0],
                }));
              }}
              onDelete={() => {
                setFormValues((prev) => ({
                  ...prev,
                  mobile_app_bundle: undefined,
                }));
              }}
            />
          </Flex>
        )}
      </Flex>

      <Flex justify="between" gap="24px">
        <Flex w100 column gap="8px">
          <Flex gap="8px" align="center">
            <LabelRegular>Assign to Product</LabelRegular>
            <RequiredField />
          </Flex>
          <Dropdown
            options={productsOptions}
            onChange={(opt) =>
              setFormValues((prev) => ({
                ...prev,
                product: parseInt(`${opt?.value}`),
              }))
            }
            value={productsOptions?.find(
              (opt) => opt.value === formValues.product
            )}
            variant="border"
            dataTestId="asset-product"
            disabled={isFormDisabled}
          />
        </Flex>
        <Flex w100 column gap="8px">
          <Flex gap="8px" align="center">
            <LabelRegular>Environment</LabelRegular>
            <RequiredField />
          </Flex>
          <Dropdown
            options={envOptions}
            onChange={(opt) =>
              setFormValues((prev) => ({
                ...prev,
                environment: `${opt?.value}`,
              }))
            }
            value={envOptions?.find(
              (opt) => opt.value === formValues.environment
            )}
            variant="border"
            dataTestId="asset-environment"
            disabled={isFormDisabled}
          />
        </Flex>
      </Flex>

      {formValues.type === "cloud" && !isOnboarding && !isFormDisabled && (
        <AssetCloudForm
          formValues={formValues}
          setFormValues={setFormValues}
          disabled={!formValues.product || !formValues.environment}
        />
      )}

      {isSuperuser && !hideAsmButton && !customer && (
        <Flex justify="start" gap="16px" align="center">
          <Flex gap="8px" align="center">
            <LabelRegular>Enable ASM for this asset</LabelRegular>
          </Flex>
          <Switch
            checked={!!formValues.is_asm_enabled}
            onChange={(checked) =>
              setFormValues((prev) => ({ ...prev, is_asm_enabled: checked }))
            }
            dataTestId="asset-asm"
            disabled={isFormDisabled}
          />
        </Flex>
      )}
      <Flex align="end" column gap="4px">
        {formValues?.type === "cloud" && !formValues.name && (
          <LabelMini>You must test connection before adding</LabelMini>
        )}
        <MainButton
          label={editedAsset ? "update" : "Add Asset"}
          onClick={applyForm}
          inProgress={isCreating || isUpdating}
          disabled={
            isCreating ||
            (!formValues.name && !newAssetName) ||
            !formValues.product ||
            !formValues.environment ||
            isFormDisabled ||
            !isValidCloudIntegration(formValues)
          }
          dataTestId="add-asset-btn"
          size="medium"
        />
      </Flex>
    </Flex>
  );
};
