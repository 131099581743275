import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { FilterLine } from "../../../../components/composed/filterLine/FilterLine";
import { LinkButton } from "../../../../components/elements/button/link/LinkButton";
import { useApiFindingsPaging } from "../../../../hooks/queries/findingContext";
import { useApiProducts } from "../../../../hooks/queries/productsContext";
import { useApiProjects } from "../../../../hooks/queries/projectsContext";
import { SEVERITIES } from "../../../../shared/consts";
import {
  convertToUiFilters,
  excludedFilters,
  filtersWithTextLabels,
  listFilters,
  stringsListToArray,
} from "../FindingsFilterBL";

import FilterBadge from "./FilterBadge";
import { Icon } from "../../../../components/elements/icon/Icon";
import { RoundButtonStyle } from "../../../../components/elements/scrollToTop/ScrollToTopButtonStyle";
import { Mixpanel } from "../../../../shared/mixpanel";
import { Filters, findingsDefaultFilters } from "../../Findings";
import { useApiMe } from "../../../../hooks/queries/meContext";
import { useApiScanners } from "../../../../hooks/queries/scannersContext";
import { priorityOptions } from "../../../assets/AssetUtils";
import { findingTypesOptions } from "../../../../types/Finding";

export type FilterContext = {
  filterKey: string;
  value?: string;
};

const filterLineExcludedFilters = [...excludedFilters, "timeframe"];

export const FindingsFilterLine = ({
  filters,
  setFiltersUrl,
  setShowFiltersPane,
  showFiltersLine,
  hideFiltersLine,
  setHideFiltersLine,
  onClearAll,
}: {
  filters: { [key: string]: any };
  setFiltersUrl: (filters: Filters) => void;
  setShowFiltersPane: React.Dispatch<React.SetStateAction<boolean>>;
  showFiltersLine: boolean;
  hideFiltersLine: boolean;
  setHideFiltersLine: React.Dispatch<React.SetStateAction<boolean>>;
  onClearAll?: () => void;
}) => {
  const { data: me } = useApiMe();
  const { data: scanners } = useApiScanners(me?.customer?.id);
  const { data: products } = useApiProducts();
  const { data: projects } = useApiProjects();
  const { data: findings } = useApiFindingsPaging(
    filters || findingsDefaultFilters
  );

  const theme = useContext(ThemeContext);
  const filtersConverted = convertToUiFilters(filters);
  const filtersKeys = Object.keys(filtersConverted).filter(
    (key) => !filterLineExcludedFilters.includes(key)
  );

  const removeEmptyStrings = (obj: { [key: string]: any }) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === "") delete obj[key];
    });
    return obj;
  };

  const removeFilter = ({ filterKey, value }: FilterContext): void => {
    const newFilters = { ...filters };
    if (value)
      newFilters[filterKey] = stringsListToArray(filters[filterKey])
        .filter((itemId) => itemId !== value)
        .join(",");
    else delete newFilters[filterKey];
    setFiltersUrl(removeEmptyStrings(newFilters));
  };

  const getLabel = (value: string | string[], filterKey: string): string => {
    if (!filtersWithTextLabels.includes(filterKey)) return `${value}`;
    const labelsDict: { [index: string]: string } = {
      products:
        products?.find((p) => p.id === parseInt(`${value}`))?.name || "",
      project: projects?.find((p) => p.id === parseInt(`${value}`))?.name || "",
      overall_risk: SEVERITIES[parseInt(`${value}`)],
      affected_assets: `asset id: ${value}`,
      affected_assets_priority:
        priorityOptions.find((p) => p.value === parseInt(`${value}`))?.label ||
        "",
      scanner_name: scanners?.find((s) => s.name === value)?.display_name || "",
      finding_type:
        findingTypesOptions.find((p) => p.value === value)?.label || "",
    };
    return labelsDict[filterKey];
  };

  const getBadgesList = (): JSX.Element[] => {
    return filtersKeys
      .filter((key) => !filterLineExcludedFilters.includes(key))
      .map((filterKey) => {
        if (listFilters.includes(filterKey))
          return stringsListToArray(filters[filterKey]).map((value) => (
            <FilterBadge
              key={`${filterKey}-${value}`}
              filterKey={filterKey}
              label={getLabel(value, filterKey)}
              value={value}
              removeFilter={removeFilter}
            />
          ));
        return (
          <FilterBadge
            key={`${filterKey}`}
            filterKey={filterKey}
            label={filtersConverted[filterKey]}
            removeFilter={removeFilter}
          />
        );
      })
      .flat();
  };

  const badges = getBadgesList();

  const showFilterPanel = () => {
    setShowFiltersPane(true);
    Mixpanel.track("Findings - Show filter panel");
  };

  const handleRemoveAllFilters = () => {
    setFiltersUrl(findingsDefaultFilters);
    onClearAll && onClearAll();
  };

  return (
    <>
      <div>
        <FilterLine
          filtersBadges={<>{badges}</>}
          hasBadges={filtersKeys.length > 0}
          onShowFiltersPanel={showFilterPanel}
          onRemoveAllFilters={handleRemoveAllFilters}
          showSaveAsFilterButton={false}
        />
      </div>
      <RoundButtonStyle
        onClick={() => setHideFiltersLine(false)}
        isVisible={
          !!findings?.pages[0].count && showFiltersLine && hideFiltersLine
        }
      >
        <Icon name="filter" color={theme.white100} />
      </RoundButtonStyle>

      {/* Floating State */}
      {!!findings?.pages[0].count && showFiltersLine && !hideFiltersLine && (
        <div
          style={{
            position: "fixed",
            bottom: !hideFiltersLine ? "100px" : "-400px",
            right: "10%",
            width: "70%",
            transition: "0.2s",
            boxShadow: `0px 8px 40px ${theme.lightCardShadow}`,
            zIndex: "300",
          }}
        >
          <FilterLine
            filtersBadges={<>{badges}</>}
            hasBadges={filtersKeys.length > 0}
            onShowFiltersPanel={() => setShowFiltersPane(true)}
            onRemoveAllFilters={handleRemoveAllFilters}
            showSaveAsFilterButton={false}
          />
          <span
            style={{
              position: "absolute",
              top: "25px",
              right: "200px",
            }}
          >
            <LinkButton
              label="Hide"
              iconName="chevronRight"
              onClick={() => setHideFiltersLine(true)}
            />
          </span>
        </div>
      )}
    </>
  );
};
