import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { bitAfterNow, getQueryParams } from "../../shared/helper";
import { JiraUser } from "../../types/Finding";
import { get, getItems, getPage, updateUrl } from "./sdk";
import { DEFAULT_STALE_TIME, invalidateApiQueries } from "./utils";
import {
  CustomerJiraDetails,
  JiraField,
  JiraProjectsPage,
} from "../../types/JiraIntegration";

const jiraUserKey = "jira_user";
const jiraIntegrationKey = "jira-integration";

export interface JiraUserContext {
  findingId: number;
  jiraUser: JiraUser;
  onSuccessCallback?: (jiraUser: JiraUser) => void;
  onErrorCallback?: (error: Error) => void;
}

export interface CustomerJiraDetailsContext {
  jiraDetails: CustomerJiraDetails;
  onSuccessCallback?: (jiraDetails: CustomerJiraDetails) => void;
  onErrorCallback?: (error: Error) => void;
}

export const useApiFindingsAssignedUsers = (
  searchText?: string,
  enabled: boolean = true
) =>
  //  /api/v1/jira-integration/jira_user_search/?search_term=${search_term}&admin-mode=true/false
  useQuery<JiraUser[], Error>({
    queryKey: [jiraIntegrationKey, "jira_user_search", searchText],
    keepPreviousData: true,
    placeholderData: [],
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    queryFn: async (): Promise<JiraUser[]> =>
      getItems(`${jiraIntegrationKey}/jira_user_search`, {
        search_term: searchText,
      }),
    enabled: enabled,
  });

export const useApiUpdateFindingAssignee = () => {
  const queryClient = useQueryClient();
  return useMutation<JiraUser, Error, JiraUserContext>({
    mutationKey: [jiraUserKey],
    mutationFn: async ({
      findingId,
      jiraUser,
    }: JiraUserContext): Promise<JiraUser> =>
      await updateUrl(`findings/${findingId}/assign/`, { assignee: jiraUser }),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries(["findings"], queryClient),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

// GET /api/v1/jira-integration/jira_project_search/?query=${query}
export const useApiJiraProjectsPaging = (
  query?: string,
  enabled: boolean = true
) =>
  useInfiniteQuery<JiraProjectsPage, Error>({
    queryKey: [jiraIntegrationKey, "jira_project_search", query],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    queryFn: async ({ pageParam = 1 }): Promise<JiraProjectsPage> =>
      getPage(`${jiraIntegrationKey}/jira_project_search`, {
        query,
        page: pageParam,
      }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

// GET /api/v1/jira-integration/check_jira_integration
export const useApiJiraCheckIntegration = () =>
  useQuery<boolean, Error>({
    queryKey: [jiraIntegrationKey, "check_jira_integration"],
    keepPreviousData: true,
    placeholderData: false,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    queryFn: async (): Promise<boolean> =>
      get(`${jiraIntegrationKey}/check_jira_integration`)
        .then((response) => response.ok)
        .catch(() => false),
  });

// PUT /api/v1/jira-integration/update_jira_integration
export const useApiJiraUpdateIntegration = () => {
  const queryClient = useQueryClient();
  return useMutation<CustomerJiraDetails, Error, CustomerJiraDetailsContext>({
    mutationKey: [jiraIntegrationKey, "update_jira_integration"],
    mutationFn: async ({
      jiraDetails,
    }: CustomerJiraDetailsContext): Promise<CustomerJiraDetails> =>
      await updateUrl(`${jiraIntegrationKey}/update_jira_integration`, {
        jira_details: jiraDetails,
      }),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([jiraIntegrationKey], queryClient),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiJiraProjectLabels = (projectKey: string) =>
  //GET  /api/v1/jira-integration/jira_project_labels
  useQuery<string[], Error>({
    queryKey: [jiraIntegrationKey, "jira_project_labels", projectKey],
    keepPreviousData: true,
    placeholderData: [],
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: !!projectKey,
    queryFn: async (): Promise<string[]> =>
      getItems(`${jiraIntegrationKey}/jira_project_labels`, {
        project_key: projectKey,
      }),
  });

export const useApiJiraProjectWorkflow = (
  projectKey: string | undefined,
  issueType: string | undefined
) =>
  //GET  /api/v1/jira-integration/jira_project_workflow?project=${projectKey}
  useQuery<JiraField[], Error>({
    queryKey: [
      jiraIntegrationKey,
      "jira_project_workflow",
      projectKey,
      issueType,
    ],
    keepPreviousData: true,
    placeholderData: [],
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: !!projectKey && !!issueType,
    queryFn: async (): Promise<JiraField[]> =>
      getItems(`${jiraIntegrationKey}/jira_project_workflow`, {
        project: projectKey,
        issue_type: issueType,
      }),
  });
