import { useEffect, useState } from "react";
import { Box } from "../../components/elements/box/Box";
import { Table } from "../../components/elements/table/Table";
import { Flex } from "../../components/layouts/flex/Flex";
import { useIsSuperuser } from "../../hooks/useIsSuperuser";
import {
  BodyBold,
  BodyRegular,
  HeaderSecondary,
  HeaderSubBold,
  LabelMedium,
} from "../../components/elements/typography/Typography";
import { useApiCustomers } from "../../hooks/queries/customersContext";
import { Dropdown } from "../../components/elements/dropdowns/Dropdown";
import {
  useApiFindingsCounts,
  useApiFindingsPaging,
} from "../../hooks/queries/findingContext";
import { Link, useSearchParams } from "react-router-dom";
import { IconButton } from "../../components/elements/button/icon/IconButton";
import { useApiMe } from "../../hooks/queries/meContext";
import { useApiProjects } from "../../hooks/queries/projectsContext";
import RiskLegendItem from "../../components/elements/legend/RiskLegendItem";
import { SCREEN_MOBILE_WIDTH, SEVERITIES } from "../../shared/consts";
import { Finding } from "../../types/Finding";
import { Switch } from "../../components/elements/switch/Switch";
import { useScreenWidth } from "../../hooks/utilsHooks";
import { FindingsFilterLine } from "./filters/filterLine/FindingsFilterLine";
import { FindingsFiltersPane } from "./filters/FindingsFiltersPane";
import { Filters } from "./Findings";
import { base64ToObject, objectToBase64 } from "../../shared/helper";
import { FindingStatus } from "./findingStatus/FindingStatus";
import { NON_RELEVANT_LICENSE, PriorityTier } from "../../types/Customer";
import { customersPriorityTierOptions } from "../../shared/formUtils";
import { InputText } from "../../components/elements/input/textInput/InputText";
import { LinkButton } from "../../components/elements/button/link/LinkButton";

export const FindingsAdminPanel = () => {
  const isSuperuser = useIsSuperuser();
  const [searchParams, setSearchParams] = useSearchParams();
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;

  const { data: me } = useApiMe();
  const [showFiltersPane, setShowFiltersPane] = useState(false);

  // Decode filters from url
  const urlFiltersBase64 = searchParams.get("filters");
  const urlFilters: Filters =
    urlFiltersBase64 && base64ToObject(urlFiltersBase64);

  const [filters, setFilters] = useState<Filters>(
    !!urlFilters ? urlFilters : {}
  );

  const setFiltersUrl = (filters: Filters) => {
    searchParams.set("filters", objectToBase64(filters));
    setSearchParams(searchParams);
  };

  const { data: customers, isFetching: isFetchingCustomers } = useApiCustomers(
    {},
    isSuperuser
  );

  const relevantCustomers = customers?.filter(
    (c) => !NON_RELEVANT_LICENSE.includes(c.license?.type || "")
  );

  const [selectedCustomer, setSelectedCustomer] = useState<number | null>(
    me?.customer?.id || null
  );

  const [selectedTier, setTier] = useState<PriorityTier | "all">("all");
  const [showWayneEnterprises, setShowWayneEnterprises] =
    useState<boolean>(false);

  const { data: projects } = useApiProjects({
    "admin-mode": isSuperuser,
    ...(selectedCustomer !== null && { customer: selectedCustomer }),
  });

  const findingsQueryParams = {
    include_pending: true,
    no_prefetch_assets: true,
    "admin-mode": isSuperuser,
    ...(selectedCustomer !== null && { customer: selectedCustomer }),
    ...filters,
  };

  const {
    data: findings,
    isFetching: isFetchingFindings,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch: refetchFindings,
  } = useApiFindingsPaging(findingsQueryParams, isSuperuser);

  const pagedFindings =
    findings?.pages
      ?.map((p) => p.results || [])
      ?.flat()
      .filter(
        (f) =>
          (showWayneEnterprises || f.customer !== 1) &&
          relevantCustomers?.find(
            (c) =>
              c.id === f.customer &&
              (selectedTier === "all" || c.priority_tier === selectedTier)
          )
      ) || [];

  const { data: findingsCounts } = useApiFindingsCounts(findingsQueryParams);

  useEffect(() => {
    refetchFindings();
  }, [refetchFindings]);

  useEffect(() => {
    if (searchParams.get("customer")) {
      setSelectedCustomer(
        searchParams.get("customer") === "all"
          ? null
          : Number(searchParams.get("customer"))
      );
    }
    if (searchParams.get("showWayne")) {
      setShowWayneEnterprises(searchParams.get("showWayne") === "true");
    }

    if (searchParams.get("tier")) {
      setTier((searchParams.get("tier") as PriorityTier) || "all");
    }

    // Decode filters from url
    const urlFiltersBase64 =
      searchParams.get("filters") ||
      localStorage.getItem("findingsAdminPanelFilters");
    if (!urlFiltersBase64) return;
    const urlFilters: Filters = base64ToObject(urlFiltersBase64);
    if (urlFilters) {
      setFilters(urlFilters);
      localStorage.setItem("findingsAdminPanelFilters", urlFiltersBase64);
    }
  }, [searchParams]);

  if (!isSuperuser) {
    return null;
  }

  function removeFiltersUrl(filter_keys: string[]) {
    const newFilters = { ...filters };
    for (const filter_key of filter_keys) {
      delete newFilters[filter_key];
    }
    setFiltersUrl(newFilters);
  }

  return (
    <Flex w100 column gap="24px">
      <Flex align="center" gap="24px">
        <HeaderSecondary>Admin Findings Panel</HeaderSecondary>
        <BodyBold>
          Showing {pagedFindings?.length} findings out of{" "}
          {findingsCounts?.total} total
        </BodyBold>
      </Flex>
      <FindingsFilterLine
        filters={filters}
        setFiltersUrl={setFiltersUrl}
        setShowFiltersPane={setShowFiltersPane}
        showFiltersLine={false}
        hideFiltersLine={false}
        setHideFiltersLine={() => {}}
      />
      {showFiltersPane && (
        <FindingsFiltersPane
          onClose={() => setShowFiltersPane(false)}
          filters={filters}
          setFiltersUrl={setFiltersUrl}
          searchWord={""}
        />
      )}
      <Box className="d-flex flex-column gap-16">
        <Flex align="center" gap="16px" justify="between" w100>
          <HeaderSubBold>Predefined Filters</HeaderSubBold>
          <LinkButton
            iconName="cancel"
            label="Clear All"
            onClick={() => setFiltersUrl({})}
          />
        </Flex>
        <Flex gap="24px">
          <Flex column>
            <LabelMedium>Pending PT Findings</LabelMedium>
            <Switch
              checked={filters.is_pending && !filters.is_automated}
              onChange={(checked) => {
                checked
                  ? setFiltersUrl({
                      ...filters,
                      is_pending: true,
                      is_automated: false,
                    })
                  : removeFiltersUrl(["is_pending", "is_automated"]);
              }}
            />
          </Flex>
          <Flex column>
            <LabelMedium>Pending ASM Findings</LabelMedium>
            <Switch
              checked={filters.is_pending && filters.is_automated}
              onChange={(checked) => {
                checked
                  ? setFiltersUrl({
                      ...filters,
                      is_pending: true,
                      is_automated: true,
                    })
                  : removeFiltersUrl(["is_pending", "is_automated"]);
              }}
            />
          </Flex>
          <Flex column>
            <LabelMedium>Waiting for retest</LabelMedium>
            <Switch
              checked={filters.status === "re_test"}
              onChange={(checked) => {
                checked
                  ? setFiltersUrl({
                      ...filters,
                      status: "re_test",
                    })
                  : removeFiltersUrl(["status"]);
              }}
            />
          </Flex>
          <Flex column>
            <LabelMedium>All Open findings</LabelMedium>

            <Switch
              checked={
                filters.status?.includes("presented") &&
                filters.status?.includes("in_progress") &&
                filters.status?.includes("re_test")
              }
              onChange={(checked) => {
                checked
                  ? setFiltersUrl({
                      ...filters,
                      status: "presented,in_progress,re_test",
                    })
                  : removeFiltersUrl(["status"]);
              }}
            />
          </Flex>
          <Flex column>
            <LabelMedium>Presented PT Findings</LabelMedium>

            <Switch
              checked={
                filters.status?.includes("presented") &&
                filters.is_automated === false
              }
              onChange={(checked) => {
                checked
                  ? setFiltersUrl({
                      ...filters,
                      status: "presented",
                      is_automated: false,
                    })
                  : removeFiltersUrl(["status", "is_automated"]);
              }}
            />
          </Flex>
          <Flex column>
            <LabelMedium>Waiting for OP response</LabelMedium>
            <Switch
              checked={filters.waiting_for_op_response}
              onChange={(checked) => {
                checked
                  ? setFiltersUrl({
                      ...filters,
                      waiting_for_op_response: true,
                    })
                  : removeFiltersUrl(["waiting_for_op_response"]);
              }}
            />
          </Flex>
          <Flex column>
            <LabelMedium>Assigned to me</LabelMedium>
            <Switch
              checked={filters.op_jira_assignee?.email === me?.email}
              onChange={(checked) => {
                checked
                  ? setFiltersUrl({
                      ...filters,
                      op_jira_assignee: me?.email,
                    })
                  : removeFiltersUrl(["op_jira_assignee"]);
              }}
            />
          </Flex>
          <Flex column>
            <LabelMedium>Retest Not Found</LabelMedium>
            <Switch
              checked={Object.keys(filters).includes("retest_not_found")}
              onChange={(checked) => {
                checked
                  ? setFiltersUrl({
                      ...filters,
                      retest_not_found: true,
                    })
                  : removeFiltersUrl(["retest_not_found"]);
              }}
            />
          </Flex>
        </Flex>
      </Box>
      <Box className="w-100">
        <Flex column gap="24px">
          <Flex
            column={isMobile}
            align={isMobile ? "start" : "center"}
            gap="24px"
          >
            <div style={{ width: "265px", marginLeft: "24px" }}>
              <InputText
                dataTestId="findings-search-bar"
                placeholder="Search"
                iconName="search"
                width={"265px"}
                isClearable
                onChange={(e) =>
                  setFiltersUrl({
                    ...filters,
                    title: e.target.value,
                  })
                }
                onClear={() => {
                  let newFilters = { ...filters };
                  delete newFilters.title;
                  setFiltersUrl(newFilters);
                }}
              />
            </div>
            <Flex
              column
              style={{
                width: "300px",
              }}
            >
              <LabelMedium>Filter by customer</LabelMedium>
              <Dropdown
                disabled={isFetchingCustomers}
                variant="border"
                searchable
                onChange={(option) => {
                  searchParams.set("customer", option?.value.toString() || "");
                  setSearchParams(searchParams);
                }}
                value={relevantCustomers
                  ?.filter((c) => c.id === selectedCustomer)
                  ?.map((c) => ({ label: c.name, value: c.id }))
                  ?.at(0)}
                placeholder="Select Customer"
                options={[
                  {
                    label: "All Customers",
                    value: "all",
                  },
                  ...(relevantCustomers?.map((c) => ({
                    label: c.name,
                    value: c.id,
                  })) || []),
                ]}
              />
            </Flex>
            <Flex
              column
              style={{
                width: "300px",
              }}
            >
              <LabelMedium>Filter by tier</LabelMedium>
              <Dropdown
                disabled={isFetchingCustomers}
                variant="border"
                onChange={(option) => {
                  searchParams.set("tier", option?.value.toString() || "all");
                  setSearchParams(searchParams);
                }}
                value={
                  customersPriorityTierOptions.filter(
                    (t) => t.value === selectedTier
                  )[0]
                }
                placeholder="Select Customer"
                options={customersPriorityTierOptions}
              />
            </Flex>
            <Flex column>
              <LabelMedium>Show Wayne Enterprises</LabelMedium>
              <Switch
                checked={showWayneEnterprises}
                onChange={(checked) => {
                  searchParams.set("showWayne", checked.toString());
                  setSearchParams(searchParams);
                }}
              />
            </Flex>
          </Flex>
          <Table
            columns={[
              {
                key: "id",
                title: "ID",
                maxWidth: "50px",
                sortable: true,
              },

              {
                key: "title",
                title: "Title",
                maxWidth: "600px",
                cell: (row) => (
                  <BodyRegular className="text-truncate">
                    {row.title}{" "}
                  </BodyRegular>
                ),
              },

              {
                key: "customer",
                title: "Customer",
                maxWidth: "200px",
                isHidden: isMobile,
                cell: (row) =>
                  customers?.find((c) => c.id === row.customer)?.name ||
                  row.customer,
              },
              {
                key: "project",
                title: "Source",
                maxWidth: "250px",
                isHidden: isMobile,
                cell: (row) =>
                  selectedCustomer && projects
                    ? projects.find((p) => p.id === row.project)?.name ||
                      row.project
                    : "N/A",
              },
              {
                key: "op_jira_assignee",
                title: "Researcher",
                maxWidth: "150px",
                isHidden: isMobile,
                cell: (row) => (row as Finding)?.op_jira_assignee?.display_name,
              },
              {
                key: "overall_risk",
                title: "Severity",
                sortable: true,
                maxWidth: "100px",
                cell: (row) => (
                  <RiskLegendItem
                    label={SEVERITIES[row.overall_risk]?.toLowerCase()}
                    size={14}
                  />
                ),
              },
              {
                key: "created_at",
                title: "Date Created",
                sortable: true,
                maxWidth: "150px",
                isHidden: isMobile,
                cell: (row) => new Date(row.created_at).toLocaleDateString(),
              },
              {
                title: "Status",
                key: "status",
                maxWidth: "180px",
                sortable: true,
                cell: (row) => <FindingStatus finding={row} />,
              },
              {
                title: "Status Updated At",
                key: "status_updated_at",
                sortable: true,
                maxWidth: "150px",
                cell: (row) =>
                  new Date(row.status_updated_at).toLocaleDateString(),
              },
              {
                key: "go_to_finding",
                title: "Details",
                maxWidth: "100px",
                cell: (row) => (
                  <Flex align="center" gap="4px">
                    <Link
                      to={`/finding-details/${row.id}?admin-mode=true&mode=edit`}
                    >
                      <IconButton iconName="edit" size="small" />
                    </Link>
                    <Link to={`/finding-details/${row.id}?admin-mode=true`}>
                      <IconButton
                        iconName="chevronRight"
                        size="small"
                        onClick={() => {}}
                      />
                    </Link>
                  </Flex>
                ),
              },
            ]}
            rows={pagedFindings}
            isLoading={isFetchingFindings}
            isFetchingNextPage={isFetchingNextPage}
            onScrollPagination={fetchNextPage}
            hasNextPage={hasNextPage}
            hasScrollPagination={true}
            onSort={(column, order) =>
              setFilters({
                ...filters,
                ordering: order === "desc" ? `-${column.key}` : column.key,
              })
            }
          />
        </Flex>
      </Box>
    </Flex>
  );
};
