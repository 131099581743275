import { InputText } from "../../../components/elements/input/textInput/InputText";
import { BodyBold } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { RecaptchaButton } from "./RecaptchaButton";
import { useState } from "react";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { MfaMessage } from "./MfaMessage";
import { useSearchParams } from "react-router-dom";
import { useApiCheckMfa } from "../../../hooks/queries/usersContext";

type loginForm = {
  email: string;
  password: string;
};

type Props = { onClickBack: () => void };

export default function LoginPlain(props: Props) {
  const { onClickBack } = props;
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  useApiCheckMfa({ email, token }, !!token && !!email);

  const [showMfaMessage, setShowMfaMessage] = useState(false);

  const [formValues, setFormValues] = useState<loginForm>({
    email: email || "",
    password: "",
  });

  return showMfaMessage ? (
    <MfaMessage
      email={formValues.email}
      onClose={() => setShowMfaMessage(false)}
    />
  ) : (
    <Flex column gap="24px">
      <Flex column gap="8px">
        <BodyBold>Email</BodyBold>
        <InputText
          name="email"
          type="text"
          width="100%"
          value={formValues.email}
          onChange={(e) => {
            setFormValues((prev) => ({
              ...prev,
              email: e.target.value,
            }));
          }}
        />
      </Flex>

      <Flex column gap="8px">
        <BodyBold>Password</BodyBold>
        <InputText
          isPassword
          name="password"
          width="100%"
          value={formValues.password}
          onChange={(e) => {
            setFormValues((prev) => ({
              ...prev,
              password: e.target.value,
            }));
          }}
        />
      </Flex>

      <RecaptchaButton
        action="/api/v1/auth/login/"
        formValues={formValues}
        setShowMfaMessage={setShowMfaMessage}
      />
      <SecondaryButton label="Back to SSO Login" onClick={onClickBack} />
    </Flex>
  );
}
