import { IconProps } from "./WaspIcon";

export function FindingsMenuIcon(props: IconProps) {
  const size = props.size || 32;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_12853_87496"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="-1"
        width="24"
        height="25"
      >
        <path d="M0 -0.00332451H24V23.9967H0V-0.00332451Z" fill="white" />
      </mask>
      <g mask="url(#mask0_12853_87496)">
        <path
          d="M3.75 22.3075H20.25C21.8033 22.3075 23.0625 21.0483 23.0625 19.495C23.0625 18.9948 22.932 18.5251 22.703 18.1181L14.453 3.11811C13.971 2.26128 13.0531 1.68247 12 1.68247C10.9469 1.68247 10.029 2.26128 9.54698 3.11811L1.29698 18.1181C1.06809 18.5251 0.9375 18.9948 0.9375 19.495C0.9375 21.0483 2.1967 22.3075 3.75 22.3075Z"
          stroke={props.color || "currentColor"}
          strokeWidth="1.875"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 8.24496V13.87"
          stroke={props.color || "currentColor"}
          strokeWidth="1.875"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9375 17.62C12.9375 18.1378 12.5178 18.5575 12 18.5575C11.4822 18.5575 11.0625 18.1378 11.0625 17.62C11.0625 17.1023 11.4822 16.6825 12 16.6825C12.5178 16.6825 12.9375 17.1023 12.9375 17.62Z"
          fill={props.color || "currentColor"}
        />
      </g>
    </svg>
  );
}
