import { useContext, useState } from "react";
import { LinkButton } from "../../components/elements/button/link/LinkButton";
import { HeaderMain } from "../../components/elements/typography/Typography";
import { useApiProducts } from "../../hooks/queries/productsContext";
import { ProductsFilter } from "./productsFilter/ProductsFilter";
import { useScreenWidth } from "../../hooks/utilsHooks";
import {
  SCREEN_BIG_DESKTOP_WIDTH,
  SCREEN_MEDIUM_LAPTOP_WIDTH,
  SCREEN_MOBILE_WIDTH,
  SCREEN_VERY_SMALL_LAPTOP_WIDTH,
} from "../../shared/consts";
import { BottomBlockContainer } from "./bottomBlock/BottomBlockContainer";
import { Finding } from "../../types/Finding";
import { FindingPane } from "../findings/findingPane/FindingPane";
import { Project } from "../../types/Project";
import { ProjectPane } from "../projects/projectPane/ProjectPane";
import { useSearchParams } from "react-router-dom";
import { FindingsChartsContainer } from "../findings/findingsCharts/FindingsChartsContainer";
import { MainButton } from "../../components/elements/button/main/MainButton";
import { IntelFeed } from "./intelligenceFeed/IntelFeed";
import { Flex } from "../../components/layouts/flex/Flex";
import { Mixpanel } from "../../shared/mixpanel";
import { Tours } from "../../tours/Tours";
import { dashboardSteps } from "../../tours/DashboardTourSteps";
import { ThemeContext } from "styled-components";
import { RequestsModal } from "../projects/RequestsModal";
import { ExpressPtModal } from "../projects/projectScheduler/ExpressPtModal";
import Onboarding from "../welcome/onboarding/Onboarding";
import { AlertsBanners } from "../alertsBanners/AlertsBanners";
import { useTrackPage } from "../../hooks/trackingHooks";
import { useApiMe } from "../../hooks/queries/meContext";
import { MobileDashboard } from "./MobileDashboard";
import { ASMUpdates } from "../asmUpdates/ASMUpdates";
import { Box } from "../../components/elements/box/Box";
import { ReportsGenV2 } from "../reports/ReportsGenV2";
import { useApiSecurityScoreExtended } from "../insights/findingsTrendsGraph/findingsTrendsContext";
import { SecurityScore } from "./securityScore/SecurityScore";
import { DistributionOfFindings } from "./findingsOverview/DistributionOfFindings";
import { DistributionOfAssets } from "./assetsOverview/DistributionOfAssets";
import { TextButton } from "../../components/elements/button/text/TextButton";
import { RiskScoreCard } from "./topBlocks/riskScore/RiskScoreCard";
import { DashboardOverviewCard } from "./topBlocks/dashboardOverview/DashboardOverviewCard";
import { useIsSuperuser } from "../../hooks/useIsSuperuser";
import { AssetsOverviewV2 } from "./assetsOverview/AssetsOverviewV2";
import { FindingsOverviewV2 } from "./findingsOverview/FindingsOverviewV2";

export type Tab = "products" | "projects" | "activeIssues";

const Dashboard = () => {
  const theme = useContext(ThemeContext);

  const screenWidth = useScreenWidth();
  const isLargeScreen = screenWidth >= SCREEN_BIG_DESKTOP_WIDTH - 50;
  const isSmallScreen = screenWidth < SCREEN_BIG_DESKTOP_WIDTH - 50;
  const isLaptop = screenWidth <= SCREEN_MEDIUM_LAPTOP_WIDTH;
  const isVerySmallLaptop = screenWidth <= SCREEN_VERY_SMALL_LAPTOP_WIDTH;
  const isMobile = screenWidth <= SCREEN_MOBILE_WIDTH;
  console.log({
    isLargeScreen,
    isSmallScreen,
    isLaptop,
    isVerySmallLaptop,
    isMobile,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const productId = searchParams.get("productId");
  const projectId = searchParams.get("projectId");
  const showOnboardingModal = searchParams.get("onboarding");

  const isSuperuser = useIsSuperuser();
  const { data: me } = useApiMe();
  const { data: products } = useApiProducts();
  const { data: securityScoreExtended } = useApiSecurityScoreExtended();

  useTrackPage("Dashboard", undefined, me);

  const [selectedProduct, setSelectedProduct] = useState<number | "all">(
    parseInt(productId || "0") || "all"
  );

  const [project, setProject] = useState<Project | undefined>(undefined);
  const [showProjectPane, setShowProjectPane] = useState<boolean>(
    !!projectId || false
  );

  const [showFindingPane, setShowFindingPane] = useState<boolean>(false);
  const [selectedFinding, setSelectedFinding] = useState<Finding | null>(null);

  const [showReportsGen, setShowReportsGen] = useState<boolean>(false);
  const [showRequestsModal, setShowRequestsModal] = useState(false);
  const [showExpressPtModal, setShowExpressPtModal] = useState(false);

  const [selectedTab, setSelectedTab] = useState<Tab>("products");

  const [isLegacyView, setIsLegacyView] = useState(true);

  const switchToExpressTest = () => {
    Mixpanel.track("Express Test");
    setShowRequestsModal(false);
    setShowExpressPtModal(true);
  };

  const onSelectedFinding = (finding: Finding) => {
    setSelectedFinding(finding);
    setShowFindingPane(true);
  };
  const onCloseFindingPane = () => {
    setSelectedFinding(null);
    setShowFindingPane(false);
  };

  const onOpenProjectPane = (project: Project) => {
    setSearchParams({ ...searchParams, projectId: `${project.id}` });
    setShowProjectPane(true);
    setProject(project);
  };

  const onCloseProjectPane = () => {
    setShowProjectPane(false);
    setProject(undefined);
  };

  const onOpenReportsGen = () => {
    setShowReportsGen(true);
    Mixpanel.track("Open Reports Generator");
  };

  const [numberOfShowingBanners, setNumberOfShowingBanners] = useState(0);

  const getAmountOfBanners = () => {
    const alertBanners = document.querySelectorAll(
      '[data-name="alert-banner"]'
    );
    console.log("numberOfShowingBanners", alertBanners.length);
    setNumberOfShowingBanners(alertBanners.length);
  };

  if (me?.is_superuser) console.log(securityScoreExtended);
  if (isMobile) return <MobileDashboard />;
  return (
    <Flex column w100 padding="0px 0px 16px 0px">
      <Tours steps={dashboardSteps(theme, setSelectedTab)} />
      <Flex gap="24px" justify="between" h100 w100>
        <Flex
          column
          h100
          gap="24px"
          style={{
            width: isLaptop ? "60%" : "75%",
            marginTop: numberOfShowingBanners > 0 ? "0px" : "-24px",
          }}
        >
          <Flex column gap="8px">
            <AlertsBanners onRender={getAmountOfBanners} />
          </Flex>
          <Flex column={isVerySmallLaptop} gap="8px" justify="between" w100>
            <Flex align="center" gap="24px">
              <Flex column style={{ width: isLaptop ? "240px" : "" }}>
                <HeaderMain data-testid="dashboard-title">Dashboard</HeaderMain>
                {isLaptop && isSuperuser && (
                  <TextButton
                    label={
                      isLegacyView
                        ? "Show New Dashboard"
                        : "Show Legacy Dashboard"
                    }
                    onClick={() => setIsLegacyView((prev) => !prev)}
                  />
                )}
              </Flex>

              <Flex align="center">
                <ProductsFilter
                  selectedProduct={selectedProduct}
                  products={products || []}
                  onChange={(selectedProduct) => {
                    setSelectedProduct(selectedProduct);
                    setSearchParams({
                      productId: selectedProduct.toString(),
                    });
                    Mixpanel.track("Change Product", {
                      product: selectedProduct,
                    });
                  }}
                />
              </Flex>
              {!isLaptop && isSuperuser && (
                <TextButton
                  label={
                    isLegacyView
                      ? "Show New Dashboard"
                      : "Show Legacy Dashboard"
                  }
                  onClick={() => setIsLegacyView((prev) => !prev)}
                />
              )}
            </Flex>

            <Flex align="center">
              <Flex align="center" data-tut="export-reports">
                <LinkButton
                  dataTestId="open-reports-generator"
                  label="Export Reports"
                  iconName="export"
                  size="medium"
                  onClick={onOpenReportsGen}
                />
              </Flex>
              <MainButton
                label="Schedule a Test"
                iconName="penetrationTest"
                size="medium"
                onClick={() => setShowRequestsModal(true)}
              />
            </Flex>
          </Flex>

          {isLegacyView ? (
            <>
              <Flex
                w100
                justify="between"
                gap="24px"
                column={isLegacyView ? isSmallScreen : isLaptop}
              >
                <RiskScoreCard selectedProduct={selectedProduct} />
                <DashboardOverviewCard selectedProduct={selectedProduct} />
              </Flex>
            </>
          ) : (
            <>
              <Flex gap="24px" w100 flexWrap={isSmallScreen}>
                <SecurityScore selectedProduct={selectedProduct} />
                <Flex column w100 gap="24px">
                  <Box style={!isLaptop ? { height: "223px" } : {}}>
                    <Flex
                      w100
                      gap="32px"
                      column={isLaptop}
                      justify={isSmallScreen ? "around" : "start"}
                    >
                      <AssetsOverviewV2 selectedProduct={selectedProduct} />
                      <DistributionOfAssets selectedProduct={selectedProduct} />
                    </Flex>
                  </Box>

                  <Box style={!isLaptop ? { height: "223px" } : {}}>
                    <Flex
                      w100
                      gap="32px"
                      column={isLaptop}
                      justify={isSmallScreen ? "around" : "start"}
                    >
                      <FindingsOverviewV2 selectedProduct={selectedProduct} />
                      <DistributionOfFindings
                        selectedProduct={selectedProduct}
                      />
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
            </>
          )}

          <FindingsChartsContainer selectedProduct={selectedProduct} />
          {!isLaptop && (
            <BottomBlockContainer
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
              onSelectedFinding={onSelectedFinding}
              onOpenProjectPane={onOpenProjectPane}
              selectedProduct={selectedProduct}
              // We need to calculate the height of the banners to adjust the height of the container,
              // banner height is 63px and the paddings of the containers is about 45px
              bannersHeight={
                numberOfShowingBanners > 0
                  ? numberOfShowingBanners * 63 + 45
                  : 0
              }
            />
          )}
        </Flex>

        <Flex column gap="24px" style={{ width: isLaptop ? "40%" : "25%" }}>
          <Box
            style={{
              padding: isMobile ? "8px" : "24px",
              paddingBottom: "8px",
              paddingTop: "16px",
              width: "100%",
              height: isLaptop ? "100%" : "calc(100vh - 420px)",
              minHeight: "700px",
              overflowY: "hidden",
            }}
            data-tut={"asm-updates"}
            id="top-right-box"
          >
            <ASMUpdates />
          </Box>
          {!isLaptop && <IntelFeed />}
        </Flex>
      </Flex>

      {isLaptop && (
        <Flex column gap="16px" style={{ marginTop: "24px" }}>
          <BottomBlockContainer
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            onSelectedFinding={onSelectedFinding}
            onOpenProjectPane={onOpenProjectPane}
            selectedProduct={selectedProduct}
          />
          <IntelFeed />
        </Flex>
      )}

      {showFindingPane && (
        <FindingPane
          onClose={onCloseFindingPane}
          findingId={selectedFinding?.id}
        />
      )}
      {showProjectPane && (
        <ProjectPane project={project} onClose={onCloseProjectPane} />
      )}
      {showReportsGen && (
        // <ReportsGenerator onClose={() => setShowReportsGen(false)} />
        <ReportsGenV2 onClose={() => setShowReportsGen(false)} />
      )}
      {showRequestsModal && (
        <RequestsModal
          onClose={() => {
            Mixpanel.track("Cancel button");
            setShowRequestsModal(false);
          }}
          switchToExpressTest={switchToExpressTest}
        />
      )}
      {showExpressPtModal && (
        <ExpressPtModal
          onClose={() => {
            Mixpanel.track("Close button");
            setShowExpressPtModal(false);
            setSearchParams({ ...searchParams, express: "" });
          }}
        />
      )}
      {showOnboardingModal && (
        <Onboarding
          onClose={() => {
            searchParams.delete("onboarding");
            setSearchParams(searchParams);
          }}
        />
      )}
    </Flex>
  );
};

export default Dashboard;
