import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { AssetOverviewCounter } from "../topBlocks/dashboardOverview/AssetOverviewCounter";
import { TrendBadge } from "../assetsOverview/TrendBadge";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import {
  SCREEN_BIG_DESKTOP_WIDTH,
  SCREEN_MEDIUM_LAPTOP_WIDTH,
} from "../../../shared/consts";
import { useApiTrendsOverview } from "../../insights/findingsTrendsGraph/findingsTrendsContext";
import { objectToBase64 } from "../../../shared/helper";
import { useApiFindingsCounts } from "../../../hooks/queries/findingContext";
import { OPEN_STATUSES } from "../../../types/Finding";

type Props = {
  selectedProduct: number | "all";
};

export const FindingsOverviewV2 = (props: Props) => {
  const { selectedProduct } = props;
  const screenWidth = useScreenWidth();
  const isLargeScreen = screenWidth >= SCREEN_BIG_DESKTOP_WIDTH - 50;
  const isLaptop = screenWidth < SCREEN_MEDIUM_LAPTOP_WIDTH;

  const getFilters = () => {
    var params: any = {};
    if (selectedProduct !== "all") params.product = selectedProduct;
    return params;
  };

  const { data: overview } = useApiTrendsOverview(getFilters());

  const { data: findingsCounts } = useApiFindingsCounts(getFilters());

  return (
    <Flex
      column
      justify="between"
      gap="24px"
      h100
      style={{
        width: isLaptop || isLargeScreen ? "100%" : "470px",
      }}
    >
      <Flex justify="between" align="center" w100>
        <Flex className="text-truncate" align="center" gap="8px">
          <HeaderSecondary className="text-truncate" title="Assets Overview">
            Findings Overview
          </HeaderSecondary>
        </Flex>
      </Flex>
      <Flex gap="16px" justify="between" align="end" h100 w100>
        <Flex column w100 gap="4px">
          <AssetOverviewCounter
            title="Fixed Findings"
            count={overview?.findings.current.fixed || 0}
            isLoading={false}
            linkTo={`/findings?filters=${objectToBase64({ status: "fixed" })}`}
          />
          <TrendBadge
            oldValue={overview?.findings.timeframe.fixed || 0}
            newValue={overview?.findings.current.fixed || 0}
            timeframeLabel={"Last 30 Days"}
            showTrendIcon
          />
        </Flex>
        <SeparatorVertical style={{ height: "100px" }} />
        <Flex column w100 gap="4px" style={{ minWidth: "146px" }}>
          <AssetOverviewCounter
            title="Mean Time to Resolve"
            suffix="Days"
            count={overview?.findings.current.mttr || 0}
            isLoading={false}
            linkTo={`/findings?filters=${objectToBase64({ status: OPEN_STATUSES, ordering: "created_at" })}`}
          />
          <TrendBadge
            oldValue={overview?.findings.timeframe.mttr || 0}
            newValue={overview?.findings.current.mttr || 0}
            timeframeLabel={"Last 30 Days"}
            showTrendIcon
          />
        </Flex>
        <SeparatorVertical style={{ height: "100px" }} />
        <Flex column w100 gap="4px">
          <AssetOverviewCounter
            title="Breached SLA"
            count={findingsCounts?.breached_sla || 0}
            isLoading={false}
            linkTo={`/findings?filters=${objectToBase64({ breached_sla: true })}`}
          />
          <Flex style={{ opacity: 0 }}>
            <TrendBadge
              oldValue={0}
              newValue={0}
              timeframeLabel={"Last 30 Days"}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
