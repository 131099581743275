import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { SkeletonLoading } from "../../../../components/elements/loading/SkeletonLoading";
import {
  BodyRegular,
  HeaderMain,
  HeaderSecondary,
  LabelRegular,
  LabelRegularHover,
} from "../../../../components/elements/typography/Typography";
import { Link } from "react-router-dom";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { useScreenWidth } from "../../../../hooks/utilsHooks";
import { SCREEN_LAPTOP_WIDTH } from "../../../../shared/consts";
import { Mixpanel } from "../../../../shared/mixpanel";
import { KPI } from "../../../../components/composed/kpi/KPI";

export const AssetOverviewCounter = ({
  title,
  titleElement,
  count,
  suffix,
  total,
  linkTo,
  isLoading,
}: {
  title: string;
  titleElement?: JSX.Element;
  count: number;
  suffix?: string;
  total?: string;
  linkTo?: string;
  isLoading: boolean;
}) => {
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();

  return (
    <Flex
      column
      align="start"
      justify="end"
      h100
      w100
      gap="4px"
      style={{ overflow: "hidden" }}
    >
      <Flex align="end" gap="6px" w100>
        {isLoading ? (
          <SkeletonLoading height="48px" width="48px" />
        ) : !total ? (
          <Flex w100 align="baseline" gap={suffix !== "%" ? "4px" : ""}>
            <KPI count={count} size="large" />
            {!!suffix && <HeaderSecondary>{suffix}</HeaderSecondary>}
          </Flex>
        ) : (
          <Flex align="baseline" gap="4px" w100>
            <Flex w100={screenWidth <= SCREEN_LAPTOP_WIDTH} align="baseline">
              {/* <KPILarge title={count.toString()}>{count}</KPILarge> */}
              <KPI count={count} size="large" />
              {!!suffix && <BodyRegular>{suffix}</BodyRegular>}
            </Flex>
            <Flex w100={screenWidth <= SCREEN_LAPTOP_WIDTH} align="baseline">
              <HeaderMain>/</HeaderMain>
              <LabelRegular title={`Total ${total}`} color={theme.black600}>
                {`${total} Total`}
              </LabelRegular>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex align="center" style={{ height: "16px" }}>
        {linkTo ? (
          <Link
            to={linkTo}
            style={{ textDecoration: "none", color: "inherit" }}
            className="text-truncate  w-100"
            onClick={() =>
              Mixpanel.track("Dashboard - Assets Overview - redirect", {
                title,
              })
            }
          >
            <LabelRegularHover title={title} color={theme.textSecondary}>
              {titleElement || title}
            </LabelRegularHover>
          </Link>
        ) : (
          <LabelRegular
            className="text-truncate w-100"
            title={title}
            color={theme.textSecondary}
          >
            {titleElement || title}
          </LabelRegular>
        )}
      </Flex>
    </Flex>
  );
};
