import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { DoughnutChart } from "../../../components/composed/charts/DoughnutChart";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyBold,
  BodyRegular,
} from "../../../components/elements/typography/Typography";
import { useApiSecurityScoreExtended } from "../../insights/findingsTrendsGraph/findingsTrendsContext";
import { TrendBadge } from "../assetsOverview/TrendBadge";

const getRiskLevelAndText = (score: number) => {
  const securityScoreTextsMap = {
    high: "Your security posture is critically weak. Major vulnerabilities expose you to high-risk threats. Immediate action is needed to reduce exposure.",
    moderate:
      "Your security posture needs improvement. Several vulnerabilities could be exploited by attackers. Address key issues to strengthen protection.",
    low: "Your security is in good shape. Some improvements can further reduce risk. Regular monitoring and updates are recommended.",
    veryLow:
      "Your security posture is strong. Minimal vulnerabilities are present. Maintain best practices to stay protected.",
  };

  if (score < 40)
    return { riskLevel: "High Risk", text: securityScoreTextsMap.high };
  if (score >= 40 && score < 70)
    return { riskLevel: "Moderate Risk", text: securityScoreTextsMap.moderate };
  if (score >= 70 && score < 90)
    return { riskLevel: "Low Risk", text: securityScoreTextsMap.low };
  return { riskLevel: "Very Low Risk", text: securityScoreTextsMap.veryLow };
};

type Props = {
  score: number;
};

export const SecurityScorePieChart = ({ score }: Props) => {
  const theme = useContext(ThemeContext);

  const getBackgroundColor = (score: number) => {
    if (score < 30) return theme.redPrimary;
    if (score >= 30 && score < 70) return theme.yellowPrimary;
    return theme.greenPrimary;
  };

  const info = getRiskLevelAndText(score);

  const { data: trend } = useApiSecurityScoreExtended();

  return (
    <Flex h100 w100 align="center" justify="around" gap="24px">
      <DoughnutChart
        isLoadingData={score === undefined}
        labels={["Security Score"]}
        datasets={[
          {
            data: [score, 100 - score],
            backgroundColor: [getBackgroundColor(score), theme.black500],
          },
        ]}
        size={100}
        top={"25px"}
        labelNumber={score}
        labelText=""
        labelSuffix="%"
        labelNumberSize={24}
      />
      <Flex column gap="4px" style={{ minWidth: "250px" }}>
        <BodyBold>{info.riskLevel}</BodyBold>
        <BodyRegular>{info.text}</BodyRegular>
        <TrendBadge
          oldValue={trend?.security_score_30_days_ago || 0}
          newValue={trend?.security_score || 0}
          timeframeLabel={"Last 30 Days"}
          showTrendIcon
        />
      </Flex>
    </Flex>
  );
};
