import { Me } from "../../types/Me";
import { ChatMessageType } from "./chat/ChatMessageType";
import { WaspAIMessage } from "./context/types";

export const THREAD_ID_LOCAL_STORAGE_KEY = "thread_id";

export const getOrCreateThreadId = (me: Me | undefined): string | undefined => {
  if (!me) return undefined;
  const threadId = localStorage.getItem(THREAD_ID_LOCAL_STORAGE_KEY);
  if (threadId) return threadId;
  const timestamp: number = new Date().getTime(); // milliseconds
  const newThreadId = `${me.customer.id}-${me.account_id}-${timestamp}`;
  localStorage.setItem(THREAD_ID_LOCAL_STORAGE_KEY, newThreadId);
  return newThreadId;
};

export const getDataFromThreadId = (threadId: string) => {
  const [customerId, accountID, timestamp] = threadId.split("-");
  return { customerId, accountID, timestamp };
};

export const formatMessagesForApi = (
  chatMessages: ChatMessageType[]
): WaspAIMessage[] => {
  // Convert to Anthropic messages format
  return chatMessages.map((message) => ({
    role: message.role,
    content: [{ type: "text", text: message.text }],
  }));
};
