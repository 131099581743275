import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { Icon } from "../../../components/elements/icon/Icon";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import {
  HeaderSecondary,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { LastFindingsFiltersCategory } from "../latestFindings/LastFindingsFiltersCategory";
import { SEVERITIES } from "../../../shared/consts";
import { findingTypesOptions, STATUSES } from "../../../types/Finding";
import { useApiAssetsPaging } from "../../../hooks/queries/assetsContext";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { useApiProjects } from "../../../hooks/queries/projectsContext";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import {
  dateObjToDateString,
  deepEqual,
  isClosedStatus,
} from "../../../shared/helper";
import { getStatusName } from "../../../shared/findingsHelper";
import {
  convertToServerFilters,
  convertToUiFilters,
  getNumberOfActiveFilters,
} from "./FindingsFilterBL";
import { DualRangeSlider } from "../../../components/elements/slider/DualSlider";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { useApiAccounts } from "../../../hooks/queries/accountsContext";
import { SlaExpiration } from "./SlaExpiration";
import { useApiFindingsLabelsCves } from "../../../hooks/queries/findingContext";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { useApiSuperUsers } from "../../../hooks/queries/usersContext";
import { useApiScanners } from "../../../hooks/queries/scannersContext";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Flex } from "../../../components/layouts/flex/Flex";
import { RangeDatePicker } from "../../../components/elements/datetimePicker/RangeDatePicker";
import { priorityOptions } from "../../assets/AssetUtils";

type Filters = { [key: string]: any };

type Props = {
  onClose: () => void;
  filters: { [key: string]: any };
  setFilters?: (filters: {}) => void;
  setFiltersUrl?: (filters: Filters) => void;
  activeOnly?: boolean;
  searchWord?: string;
};

export const FindingsFiltersPane = (props: Props) => {
  const {
    onClose: close,
    filters,
    setFilters,
    activeOnly = false,
    setFiltersUrl,
    searchWord,
  } = props;

  const theme = useContext(ThemeContext);
  const isSuperuser = useIsSuperuser();
  const { data: products } = useApiProducts();
  const { data: projects } = useApiProjects();
  const { data: accounts } = useApiAccounts();
  const { data: labelsCves } = useApiFindingsLabelsCves();
  const { data: superusers } = useApiSuperUsers({}, isSuperuser);
  const [affectedAssetsFilters, setAffectedAssetsFilters] = useState<Filters>({
    risk_score_min: 1,
  });

  const {
    data: affectedAssets,
    isFetching: affectedAssetsIsFetching,
    hasNextPage: affectedAssetsHasNextPage,
    fetchNextPage: affectedAssetsFetchNextPage,
  } = useApiAssetsPaging(affectedAssetsFilters, true);

  const [newFilters, setNewFilters] = useState<Filters>(
    convertToUiFilters(filters)
  );
  const [search, setSearch] = useState(searchWord);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(
    () => setIsDisabled(deepEqual(newFilters, convertToUiFilters(filters))),
    [newFilters, filters]
  );

  const getAffectedAssets = () => {
    return affectedAssets?.pages.map((page) => page.results || []).flat() || [];
  };

  const { data: me } = useApiMe();

  const { data: scanners } = useApiScanners(me?.customer?.id);
  const scannersDisplayNames =
    scanners?.map((scanner) => scanner.display_name) || [];

  const onApplyFilters = () => {
    var tempFilters = convertToServerFilters(newFilters, activeOnly);
    setFilters && setFilters(tempFilters); // Will be used from Dashboard page
    setFiltersUrl && setFiltersUrl(tempFilters); // Will be used from Findings page
    close();
  };

  const onChangeGeneralList = (optionValues: string[], key: string) => {
    var tempFilters = { ...newFilters };
    if (optionValues.length) {
      tempFilters[key] = optionValues.join(",");
    } else {
      delete tempFilters[key];
    }
    setNewFilters(tempFilters);
  };

  const onChangeBooleanList = (optionValues: string[], key: string) => {
    var tempFilters = { ...newFilters };
    if (optionValues.length) {
      tempFilters[key] = optionValues;
    } else {
      delete tempFilters[key];
    }
    setNewFilters(tempFilters);
  };

  const onChangeCreatedAt = (date: string, startOrEnd: string) => {
    let tempFilters = { ...newFilters };
    // const isoDate = getIsoString(date);
    // if (startOrEnd === "start") tempFilters.created_at_after = isoDate;
    // if (startOrEnd === "end") tempFilters.created_at_before = isoDate;

    const dateFormat = dateObjToDateString(new Date(date));
    if (startOrEnd === "start") tempFilters.min_created_at = dateFormat;
    if (startOrEnd === "end") tempFilters.max_created_at = dateFormat;
    setNewFilters(tempFilters);
  };

  const onChangeCvssValues = ({ min, max }: { min?: number; max?: number }) => {
    let tempFilters = { ...newFilters };
    if (!!min) tempFilters.cvss_min_score = min;
    if (!!max) tempFilters.cvss_max_score = max;
    setNewFilters(tempFilters);
  };

  const onChangeSlaExpiration = (condition: string, date: string) => {
    var tempFilters = { ...newFilters };
    if (condition === "sla_expires_at") {
      delete tempFilters.sla_expires_after;
      tempFilters.sla_expires_at = date;
    } else if (condition === "sla_expires_after") {
      delete tempFilters.sla_expires_at;
      tempFilters.sla_expires_after = date;
    } else if (condition === "all") {
      delete tempFilters.sla_expires_after;
      delete tempFilters.sla_expires_at;
    }
    setNewFilters(tempFilters);
  };

  const isSearchInOptions = (options: readonly any[]): boolean => {
    if (!search) return true;
    return options.some((option) => {
      return option?.toLowerCase().includes(search.toLowerCase());
    });
  };

  const isSearchInTitle = (title: string): boolean => {
    if (!search) return true;
    return title?.toLowerCase().includes(search.toLowerCase());
  };

  return (
    <RightPane onClose={close}>
      <div style={{ padding: 32 }}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <Icon name="filter" size={34} color={theme.primary} />
            <HeaderSecondary style={{ color: theme.black900, marginLeft: 8 }}>
              Issues Filter
            </HeaderSecondary>
          </div>
          <div className="d-flex align-items-center">
            <TextButton
              label="Reset"
              disabled={
                !newFilters || getNumberOfActiveFilters(newFilters) === 0
              }
              onClick={() => setNewFilters({})}
              dataTestId="reset-filters"
            />
            <MainButton
              size="small"
              disabled={isDisabled}
              label="Apply"
              onClick={onApplyFilters}
              dataTestId="apply-filters"
            />
          </div>
        </div>
        <div className="w-100 mt-3 mb-2">
          <InputText
            iconName="search"
            width={"100%"}
            onChange={(e) => {
              setSearch(e.target.value);
              setTimeout(() => {
                setAffectedAssetsFilters({
                  risk_score_min: 1,
                  name: e.target.value,
                });
              }, 500);
            }}
            placeholder="Search Filters"
          />
        </div>
        <SeparatorHorizontal />
        <div style={{ height: "calc(100vh - 215px)", overflowY: "scroll" }}>
          {isSearchInTitle("created") && (
            <Flex w100 column gap="24px" style={{ paddingTop: "24px" }}>
              <LabelRegular>Created At</LabelRegular>
              <Flex w100 justify="center">
                <RangeDatePicker
                  start={dateObjToDateString(
                    new Date(
                      // newFilters.created_at_after
                      newFilters.min_created_at
                    )
                  )}
                  onChangeStart={(e) =>
                    onChangeCreatedAt(e.target.value, "start")
                  }
                  end={dateObjToDateString(
                    new Date(
                      // newFilters.created_at_before
                      newFilters.max_created_at
                    )
                  )}
                  onChangeEnd={(e) => onChangeCreatedAt(e.target.value, "end")}
                />
              </Flex>
              <SeparatorHorizontal />
            </Flex>
          )}
          {isSearchInTitle("cvss") && (
            <LastFindingsFiltersCategory
              newFilters={newFilters}
              title="CVSS Score"
              options={[]}
              onChange={() => {}}
              children={
                <DualRangeSlider
                  min={newFilters?.cvss_min_score}
                  max={newFilters?.cvss_max_score}
                  setMin={(min) => onChangeCvssValues({ min })}
                  setMax={(max) => onChangeCvssValues({ max })}
                />
              }
            />
          )}
          {(isSearchInTitle("Finding Type") ||
            isSearchInOptions(findingTypesOptions.map((o) => o.value))) && (
            <LastFindingsFiltersCategory
              newFilters={newFilters}
              title="Finding Type"
              options={findingTypesOptions
                .map((o) => ({
                  ...o,
                  checked:
                    !!newFilters && newFilters?.finding_type?.includes(o.value),
                }))
                .filter(
                  (option) =>
                    isSearchInTitle("Finding Type") ||
                    isSearchInTitle(option.label)
                )}
              onChange={(optionValues) =>
                onChangeGeneralList(optionValues, "finding_type")
              }
            />
          )}
          {(isSearchInTitle("Product") ||
            isSearchInOptions((products || []).map((p) => p.name))) && (
            <LastFindingsFiltersCategory
              newFilters={newFilters}
              title="Product"
              options={
                (products || [])
                  .map((product) => ({
                    label: product.name,
                    value: product.id.toString(),
                    checked: newFilters?.products?.includes(product.id),
                  }))
                  .filter(
                    (option) =>
                      isSearchInTitle("Product") ||
                      isSearchInTitle(option.label)
                  ) || []
              }
              onChange={(optionValues) =>
                onChangeGeneralList(optionValues, "products")
              }
            />
          )}
          {(isSearchInTitle("Project") ||
            isSearchInOptions((projects || []).map((p) => p.name))) && (
            <LastFindingsFiltersCategory
              newFilters={newFilters}
              title="Project"
              options={
                (projects || [])
                  .map((project) => ({
                    label: project.name,
                    value: project.id.toString(),
                    checked: newFilters?.project?.includes(project.id),
                  }))
                  .filter(
                    (option) =>
                      isSearchInTitle("Project") ||
                      isSearchInTitle(option.label)
                  ) || []
              }
              onChange={(optionValues) =>
                onChangeGeneralList(optionValues, "project")
              }
            />
          )}
          {(isSearchInTitle("Ticket Status") ||
            isSearchInOptions(STATUSES)) && (
            <LastFindingsFiltersCategory
              newFilters={newFilters}
              title="Ticket Status"
              options={STATUSES.filter((s) => !activeOnly || !isClosedStatus(s))
                .map((status) => ({
                  label: getStatusName(status),
                  value: status,
                  checked: newFilters?.status?.includes(status),
                }))
                .filter(
                  (option) =>
                    isSearchInTitle("Ticket Status") ||
                    isSearchInTitle(option.label)
                )}
              onChange={(optionValues) =>
                onChangeGeneralList(optionValues, "status")
              }
            />
          )}
          {(isSearchInTitle("Severity Overall Risk") ||
            isSearchInOptions(Object.values(SEVERITIES))) && (
            <LastFindingsFiltersCategory
              newFilters={newFilters}
              title="Severity"
              options={Object.keys(SEVERITIES)
                .reverse()
                .map((severity) => ({
                  label: SEVERITIES[severity],
                  value: severity.toString(),
                  checked: newFilters?.overall_risk?.includes(severity),
                }))
                .filter(
                  (option) =>
                    isSearchInTitle("Severity Overall Risk") ||
                    isSearchInTitle(option.label)
                )}
              onChange={(optionValues) =>
                onChangeGeneralList(optionValues, "overall_risk")
              }
            />
          )}
          {(isSearchInTitle("Breached SLA") ||
            isSearchInOptions(["breached", "compliance"])) && (
            <LastFindingsFiltersCategory
              newFilters={newFilters}
              title="SLA"
              options={[
                {
                  label: "Breached",
                  value: "breached",
                  checked: newFilters?.breached_sla?.includes("breached"),
                },
                {
                  label: "Compliance",
                  value: "compliance",
                  checked: newFilters?.breached_sla?.includes("compliance"),
                },
              ].filter(
                (option) =>
                  isSearchInTitle("Breached SLA") ||
                  isSearchInTitle(option.label)
              )}
              onChange={(optionValues) =>
                onChangeBooleanList(optionValues, "breached_sla")
              }
            />
          )}

          {(isSearchInTitle("SLA Expiration") ||
            isSearchInOptions(["sla", "expiration"])) && (
            <LastFindingsFiltersCategory
              newFilters={newFilters}
              title="SLA Expiration"
              children={
                <SlaExpiration
                  newFilters={newFilters}
                  onChange={onChangeSlaExpiration}
                />
              }
              onChange={() => {}}
              options={[]}
            />
          )}

          {!activeOnly &&
            (isSearchInTitle("False Positive") ||
              isSearchInOptions(["show", "hide"])) && (
              <LastFindingsFiltersCategory
                newFilters={newFilters}
                title="False Positive"
                options={[
                  {
                    label: "Show",
                    value: "show",
                    checked: newFilters?.is_false_positive?.includes("show"),
                  },
                  {
                    label: "Hide",
                    value: "hide",
                    checked: newFilters?.is_false_positive?.includes("hide"),
                  },
                ].filter(
                  (option) =>
                    isSearchInTitle("False Positive") ||
                    isSearchInTitle(option.label)
                )}
                onChange={(optionValues) =>
                  onChangeBooleanList(optionValues, "is_false_positive")
                }
              />
            )}
          {(isSearchInTitle("Affected Assets") ||
            isSearchInOptions(getAffectedAssets().map((p) => p.name))) && (
            <LastFindingsFiltersCategory
              newFilters={newFilters}
              title="Affected Assets"
              options={getAffectedAssets()
                .map((asset) => ({
                  label: asset.name,
                  value: asset.id.toString(),
                  checked: newFilters?.affected_assets?.includes(
                    asset.id.toString()
                  ),
                }))
                .filter(
                  (option) =>
                    isSearchInTitle("Affected Assets") ||
                    isSearchInTitle(option.label)
                )}
              onChange={(optionValues) => {
                onChangeBooleanList(optionValues, "affected_assets");
              }}
              hasNextPage={affectedAssetsHasNextPage}
              isFetching={affectedAssetsIsFetching}
              fetchNextPage={affectedAssetsFetchNextPage}
            />
          )}
          {(isSearchInTitle("Affected Asset Priority") ||
            isSearchInOptions(
              getAffectedAssets().map((asset) => asset.name)
            )) && (
            <LastFindingsFiltersCategory
              newFilters={newFilters}
              title="Affected Asset Priority"
              options={priorityOptions
                .map((priority) => ({
                  label: priority.label,
                  value: priority.value.toString(),
                  checked: newFilters?.affected_assets_priority?.includes(
                    priority.value
                  ),
                }))
                .filter(
                  (option) =>
                    isSearchInTitle("Affected Asset Priority") ||
                    isSearchInTitle(option.label)
                )}
              onChange={(optionValues) =>
                onChangeGeneralList(optionValues, "affected_assets_priority")
              }
            />
          )}
          {(isSearchInTitle("Assignee") ||
            (accounts &&
              isSearchInOptions(accounts.map((a) => a.user.name)))) && (
            <LastFindingsFiltersCategory
              newFilters={newFilters}
              title="Assignee"
              isSingleOption={true}
              options={
                accounts
                  ?.filter((a) => !!a.user.name)
                  ?.map((a) => ({
                    label: a.user.name,
                    value: a.email,
                    checked:
                      !!newFilters?.assignee?.length &&
                      newFilters.assignee[0] === a.email,
                  }))
                  .filter(
                    (option) =>
                      isSearchInTitle("Assignee") ||
                      isSearchInTitle(option.label)
                  ) || []
              }
              onChange={(optionValues) => {
                onChangeBooleanList(optionValues, "assignee");
              }}
            />
          )}

          {isSuperuser &&
            (isSearchInTitle("IssuedBy") ||
              (isSuperuser &&
                superusers &&
                isSearchInOptions(superusers.map((a) => a.email)))) && (
              <LastFindingsFiltersCategory
                newFilters={newFilters}
                title="Issued By"
                isSingleOption={true}
                options={
                  superusers
                    ?.map((a) => ({
                      label: `${a.first_name} ${a.last_name}` || a.email,
                      value: a.email,
                      checked:
                        !!newFilters?.issued_by?.length &&
                        newFilters.issued_by[0] === a.email,
                    }))
                    .filter(
                      (option) =>
                        isSearchInTitle("IssuedBy") ||
                        isSearchInTitle(option.label)
                    ) || []
                }
                onChange={(optionValues) => {
                  onChangeBooleanList(optionValues, "issued_by");
                }}
              />
            )}

          {(isSearchInTitle("labels") ||
            (labelsCves && isSearchInOptions(labelsCves.labels))) && (
            <LastFindingsFiltersCategory
              newFilters={newFilters}
              title="Labels"
              options={
                labelsCves?.labels
                  ?.map((a) => ({
                    label: a,
                    value: a,
                    checked: newFilters?.labels?.includes(a),
                  }))
                  .filter(
                    (option) =>
                      isSearchInTitle("Labels") || isSearchInTitle(option.label)
                  ) || []
              }
              onChange={(optionValues) => {
                onChangeBooleanList(optionValues, "labels");
              }}
            />
          )}
          {(isSearchInTitle("scanner") ||
            isSearchInOptions(scannersDisplayNames)) && (
            <LastFindingsFiltersCategory
              isSingleOption
              newFilters={newFilters}
              title="Source Scanner"
              options={
                scanners
                  ?.filter(
                    (s) => !["sublister", "bbot-scanner"].includes(s.name)
                  )
                  ?.map((scanner) => ({
                    label: scanner.display_name,
                    value: scanner.name,
                    checked: newFilters?.scanner_name?.includes(scanner.name),
                  }))
                  .filter(
                    (option) =>
                      isSearchInTitle("scanners") ||
                      isSearchInTitle(option.label)
                  ) || []
              }
              onChange={(optionValues) =>
                // onChangeBooleanList(optionValues, "scanner_name")
                setNewFilters((currentFilters) => ({
                  ...currentFilters,
                  scanner_name: optionValues[0],
                }))
              }
            />
          )}
        </div>
      </div>
    </RightPane>
  );
};
