import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import {
  BodyBold,
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiCreateMfaToken } from "../../../hooks/queries/usersContext";
import useToastContext from "../../../hooks/toastHook";

export const MfaMessage = ({
  email,
  onClose,
}: {
  email: string;
  onClose: () => void;
}) => {
  const addToast = useToastContext();
  const { mutate: createNewToken, isLoading } = useApiCreateMfaToken();

  const createMfaToken = () =>
    createNewToken({
      email: email,
      onErrorCallback: (err) =>
        addToast({ type: "error", message: err.message }),
      onSuccessCallback: () =>
        addToast({ type: "success", message: `Email sent to ${email}` }),
    });

  return (
    <Flex column w100 gap="24px" align="center" style={{ padding: "24px" }}>
      <Flex column gap="8px" align="center">
        <HeaderSecondary>Check your email to verify</HeaderSecondary>
        <BodyRegular>
          Please click the link in the email we sent you to complete your
          credentials update.
        </BodyRegular>
      </Flex>
      <Flex w100 column gap="8px" justify="center" align="center">
        <SeparatorHorizontal />
        <BodyBold>Email sent to {email}</BodyBold>
        <BodyRegular>
          Didn't get the email? Please be sure to check your Spam or Junk folder
        </BodyRegular>
        <Flex w100 align="center" justify="center" gap="24px">
          <MainButton
            label="resend"
            style={{ width: "120px" }}
            onClick={createMfaToken}
            inProgress={isLoading}
          />
          <SecondaryButton label="Back to Sign In" onClick={() => onClose()} />
        </Flex>
      </Flex>
    </Flex>
  );
};
