import { Dispatch, SetStateAction, useContext, useRef, useState } from "react";
import { CSSProperties, ThemeContext } from "styled-components";
import { MenuItemStyle } from "../FindingsStyles";
import { useApiUpdateFindingStatus } from "../../../hooks/queries/findingContext";
import { useApiMe } from "../../../hooks/queries/meContext";
import useToastContext from "../../../hooks/toastHook";
import { Finding, FindingEdit, FindingStatus } from "../../../types/Finding";
import { Avatar } from "../../../components/elements/avatar/Avatar";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { Icon } from "../../../components/elements/icon/Icon";
import { TextArea } from "../../../components/elements/input/textArea/TextArea";
import { Menu } from "../../../components/elements/menu/Menu";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { useOutsideTrigger } from "../../../hooks/utilsHooks";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Backdrop } from "../../../components/elements/rightPane/RightPane";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";

type Props = {
  close: () => void;
  finding?: Finding | null;
  setShowConfirmModal?: Dispatch<SetStateAction<boolean>>;
  setUpdates?: Dispatch<SetStateAction<FindingEdit | null>>;
  customStyle?: CSSProperties;
};

type StatusOption = {
  label: string;
  key: FindingStatus;
  iconColor?: string;
};

export const FindingStatusMenu = (props: Props) => {
  const { close, finding, customStyle, setShowConfirmModal, setUpdates } =
    props;
  const isSuperuser = useIsSuperuser();
  const theme = useContext(ThemeContext);
  const addToast = useToastContext();
  const wrapperRef = useRef(null);
  const { data: me } = useApiMe();

  const [selectedStatus, setSelectedStatus] = useState<StatusOption>();
  const [commentText, setCommentText] = useState<string>("");

  const closeMenu = () => !commentText && close();
  useOutsideTrigger(wrapperRef, closeMenu);

  const onSuccessCallBack = () => {
    addToast({
      message: `status has been successfully changed`,
      type: "success",
    });
    close();
  };

  const onErrorCallBack = (error: Error) => {
    addToast({
      message: error.message,
      type: "error",
    });
  };

  const { mutate: mutateSingle, isLoading } = useApiUpdateFindingStatus({
    findingId: finding?.id || 0,
    onSuccessCallBack,
    onErrorCallBack,
  });

  const handleUpdate = () => {
    if (finding) {
      if (selectedStatus?.key) finding.status = selectedStatus.key;
      mutateSingle({
        findingId: finding.id,
        findingData: {
          comment: commentText,
          status: selectedStatus?.key,
        },
      });
    } else {
      setUpdates &&
        setUpdates({ status: selectedStatus?.key, comment: commentText });
      setShowConfirmModal && setShowConfirmModal(true);
    }
  };

  let statusOptions: StatusOption[] = [
    { label: "Presented", key: "presented" },
    { label: "In Progress", key: "in_progress" },
    { label: "Ready For Retest", key: "re_test" },
    { label: "Acceptable Risk", key: "acceptable_risk" },
    { label: "Dismissed", key: "dismissed", iconColor: theme.blue300 },
  ];

  if (
    isSuperuser ||
    (me?.customer.is_multi_tenant && finding?.source === "CUSTOMER_INSERTED")
  )
    statusOptions = [
      ...statusOptions,
      { label: "Fixed", key: "fixed", iconColor: theme.greenPrimary },
    ];

  return (
    <>
      <Backdrop
        transparent
        blurEnabled={false}
        onClick={close}
        style={{
          position: "absolute",
          left: "-100vw",
          top: "-100vh",
          bottom: "-100vh",
          right: "-100vw",
        }}
      />
      <Menu
        data-testid="status-menu"
        ref={wrapperRef}
        style={{
          width: "320px",
          zIndex: 200,
          ...customStyle,
        }}
      >
        {!selectedStatus ? (
          <Flex column w100 gap="8px">
            <LabelRegular
              className="d-flex w-100"
              style={{ color: theme.black900 }}
            >
              Select new Status
              <span className="ms-auto pointer" onClick={close}>
                <Icon name="cancel" size={16} color={theme.primary} />
              </span>
            </LabelRegular>
            <SeparatorHorizontal style={{ marginBlock: "8px" }} />
            <Flex column gap="16px">
              {statusOptions.map((status) => (
                <MenuItemStyle
                  style={{
                    color: theme.black800,
                    fontWeight: status.key === finding?.status ? "600" : "400",
                  }}
                  onClick={() =>
                    status.key !== finding?.status && setSelectedStatus(status)
                  }
                  key={`item-${status.key}`}
                  data-testid={`item-${status.key}`}
                >
                  <Flex align="center" gap="8px">
                    <Icon
                      name={status.key}
                      color={status.iconColor || theme.primary}
                      size={16}
                    />
                    {status.label}
                  </Flex>
                </MenuItemStyle>
              ))}
            </Flex>
          </Flex>
        ) : (
          <Flex column w100 gap="8px" data-testid="reason-for-change-form">
            <LabelRegular
              className="d-flex w-100 gap-4"
              style={{ color: theme.black900 }}
            >
              <span
                data-testid="back-btn"
                className="pointer"
                onClick={() => setSelectedStatus(undefined)}
              >
                <Icon name="chevronLeft" color={theme.primary} size={16} />
              </span>
              Reason for Change
              <span className="ms-auto pointer" onClick={close}>
                <Icon name="cancel" size={16} color={theme.primary} />
              </span>
            </LabelRegular>
            <SeparatorHorizontal style={{ marginBlock: "8px" }} />
            <Flex column gap="16px">
              <Flex gap="8px">
                <Avatar imageSrc={me?.avatar_url} />
                <TextArea
                  width="245px"
                  dataTestId="comment-input"
                  placeholder="Reason for the change in status"
                  onChange={(e) => setCommentText(e.target.value)}
                />
              </Flex>
            </Flex>
            <MainButton
              label="Submit"
              dataTestId="submit-btn"
              size="small"
              style={{ width: "83px", alignSelf: "flex-end" }}
              inProgress={isLoading}
              disabled={
                ["acceptable_risk", "dismissed"].includes(selectedStatus.key) &&
                !commentText
              }
              onClick={handleUpdate}
            />
          </Flex>
        )}
      </Menu>
    </>
  );
};
