import { useMutation, useQuery } from "@tanstack/react-query";
import { createItem, getItems } from "./sdk";

const superUsersKey = "users/superusers/";

const mfaKey = "auth/mfa/";

export type OpUser = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  avatar_url: string;
  is_superuser: boolean;
};

export const useApiSuperUsers = (
  params?: { [key: string]: any },
  enabled = true
) =>
  useQuery<OpUser[], Error>({
    queryKey: [superUsersKey],
    keepPreviousData: true,
    placeholderData: [],
    enabled: enabled,
    queryFn: async (): Promise<OpUser[]> => getItems(superUsersKey, params),
  });

type MfaTokenParams = {
  email: string;
  token?: string;
  onSuccessCallback?: () => void;
  onErrorCallback?: (err: Error) => void;
};

export const useApiCreateMfaToken = () => {
  return useMutation<MfaTokenParams, Error, MfaTokenParams>({
    mutationKey: [mfaKey],
    mutationFn: async (mfaTokenParams): Promise<MfaTokenParams> =>
      await createItem(mfaKey, mfaTokenParams),
    onSuccess: (data: MfaTokenParams, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback();
    },
    onError: (error, { onErrorCallback }) =>
      onErrorCallback && onErrorCallback(error),
  });
};

export const useApiCheckMfa = (
  params?: { [key: string]: any },
  enabled = true
) => {
  useQuery<any[], Error>({
    queryKey: [mfaKey],
    keepPreviousData: true,
    placeholderData: [],
    enabled: enabled,
    queryFn: async (): Promise<any[]> => getItems(mfaKey, params),
  });
};
