import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import {
  Dropdown,
  Option,
} from "../../../../components/elements/dropdowns/Dropdown";
import { Icon } from "../../../../components/elements/icon/Icon";
import { SeparatorVertical } from "../../../../components/elements/separators/SeparatorVertical";
import {
  HeaderSecondary,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { AssetOverviewCounter } from "./AssetOverviewCounter";
import { Flex } from "../../../../components/layouts/flex/Flex";
import {
  getDateTimeDaysBeforeNow,
  toBase64AssetsView,
} from "../../../../shared/helper";
import { Tooltip } from "../../../../components/elements/tooltip/Tooltip";
import { useApiProducts } from "../../../../hooks/queries/productsContext";
import { useApiMe } from "../../../../hooks/queries/meContext";
import { Mixpanel } from "../../../../shared/mixpanel";
import { useScreenWidth } from "../../../../hooks/utilsHooks";
import { SCREEN_BIG_DESKTOP_WIDTH } from "../../../../shared/consts";
import { useApiAssetsCount } from "../../../../hooks/queries/assetsContext";

type Props = {
  selectedProduct: number | "all";
};

export const AssetsOverview = (props: Props) => {
  const { selectedProduct } = props;
  const screenWidth = useScreenWidth();

  const [assetsCountsTimeframe, setAssetsCountsTimeframe] = useState(30);

  const getAssetsCountParams = () => {
    var params: any = {};
    if (selectedProduct !== "all") params.product = selectedProduct;
    if (assetsCountsTimeframe) params.timeframe = assetsCountsTimeframe;
    return params;
  };

  const { data: assetsCounts, isFetching } = useApiAssetsCount(
    getAssetsCountParams()
  );

  const { data: products, isFetching: isFetchingProducts } = useApiProducts();
  const { data: me } = useApiMe();
  const theme = useContext(ThemeContext);
  const timeOptions: Option[] = [
    { label: "Last 24 Hours", value: "1" },
    { label: "Last 7 Days", value: "7" },
    { label: "Last 30 Days", value: "30" },
    { label: "Last 6 Months", value: "180" },
    { label: "All times", value: "0" },
  ];

  return (
    <>
      <Flex column justify="between" gap="24px" h100 w100>
        <Flex justify="between" align="center" w100>
          <Flex className="text-truncate" align="center" gap="8px">
            <Icon name="assetsOutline" size={32} color={theme.primary} />
            <HeaderSecondary className="text-truncate" title="Assets Overview">
              Assets Overview
            </HeaderSecondary>
          </Flex>
          <Flex
            style={{
              overflow: "auto",
              maxWidth: "120px",
              flexShrink: 0,
            }}
          >
            <Dropdown
              onChange={(option) => {
                Mixpanel.track(
                  "Dashboard - Assets Overview - Timeframe selection",
                  { timeSelected: option?.label || "" }
                );

                setAssetsCountsTimeframe(
                  parseInt(option?.value.toString() || "")
                );
              }}
              options={timeOptions}
              placeholder={
                timeOptions.find(
                  (option) => option.value === assetsCountsTimeframe.toString()
                )?.label
              }
              isMenuPositionFixed
            />
          </Flex>
        </Flex>
        <Flex gap="16px" justify="between" align="end" h100 w100>
          <AssetOverviewCounter
            title="discovered assets"
            count={assetsCounts?.newly_discoverd || 0}
            total={`${assetsCounts?.total}`}
            isLoading={!!isFetching && !assetsCounts?.newly_discoverd}
            linkTo={`/assets?view=${toBase64AssetsView({
              name: "",
              filters: [
                {
                  column: "created_at",
                  value: getDateTimeDaysBeforeNow(
                    assetsCountsTimeframe
                  ).toISOString(),
                  condition: "after",
                  order: 0,
                  next_condition: "and",
                },
              ],
            })}`}
          />
          <SeparatorVertical style={{ height: "100px" }} />
          <AssetOverviewCounter
            title="Total Vulnerable Assets"
            count={assetsCounts?.total_vulnerable || 0}
            isLoading={!!isFetching && !assetsCounts?.total_vulnerable}
            linkTo={`/assets?view=${toBase64AssetsView({
              name: "",
              filters: [
                {
                  column: "risk_score",
                  value: "0",
                  condition: "gt",
                  order: 0,
                  next_condition: "and",
                },
              ],
            })}`}
          />
          <SeparatorVertical style={{ height: "100px" }} />

          <AssetOverviewCounter
            title="Protection Coverage"
            titleElement={
              <Flex align="center" gap="4px">
                <LabelRegular
                  className={
                    screenWidth < SCREEN_BIG_DESKTOP_WIDTH
                      ? "text-truncate"
                      : ""
                  }
                  title="Protection Coverage"
                  color={theme.textSecondary}
                >
                  Protection Coverage
                </LabelRegular>
                <Tooltip
                  placement="bottom"
                  content={
                    <LabelRegular
                      style={{
                        maxWidth: "200px",
                        textTransform: "none",
                      }}
                    >
                      <strong>Protection Coverage</strong> measures security
                      measures for an organization's assets. <br />
                      It considers three factors - Web Application Firewall
                      (WAF), <br />
                      Penetration Testing, and Active Security Monitoring (ASM).{" "}
                      <br />
                      The score is based on whether these factors are present or
                      not, with equal weight for each.
                    </LabelRegular>
                  }
                >
                  <Icon name="info" size={16} color={theme.black600} />
                </Tooltip>
              </Flex>
            }
            count={me?.customer?.coverage_score || 0}
            suffix="%"
            isLoading={isFetchingProducts && !products}
          />
        </Flex>
      </Flex>
    </>
  );
};
