import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Flex } from "../../components/layouts/flex/Flex";
import { Icon } from "../../components/elements/icon/Icon";
import {
  HeaderSub,
  LabelMedium,
} from "../../components/elements/typography/Typography";
import { IntegrationsIndicators } from "./sub-components/IntegrationsIndicators";
import { SecondaryButton } from "../../components/elements/button/secondary/SecondaryButton";
import { Service, servicePurchaseStatus } from "./types";
import { MainButton } from "../../components/elements/button/main/MainButton";

type StyledServiceCardProps = {
  purchaseStatus: servicePurchaseStatus;
};

const StyledServiceCard = styled.div<StyledServiceCardProps>`
  width: 570px;
  height: 188px;
  display: flex;
  gap: 24px;
  border-radius: 8px;
  border-width: 1px;
  padding: 24px;
  background: ${(props) =>
    props.purchaseStatus !== "Not purchased"
      ? props.theme.blue50
      : props.theme.bg2};
  border: 1px solid
    ${(props) =>
      props.purchaseStatus !== "Not purchased"
        ? props.theme.primary
        : props.theme.gray500};
`;

type Props = {
  service: Service;
  onClickService: (service: Service) => void;
};

export const ServiceCard = (props: Props) => {
  const { service, onClickService } = props;

  const theme = useContext(ThemeContext);

  const actionButtonLabel = {
    "Not purchased": "Learn more",
    "Request sent": "Request sent",
    "Purchased service": "Cancel service",
  };

  return (
    <StyledServiceCard purchaseStatus={service.purchaseStatus}>
      <Flex w100 h100 column justify="between">
        <Flex column gap="6px">
          <Flex gap="14px">
            <Icon name={service.icon} size={"24px"} color={theme.primary} />
            <HeaderSub className="text-truncate text-capitalize">
              {service.title}
            </HeaderSub>
          </Flex>
          <LabelMedium className="text-truncate">
            {service.subTitle}
          </LabelMedium>
        </Flex>
        <Flex justify="between">
          <Flex gap="12px" align="center">
            <LabelMedium className="text-truncate" color={theme.textSub}>
              {"Required integrations"}
            </LabelMedium>
            <IntegrationsIndicators integrations={service.integrations} />
          </Flex>
          {service.purchaseStatus === "Not purchased" ? (
            <MainButton
              label={actionButtonLabel[service.purchaseStatus]}
              onClick={() => onClickService(service)}
            />
          ) : (
            <SecondaryButton
              label={actionButtonLabel[service.purchaseStatus]}
              disabled={service.purchaseStatus === "Request sent"}
              onClick={() => {
                onClickService(service);
              }}
            />
          )}
        </Flex>
      </Flex>
    </StyledServiceCard>
  );
};
