import { FaBeer, FaEyeSlash, FaEye } from "react-icons/fa";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { MdOutlineMergeType } from "react-icons/md";
import "./fontawesome.6.2.1.min.css";
import { LightIcon } from "../../../assets/icons/LightIcon";
import { DarkIcon } from "../../../assets/icons/DarkIcon";

import { WaspIcon } from "../../../assets/icons/WaspIcon";
import { ChevronDownIcon } from "../../../assets/icons/ChevronDownIcon";
import { SettingsIcon } from "../../../assets/icons/SettingsIcon";
import { MaintenanceIcon } from "../../../assets/icons/MaintenanceIcon";
import { ProfileIcon } from "../../../assets/icons/ProfileIcon";
import { LogoutIcon } from "../../../assets/icons/LogoutIcon";
import { ChevronUpIcon } from "../../../assets/icons/ChevronUpIcon";
import { CancelIcon } from "../../../assets/icons/CancelIcon";
import { CommentIcon } from "../../../assets/icons/CommentIcon";
import { ChevronRightIcon } from "../../../assets/icons/ChevronRightIcon";
import { SearchIcon } from "../../../assets/icons/SearchIcon";
import { ExportIcon } from "../../../assets/icons/ExportIcon";
import { PenetrationTestIcon } from "../../../assets/icons/PenetrationTestIcon";
import { NotificationIcon } from "../../../assets/icons/NotificationIcon";
import { SortIcon } from "../../../assets/icons/SortIcon";
import { FilterIcon } from "../../../assets/icons/FilterIcon";
import { ReadyRetestIcon } from "../../../assets/icons/ReadyRetestIcon";
import { InProgressIcon } from "../../../assets/icons/InProgressIcon";
import { DismissedIcon } from "../../../assets/icons/DismissedIcon";
import { FixedIcon } from "../../../assets/icons/FixedIcon";
import { AcceptableRiskIcon } from "../../../assets/icons/AcceptableRiskIcon";
import { PresentedIcon } from "../../../assets/icons/PresentedIcon";
import { CheckIcon } from "../../../assets/icons/CheckIcon";
import { MidCheckIcon } from "../../../assets/icons/MidCheckIcon";
import { SecurityStatusIcon } from "../../../assets/icons/SecurityStatusIcon";
import { InfoIcon } from "../../../assets/icons/InfoIcon";
import { TrendUpIcon } from "../../../assets/icons/TrendUpIcon";
import { TrendDownIcon } from "../../../assets/icons/TrendDownIcon";
import { InsightsOutlineIcon } from "../../../assets/icons/InsightsOutlineIcon";
import { AssetsOutlineIcon } from "../../../assets/icons/AssetsOutlineIcon";
import { FindingsIcon } from "../../../assets/icons/FindingsIcon";
import { TotalIcon } from "../../../assets/icons/TotalIcon";
import { TicketsIcon } from "../../../assets/icons/TicketsIcon";
import { SlaIcon } from "../../../assets/icons/SlaIcon";
import { ActiveIssuesIcon } from "../../../assets/icons/ActiveIssuesIcon";
import { ProductsIcon } from "../../../assets/icons/ProductsIcon";
import { ProjectsIcon } from "../../../assets/icons/ProjectIcon";
import { ColumnSortIcon } from "../../../assets/icons/ColumnSortIcon";
import { ProjectStartIcon } from "../../../assets/icons/ProjectStartIcon";
import { PlusIcon } from "../../../assets/icons/PlusIcon";
import { NewsIcon } from "../../../assets/icons/NewsIcon";
import { DownloadIcon } from "../../../assets/icons/DownloadIcon";
import { CalendarIcon } from "../../../assets/icons/CalendarIcon";
import { SendIcon } from "../../../assets/icons/SendIcon";
import { WriteIcon } from "../../../assets/icons/WriteIcon";
import { ChevronLeftIcon } from "../../../assets/icons/ChevronLeftIcon";
import { AddIcon } from "../../../assets/icons/AddIcon";
import { EditIcon } from "../../../assets/icons/EditIcon";
import { RemoveIcon } from "../../../assets/icons/RemoveIcon";
import { SaveIcon } from "../../../assets/icons/SaveIcon";
import { ASMIcon } from "../../../assets/icons/ASMIcon";
import { WAFIcon } from "../../../assets/icons/WAFIcon";
import { DetailsIcon } from "../../../assets/icons/DetailsIcon";
import { FriendsIcon } from "../../../assets/icons/FriendsIcon";
import { ShieldIcon } from "../../../assets/icons/ShieldIcon";
import { ShareIcon } from "../../../assets/icons/ShareIcon";
import { CalcIcon } from "../../../assets/icons/CalcIcon";
import { Envelope } from "../../../assets/icons/Envelope";
import { FolderIcon } from "../../../assets/icons/FolderIcon";
import { LoadingIcon } from "../../../assets/icons/LoadingIcon";
import { Spinner } from "../../../assets/icons/Spinner";
import { ReverseIcon } from "../../../assets/icons/Reverse";
import { FullScreenIcon } from "../../../assets/icons/FullScreen";
import { ZoomInIcon } from "../../../assets/icons/ZoomIn";
import { ZoomOutIcon } from "../../../assets/icons/ZoomOut";
import { ExitFullScreenIcon } from "../../../assets/icons/ExitFullScreen";
import { AlarmIcon } from "../../../assets/icons/AlarmIcon";
import { PlayIcon } from "../../../assets/icons/PlayIcon";
import { UserIcon } from "../../../assets/icons/UserIcon";
import SlackIcon from "../../../assets/icons/SlackIcon.svg";
import JiraIcon from "../../../assets/icons/JiraIcon.svg";
import AwsIcon from "../../../assets/icons/aws.svg";
import OktaIcon from "../../../assets/icons/okta-icon.png";
import CloudflareIcon from "../../../assets/icons/cloudflare.svg";
import { DataIcon } from "../../../assets/icons/dataIcon";
import { CertificateIcon } from "../../../assets/icons/CertificateIcon";
import CheckGreenIcon from "../../../assets/icons/CheckGreenIcon.svg";
import CheckGrayIcon from "../../../assets/icons/CheckGrayIcon.svg";
import { MapIcon } from "../../../assets/icons/MapIcon";
import { FrameIcon } from "../../../assets/icons/FrameIcon";

import { CopyIcon } from "../../../assets/icons/CopyIcon";
import { AutomationIcon } from "../../../assets/icons/AutomationIcon";
import { CreditsIcon } from "../../../assets/icons/CreditsIcon";
import { UploadIcon } from "../../../assets/icons/UploadIcon";
import { DashboardIcon } from "../../../assets/icons/DashboardIcon";
import { GlobeIcon } from "../../../assets/icons/GlobeIcon";
import { PriorityHighIcon } from "../../../assets/icons/PriorityHighIcon";
import { Flex } from "../../layouts/flex/Flex";
import { PriorityMediumIcon } from "../../../assets/icons/PriorityMediumIcon";
import { PriorityLowIcon } from "../../../assets/icons/PriorityLowIcon";
import { PriorityVeryLowIcon } from "../../../assets/icons/PriorityVeryLowIcon";
import { PriorityVeryHighIcon } from "../../../assets/icons/PriorityVeryHighIcon";
import { IrrelevantStatusIcon } from "../../../assets/icons/IrrelevantStatusIcon";
import { DeadStatusIcon } from "../../../assets/icons/DeadStatusIcon";
import { LiveStatusIcon } from "../../../assets/icons/LiveStatusIcon";
import { FalsePositiveStatusIcon } from "../../../assets/icons/FalsePositiveStatusIcon";
import { FindingsMenuIcon } from "../../../assets/icons/FindingsNavIcon";
import { AssetsMenuIcon } from "../../../assets/icons/AssetsFillIcon";
import { DashboardMenuIcon } from "../../../assets/icons/DashboardMenuIcon";
import { InsightsMenuIcon } from "../../../assets/icons/InsightsIcon";
import { ProjectsMenuIcon } from "../../../assets/icons/ShieldSolid";
import { WaspAiMenuIcon } from "../../../assets/icons/WaspAiMenuIcon";
import { ServicesMenuIcon } from "../../../assets/icons/ServicesMenuIcon";
import { SettingsMenuIcon } from "../../../assets/icons/SettingsMenuIcon";
import { MarketplaceMenuIcon } from "../../../assets/icons/MarketplaceMenuIcon";

export type IconWarperProps = {
  name: string;
  color?: string;
  size?: number | string;
  className?: string;
};

export const Icon = (props: IconWarperProps) => {
  const { name, color = "inherit", size, className } = props;
  const iconsDict: { [key: string]: JSX.Element } = {
    spinner: (
      <div className="fa-spin">
        <Spinner color={color} size={size || "inherit"} />
      </div>
    ),
    beer: <FaBeer color={color} style={{ fontSize: size || "inherit" }} />,
    eyeSlash: (
      <FaEyeSlash color={color} style={{ fontSize: size || "inherit" }} />
    ),
    eye: <FaEye color={color} style={{ fontSize: size || "inherit" }} />,
    dropArrowDown: (
      <TiArrowSortedDown
        color={color}
        style={{ fontSize: size || "inherit" }}
      />
    ),
    dropArrowRight: (
      <TiArrowSortedUp color={color} style={{ fontSize: size || "inherit" }} />
    ),

    // ARROW ICONS
    chevronDown: <ChevronDownIcon color={color} size={size} />,
    chevronUp: <ChevronUpIcon color={color} size={size} />,
    chevronRight: <ChevronRightIcon color={color} size={size} />,
    chevronLeft: <ChevronLeftIcon color={color} size={size} />,

    // LEFT NAVBAR MENU ICONS
    dashboardMenu: <DashboardMenuIcon color={color} size={size} />,
    findingsMenu: <FindingsMenuIcon color={color} size={size} />,
    assetsMenu: <AssetsMenuIcon color={color} size={size} />,
    projectsMenu: <ProjectsMenuIcon color={color} size={size} />,
    insightsMenu: <InsightsMenuIcon color={color} size={size} />,
    waspAiMenu: <WaspAiMenuIcon color={color} size={size} />,
    servicesMenu: <ServicesMenuIcon color={color} size={size} />,
    settingsMenu: <SettingsMenuIcon color={color} size={size} />,
    marketplaceMenu: <MarketplaceMenuIcon color={color} size={size} />,

    // THEME ICONS
    light: <LightIcon color={color} size={size} />,
    dark: <DarkIcon color={color} size={size} />,

    assetsOutline: <AssetsOutlineIcon color={color} size={size} />,
    insightsOutline: <InsightsOutlineIcon color={color} size={size} />,
    wasp: <WaspIcon color={color} size={size} />,
    settings: <SettingsIcon color={color} size={size} />,
    maintenance: <MaintenanceIcon color={color} size={size} />,
    profile: <ProfileIcon color={color} size={size} />,
    logout: <LogoutIcon color={color} size={size} />,
    cancel: <CancelIcon color={color} size={size} />,
    comment: <CommentIcon color={color} size={size} />,
    search: <SearchIcon color={color} size={size} />,
    export: <ExportIcon color={color} size={size} />,
    penetrationTest: <PenetrationTestIcon color={color} size={size} />,
    notification: <NotificationIcon color={color} size={size} />,
    sort: <SortIcon color={color} size={size} />,
    filter: <FilterIcon color={color} size={size} />,
    re_test: <ReadyRetestIcon color={color} size={size} />,
    in_progress: <InProgressIcon color={color} size={size} />,
    dismissed: <DismissedIcon color={color} size={size} />,
    acceptable_risk: <AcceptableRiskIcon color={color} size={size} />,
    fixed: <FixedIcon color={color} size={size} />,
    presented: <PresentedIcon color={color} size={size} />,
    check: <CheckIcon color={color} size={size} />,
    midCheck: <MidCheckIcon color={color} size={size} />,
    securityStatus: <SecurityStatusIcon color={color} size={size} />,
    info: <InfoIcon color={color} size={size} />,
    trendUp: <TrendUpIcon color={color} size={size} />,
    trendDown: <TrendDownIcon color={color} size={size} />,
    findings: <FindingsIcon color={color} size={size} />,
    total: <TotalIcon color={color} size={size} />,
    tickets: <TicketsIcon color={color} size={size} />,
    sla: <SlaIcon color={color} size={size} />,
    activeIssues: <ActiveIssuesIcon color={color} size={size} />,
    products: <ProductsIcon color={color} size={size} />,
    projects: <ProjectsIcon color={color} size={size} />,
    columnSort: <ColumnSortIcon color={color} size={size} />,
    projectStart: <ProjectStartIcon color={color} size={size} />,
    plus: <PlusIcon color={color} size={size} />,
    news: <NewsIcon color={color} size={size} />,
    download: <DownloadIcon color={color} size={size} />,
    upload: <UploadIcon color={color} size={size} />,
    calender: <CalendarIcon color={color} size={size} />,
    send: <SendIcon color={color} size={size} />,
    write: <WriteIcon color={color} size={size} />,
    add: <AddIcon color={color} size={size} />,
    edit: <EditIcon color={color} size={size} />,
    remove: <RemoveIcon color={color} size={size} />,
    save: <SaveIcon color={color} size={size} />,
    waf: <WAFIcon color={color} size={size} />,
    asm: <ASMIcon color={color} size={size} />,
    details: <DetailsIcon color={color} size={size} />,
    friends: <FriendsIcon color={color} size={size} />,
    user: <UserIcon color={color} size={size} />,
    shield: <ShieldIcon color={color} size={size} />,
    share: <ShareIcon color={color} size={size} />,
    calc: <CalcIcon color={color} size={size} />,
    envelope: <Envelope color={color} size={size} />,
    folder: <FolderIcon color={color} size={size} />,
    loading: <LoadingIcon color={color} size={size} />,
    zoomIn: <ZoomInIcon color={color} size={size} />,
    zoomOut: <ZoomOutIcon color={color} size={size} />,
    reverse: <ReverseIcon color={color} size={size} />,
    fullScreen: <FullScreenIcon color={color} size={size} />,
    exitFullScreen: <ExitFullScreenIcon color={color} size={size} />,
    alarm: <AlarmIcon color={color} size={size} />,
    play: <PlayIcon color={color} size={size} />,
    slack: <img src={SlackIcon} alt="slack" style={{ width: size }} />,
    jira: <img src={JiraIcon} alt="jira" style={{ width: size }} />,
    cloudflare: (
      <img src={CloudflareIcon} alt="cloudflare" style={{ width: size }} />
    ),
    aws: <img src={AwsIcon} alt="aws" style={{ width: size }} />,
    okta: <img src={OktaIcon} alt="okta" style={{ width: size }} />,
    data: <DataIcon color={color} size={size} />,
    certificate: <CertificateIcon color={color} size={size} />,
    checkGreen: (
      <img src={CheckGreenIcon} alt="check-green" style={{ width: size }} />
    ),
    checkGray: (
      <img src={CheckGrayIcon} alt="check-gray" style={{ width: size }} />
    ),
    map: <MapIcon color={color} size={size} />,
    frame: <FrameIcon color={color} size={size} />,
    copy: <CopyIcon color={color} size={size} />,
    automation: <AutomationIcon color={color} size={size} />,
    credits: <CreditsIcon color={color} size={size} />,
    dashboard: <DashboardIcon color={color} size={size} />,
    globe: <GlobeIcon color={color} size={size} />,
    merge: <MdOutlineMergeType color={color} size={size} />,
    priorityVeryHigh: <PriorityVeryHighIcon color={color} size={size} />,
    priorityHigh: <PriorityHighIcon color={color} size={size} />,
    priorityMedium: <PriorityMediumIcon color={color} size={size} />,
    priorityLow: <PriorityLowIcon color={color} size={size} />,
    priorityVeryLow: <PriorityVeryLowIcon color={color} size={size} />,
    irrelevantStatusIcon: <IrrelevantStatusIcon color={color} size={size} />,
    deadStatusIcon: <DeadStatusIcon color={color} size={size} />,
    liveStatusIcon: <LiveStatusIcon color={color} size={size} />,
    falsePositiveStatusIcon: (
      <FalsePositiveStatusIcon color={color} size={size} />
    ),
  };

  return (
    <Flex
      align="center"
      justify="center"
      className={className}
      data-testid={`${name}-icon`}
    >
      {iconsDict[name] || <></>}
    </Flex>
  );
};
