import React, { useContext } from "react";
import { RightPane } from "../../components/elements/rightPane/RightPane";
import { Flex } from "../../components/layouts/flex/Flex";
import { Icon } from "../../components/elements/icon/Icon";
import { ThemeContext } from "styled-components";
import {
  HeaderSub,
  LabelMedium,
} from "../../components/elements/typography/Typography";
import { Service } from "./types";
import { MainButton } from "../../components/elements/button/main/MainButton";

type Props = {
  service?: Service;
  onClose: () => void;
};

export const ServicePane = (props: Props) => {
  const { service, onClose } = props;
  const theme = useContext(ThemeContext);

  return (
    <RightPane onClose={onClose} width={"700px"}>
      <Flex column w100 h100 justify="between">
        <Flex column w100 h100 gap="32px" padding={"32px"}>
          <Flex w100 gap={"20px"} align={"center"}>
            {service && (
              <Icon name={service.icon} size={"50px"} color={theme.primary} />
            )}
            <Flex w100 h100 justify="between" column>
              <HeaderSub className="text-truncate">
                {service?.title || ""}
              </HeaderSub>
              <LabelMedium className="text-truncate">
                {service?.subTitle || ""}
              </LabelMedium>
            </Flex>
          </Flex>
          <Flex>
            <LabelMedium>{service?.description || ""}</LabelMedium>
          </Flex>
        </Flex>
        <Flex padding={"25px"} justify={"end"}>
          <Flex align="center" gap="16px">
            <MainButton label={"??????"} iconName={"save"} />
          </Flex>
        </Flex>
      </Flex>
    </RightPane>
  );
};
