import { Service } from "./types";

export const services: Service[] = [
  {
    icon: "asm",
    title: "Domain scanning",
    subTitle:
      "Lorem ipsum dolor sit amet, consectetur consectetur adipiscing elit.",
    integrations: [{ name: "aws" }, { name: "slack" }],
    purchaseStatus: "Not purchased",
    description:
      "Distinctively strategize market-driven experiences after multimedia based users. Competently whiteboard 2.0 scenarios after real-time convergence. Holisticly maintain cost effective scenarios after enabled supply chains. Continually aggregate exceptional meta-services rather than customized expertise. Objectively plagiarize quality e-services and vertical niche markets.",
  },
  {
    icon: "globe",
    title: "Cloud scanning",
    subTitle:
      "Lorem ipsum dolor sit amet, consectetur consectetur adipiscing elit.",
    integrations: [{ name: "aws" }, { name: "slack" }, { name: "jira" }],
    purchaseStatus: "Purchased service",
    description:
      "Distinctively strategize market-driven experiences after multimedia based users. Competently whiteboard 2.0 scenarios after real-time convergence. Holisticly maintain cost effective scenarios after enabled supply chains. Continually aggregate exceptional meta-services rather than customized expertise. Objectively plagiarize quality e-services and vertical niche markets.",
  },
  {
    icon: "frame",
    title: "Mobile app scanning",
    subTitle:
      "Lorem ipsum dolor sit amet, consectetur consectetur adipiscing elit.",
    integrations: [
      { name: "aws" },
      { name: "slack" },
      { name: "jira" },
      { name: "aws" },
      { name: "slack" },
      { name: "jira" },
    ],
    purchaseStatus: "Request sent",
    description:
      "Distinctively strategize market-driven experiences after multimedia based users. Competently whiteboard 2.0 scenarios after real-time convergence. Holisticly maintain cost effective scenarios after enabled supply chains. Continually aggregate exceptional meta-services rather than customized expertise. Objectively plagiarize quality e-services and vertical niche markets.",
  },
  {
    icon: "automation",
    title: "Attack Surface Management",
    subTitle:
      "Lorem ipsum dolor sit amet, consectetur consectetur adipiscing elit.",
    integrations: [{ name: "aws" }, { name: "slack" }],
    purchaseStatus: "Not purchased",
    description:
      "Distinctively strategize market-driven experiences after multimedia based users. Competently whiteboard 2.0 scenarios after real-time convergence. Holisticly maintain cost effective scenarios after enabled supply chains. Continually aggregate exceptional meta-services rather than customized expertise. Objectively plagiarize quality e-services and vertical niche markets.",
  },
];
