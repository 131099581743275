import { useEffect } from "react";
import { StyleMainButton } from "../../../components/elements/button/main/MainButton.style";
import useToastContext from "../../../hooks/toastHook";

// Extend window type to avoid TypeScript errors
declare global {
  interface Window {
    onSubmit?: (token: string) => void;
  }
}

type Props = {
  action: string;
  formValues: { [key: string]: any };
  setShowMfaMessage: (state: boolean) => void;
};

export const RecaptchaButton = (props: Props) => {
  const { action, formValues, setShowMfaMessage } = props;

  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  console.log({ siteKey });

  const addToast = useToastContext();

  const onSubmitForm = async (recaptcha_token: string) => {
    console.log(formValues);

    const url = action;
    const headers = { "Content-Type": "application/json" };
    const data = {
      ...formValues,
      recaptcha_token: recaptcha_token,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
        redirect: "manual", // Prevent automatic redirection
      });

      if (response.status !== 200) {
        response.json().then((r) => {
          console.log(r);
          if (r.non_field_errors)
            addToast({ message: `${r.non_field_errors}`, type: "error" });
          if (r.error) addToast({ message: `${r.error}`, type: "error" });
          if (r.error === "Check your email to complete MFA")
            setShowMfaMessage(true);
        });
      } else {
        const redirectUrl = response.headers.get("Location");
        console.log(redirectUrl);

        if (redirectUrl) {
          window.location.href = redirectUrl; // Redirect the browser
        } else {
          console.error("No Location header found.");
        }
      }
    } catch (error) {
      addToast({ message: `${error}`, type: "error" });
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // Load reCAPTCHA script dynamically
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    // Attach onSubmit function to window (to make it globally accessible)
    window.onSubmit = (token: string) => {
      onSubmitForm(token);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <StyleMainButton
      size=""
      style={{ width: "100%", padding: "8px" }}
      className="g-recaptcha"
      data-sitekey={siteKey}
      data-callback="onSubmit"
      data-action="submit"
    >
      Login
    </StyleMainButton>
  );
};
