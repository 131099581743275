import React from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { HeaderSub } from "../../../components/elements/typography/Typography";
import { Icon } from "../../../components/elements/icon/Icon";
import { Integration } from "../types";

type Props = {
  integrations: Integration[];
};
export const IntegrationsIndicators = (props: Props) => {
  const { integrations } = props;

  if (integrations.length > 3) {
    return (
      <Flex gap="12px" align="center">
        <Icon name={integrations[0].name} size={"32px"} />
        <Icon name={integrations[1].name} size={"32px"} />
        <HeaderSub>{`+ ${integrations.length - 2}`}</HeaderSub>
      </Flex>
    );
  }
  return (
    <Flex gap="12px" align="center">
      {integrations.map((i) => (
        <Icon name={i.name} size={"32px"} />
      ))}
    </Flex>
  );
};
