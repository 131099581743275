import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { Loading } from "../../../components/elements/loading/Loading";
import { Column, Table } from "../../../components/elements/table/Table";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { useApiAccountsPaging } from "../../../hooks/queries/accountsContext";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { getDate } from "../../../shared/helper";
import { Mixpanel } from "../../../shared/mixpanel";
import { Account } from "../../../types/Account";

type AccountRow = { [index: string]: string };

export const UsersTable = ({
  searchWord,
  setEditedAccount,
}: {
  searchWord: string;
  setEditedAccount: React.Dispatch<React.SetStateAction<Account | undefined>>;
}) => {
  useTrackPage("Settings - Users");
  const {
    data: accounts,
    isFetching,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useApiAccountsPaging();

  const getAccounts = (): Account[] => {
    return accounts?.pages.map((page) => page.results || []).flat() || [];
  };

  const search = (rows: AccountRow[]): AccountRow[] =>
    rows.filter(
      (row) =>
        (row.name &&
          row.name.toLowerCase().indexOf(searchWord.toLowerCase()) > -1) ||
        row.email.indexOf(searchWord.toLowerCase()) > -1
    );

  const columns: Column[] = [
    {
      title: "User Name",
      key: "name",
      sortable: true,
      cell: (row) => (
        <Tooltip content={row.name} isTextTruncate>
          <BodyRegular className="text-truncate">{row.name}</BodyRegular>
        </Tooltip>
      ),
    },
    {
      title: "Email",
      key: "email",
      sortable: true,
      maxWidth: "200px",
      cell: (row) => (
        <Tooltip content={row.email} isTextTruncate>
          <BodyRegular className="text-truncate">{row.email}</BodyRegular>
        </Tooltip>
      ),
    },
    {
      title: "Role",
      key: "role",
      sortable: true,
      cell: (row) => row.role.replace("&amp;", "&"),
    },
    {
      title: "Phone Number",
      key: "phone",
      sortable: true,
    },
    {
      title: "Last Login",
      key: "last_login",
      sortable: true,
      cell: (row) => getDate(row.last_login),
    },
    {
      title: "",
      key: "",
      maxWidth: "50px",
      cell: (row) => (
        <IconButton
          iconName="chevronRight"
          onClick={() => {
            Mixpanel.track("Edit User");
            setEditedAccount &&
              setEditedAccount(
                getAccounts().find((a) => a.id === parseInt(row.id))
              );
          }}
          dataTestId={`open-user-${row.id}`}
        />
      ),
    },
  ];
  const rows: AccountRow[] =
    getAccounts()
      ?.filter((a) => !a.user.is_superuser)
      ?.map((a) => ({
        id: `${a.id}`,
        name: a.user.name,
        email: a.email,
        role: a.role || "",
        phone: a.phone || "",
        last_login: a.user.last_login || "",
      })) || [];

  if (isFetching && !isRefetching)
    return (
      <div>
        <Loading />
      </div>
    );
  return (
    <Table
      columns={columns}
      rows={searchWord ? search(rows) : rows}
      hasScrollPagination
      isFetchingNextPage={isFetchingNextPage}
      onScrollPagination={fetchNextPage}
      hasNextPage={hasNextPage}
    />
  );
};
