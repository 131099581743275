import { Routes, Route } from "react-router-dom";
import { PageContainer } from "../components/layouts/PageContainer";
import { ScreenLayout } from "../components/layouts/ScreenLayout";
import Dashboard from "../features/dashboard/Dashboard";
import { BrowserRouter } from "react-router-dom";
import { Login } from "../features/welcome/login/Login";
import LegalStuff from "../features/welcome/login/LegalStuff";
import Products from "../features/settings/products/Products";
import UsersManagement from "../features/settings/users/UsersManagement";
import Findings from "../features/findings/Findings";
import { useApiMe } from "../hooks/queries/meContext";
import { getCustomerNameUrlFormat } from "../shared/helper";
import { FindingDetails } from "../features/findings/findingDetails/FindingDetails";
import { Assets } from "../features/assets/Assets";
import Integrations from "../features/settings/integrations/Integrations";
import { SlaPolicy } from "../features/settings/sla/SlaPolicy";
import { ScanningPolicy } from "../features/settings/scans/ScanningPolicy";
import NotificationsSettings from "../features/settings/notificationsSettings/NotificationsSettings";
import Insights from "../features/insights/Insights";
import MyProfile from "../features/settings/myProfile/MyProfile";
import { AssetsEditTable } from "../features/assets/assetsTable/AssetsEditTable";
import { NoMatch } from "./NoMatch";
import { FullReport } from "../features/reports/fullReport/FullReport";
import { Projects } from "../features/projects/Projects";
import { ProjectScheduler } from "../features/projects/projectScheduler/ProjectScheduler";
import { RetestReport } from "../features/reports/retestReport/RetestReport";
import { ExecutiveSummaryReport } from "../features/reports/executiveSummaryReport/ExecutiveSummaryReport";
import { SingleFindingReport } from "../features/reports/SingleFindingReport";
import { AttestationLetterReport } from "../features/reports/attestationLetterReport/AttestationLetterReport";
import { EffortEstimation } from "../features/settings/effortEstimation/EffortEstimation";
import { Credits } from "../features/settings/credits/Credits";
import { AsmManagement } from "../features/admin/asm/AsmManagement";
import { MultiFindingsReport } from "../features/reports/MultiFindingsReport";

import { Register } from "../features/welcome/register/Register";
import { AccountForm } from "../features/welcome/register/AccountForm";
import { FindingForm } from "../features/admin/research/FindingForm";
import { PendingTrial } from "../features/welcome/register/PendingTrial";
import { Automation } from "../features/settings/automation/Automation";
import { AlertMessagesDashboard } from "../features/admin/messages/AlertMessagesDashboard";
import { WaspAI } from "../features/waspAI/WaspAI";
import { FindingsAdminPanel } from "../features/findings/FindingsAdminPanel";
import { AsmAnalytics } from "../features/asmAnalytics/AsmAnalytics";
import { WhatsNewControlPanel } from "../features/admin/whatsNew/WhatsNewControlPanel";
import { WaspAIConversations } from "../features/waspAI/WaspAIConversations";
import { WaspAIConversation } from "../features/waspAI/WaspAIConversation";
import { CtiManagement } from "../features/admin/cti/CtiManagement";
import { IsolateAssetPage } from "../features/assets/graph/flowGraph/IsolateAssetPage";
import { LeaksAnalysis } from "../components/composed/leaksAnalysis/LeaksAnalysis";
import { AsmReports } from "../features/reports/asmReport/AsmReports";
import { UploadUserManual } from "../features/waspAI/user_manual/UploadUserManual";
import { Marketplace } from "../features/marketplace/Marketplace";

const AppRoutes = () => {
  const { data: me } = useApiMe();

  const customerName = me?.customer
    ? getCustomerNameUrlFormat(me.customer.name)
    : "";

  return (
    <BrowserRouter basename={`${customerName}`}>
      <Routes>
        {/* // Public Routes // */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/account" element={<AccountForm />} />
        <Route path="/pending-trial" element={<PendingTrial />} />
        <Route path="/legal-stuff" element={<LegalStuff />} />
        <Route path="*" element={<NoMatch />} />

        {/* // Private Routes // */}
        <Route path="/" element={<ScreenLayout />}>
          <Route
            index
            element={
              <PageContainer>
                <Dashboard />
              </PageContainer>
            }
          />
          <Route
            path="dashboard"
            element={
              <PageContainer>
                <Dashboard />
              </PageContainer>
            }
          />
          <Route
            path="findings"
            element={
              <PageContainer>
                <Findings />
              </PageContainer>
            }
          />
          <Route
            path="finding-details/:id"
            element={
              <PageContainer>
                <FindingDetails />
              </PageContainer>
            }
          />

          <Route
            path="assets"
            element={
              <PageContainer>
                <Assets />
              </PageContainer>
            }
          />
          <Route
            path="assets/:view"
            element={
              <PageContainer>
                <Assets />
              </PageContainer>
            }
          />
          <Route
            path="assets/map/:id"
            element={
              <PageContainer>
                <IsolateAssetPage />
              </PageContainer>
            }
          />

          <Route
            path="assets/edit"
            element={
              <PageContainer>
                <AssetsEditTable />
              </PageContainer>
            }
          />
          <Route
            path="insights"
            element={
              <PageContainer>
                <Insights />
              </PageContainer>
            }
          />
          <Route
            path="settings"
            element={
              <PageContainer sideBarWidth={380}>
                <UsersManagement />
              </PageContainer>
            }
          />
          <Route
            path="settings/my-profile"
            element={
              <PageContainer sideBarWidth={380}>
                <MyProfile />
              </PageContainer>
            }
          />
          <Route
            path="settings/users-management"
            element={
              <PageContainer sideBarWidth={380}>
                <UsersManagement />
              </PageContainer>
            }
          />
          <Route
            path="settings/products"
            element={
              <PageContainer sideBarWidth={380}>
                <Products />
              </PageContainer>
            }
          />
          <Route
            path="settings/sla-policy"
            element={
              <PageContainer sideBarWidth={380}>
                <SlaPolicy />
              </PageContainer>
            }
          />
          <Route
            path="settings/notifications"
            element={
              <PageContainer sideBarWidth={380}>
                <NotificationsSettings />
              </PageContainer>
            }
          />
          <Route
            path="settings/scanning-policy"
            element={
              <PageContainer sideBarWidth={380}>
                <ScanningPolicy />
              </PageContainer>
            }
          />
          <Route
            path="settings/integrations"
            element={
              <PageContainer sideBarWidth={380}>
                <Integrations />
              </PageContainer>
            }
          />
          <Route
            path="settings/effort-estimation"
            element={
              <PageContainer sideBarWidth={380}>
                <EffortEstimation />
              </PageContainer>
            }
          />
          <Route
            path="settings/credits"
            element={
              <PageContainer sideBarWidth={380}>
                <Credits />
              </PageContainer>
            }
          />
          <Route
            path="settings/automation"
            element={
              <PageContainer sideBarWidth={380}>
                <Automation />
              </PageContainer>
            }
          />
          <Route
            path="projects"
            element={
              <PageContainer>
                <Projects />
              </PageContainer>
            }
          />
          <Route
            path="projects/scheduler"
            element={
              <PageContainer>
                <ProjectScheduler />
              </PageContainer>
            }
          />
          <Route
            path="projects/scheduler/:id"
            element={
              <PageContainer>
                <ProjectScheduler />
              </PageContainer>
            }
          />

          <Route
            path="asm"
            element={
              <PageContainer>
                <AsmManagement />
              </PageContainer>
            }
          />
          <Route
            path="asm-analytics"
            element={
              <PageContainer>
                <AsmAnalytics />
              </PageContainer>
            }
          />
          <Route
            path="research/finding/create"
            element={
              <PageContainer>
                <FindingForm />
              </PageContainer>
            }
          />
          <Route
            path="research/findings/pending"
            element={
              <PageContainer>
                <FindingsAdminPanel />
              </PageContainer>
            }
          />

          <Route
            path="research/finding/create/:id"
            element={
              <PageContainer>
                <FindingForm />
              </PageContainer>
            }
          />

          <Route
            path="whats-new-control-panel"
            element={
              <PageContainer>
                <WhatsNewControlPanel />
              </PageContainer>
            }
          />

          <Route
            path="cti-management"
            element={
              <PageContainer>
                <CtiManagement />
              </PageContainer>
            }
          />

          <Route
            path="alert-messages"
            element={
              <PageContainer>
                <AlertMessagesDashboard />
              </PageContainer>
            }
          />
          <Route
            path="wasp-ai"
            element={
              <PageContainer>
                <WaspAI />
              </PageContainer>
            }
          />
          <Route
            path="wasp-ai-conversations"
            element={
              <PageContainer>
                <WaspAIConversations />
              </PageContainer>
            }
          />
          <Route
            path="wasp-ai-conversations/:id"
            element={
              <PageContainer>
                <WaspAIConversation />
              </PageContainer>
            }
          />
          <Route
            path="leaks"
            element={
              <PageContainer>
                <LeaksAnalysis />
              </PageContainer>
            }
          />

          <Route
            path="upload-user-manual"
            element={
              <PageContainer>
                <UploadUserManual />
              </PageContainer>
            }
          />
          <Route
            path="marketplace"
            element={
              <PageContainer>
                <Marketplace />
              </PageContainer>
            }
          />
        </Route>

        {/* REPORTS */}
        <Route path="reports/full-report" element={<FullReport />} />
        <Route
          path="reports/executive-summary-report"
          element={<ExecutiveSummaryReport />}
        />
        <Route path="reports/retest-report" element={<RetestReport />} />
        <Route path="reports/asm-report" element={<AsmReports />} />
        <Route
          path="reports/attestation-letter-report"
          element={<AttestationLetterReport />}
        />
        {/* EXPORT FINDINGS */}
        <Route path="findings/:id/pdf" element={<SingleFindingReport />} />
        <Route path="findings/pdf" element={<MultiFindingsReport />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
